import { useState, useEffect } from "react";
import { Presets } from "rete-react-plugin";
import styled, { css } from "styled-components";
import { $nodewidth, $socketmargin, $socketsize } from "./vars";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import AppDialog from '../../../common/AppDialog';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { actionsProps } from './vars';
import useMediaQuery from '@mui/material/useMediaQuery';
import { strokeMap, nodeLabelMap, triggerEvent } from '../../../utility/helper';
import ColorPickerComponent from "../../../common/ColorPickerComponent";
import FormAutocomplete from "../../../common/FormAutocomplete";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import axios from "../../../utility/api/api";
import NodeLabelLink from '../../../custom-hooks/node-label-link';
const { RefSocket } = Presets.classic;



export const NodeStyles = styled.div`
  background: ${(props) =>
    (props.color) ? props.color : `#f0f0f0`};
  border: 1px solid grey;
  position: relative;
    width: 200px;
    height: 50px;
    background-color: #f0f0f0;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  ${(props) =>
    props.selected &&
    css`
      border-color: red;
    `}
  .title {
    color: white;
    font-family: 'Segoe UI, Tahoma, sans-serif';
    font-size: 18px;
    padding: 8px;
  }
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    text-align: right;
    margin-right: -1px;
    display: inline-block;
  }
  .input-socket {
    text-align: left;
    margin-left: -1px;
    display: inline-block;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: white;
    display: inline-block;
    font-family: 'Segoe UI, Tahoma, sans-serif';
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .control {
    display: block;
    padding: ${$socketmargin}px ${$socketsize / 2 + $socketmargin}px;
  }
  ${(props) => props.styles && props.styles(props)}
`;

function sortByIndex(
  entries
) {
  entries.sort((a, b) => {
    const ai = a[1]?.index || 0;
    const bi = b[1]?.index || 0;

    return ai - bi;
  });
}

export function CustomNode(props) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    reset,
    setValue
  } = useForm({
    mode: 'onChange', // Enable live validation
  });

  const { fields: fieldsLeft, append: append1, remove: remove1 } = useFieldArray({
    control,
    name: "leftLinks" // Name of the first array field
  });

  const { fields: fieldsRight, append: append2, remove: remove2 } = useFieldArray({
    control,
    name: "rightLinks" // Name of the second array field
  });

  const updatedFieldsLeft = watch("leftLinks");
  const updatedFieldsRight = watch("rightLinks");

  const updateField = (index, fieldName, value, type) => {
    const fields = type === 'leftLinks' ? updatedFieldsLeft : updatedFieldsRight;
    fields[index][fieldName] = value;
    setRerender(prev => !prev);
    // const updatedFields = [...fields]; // Create a shallow copy of the fields array
    // updatedFields[index][fieldName] = value; // Update the specified field in the object at the given index
    // setValue(`${type}[${index}].${fieldName}`, value); // Update the form value for the field
  };


  const getLabelIcon = (label) => {
    if (!label) return '';
    if (typeof label === 'string') {
      return <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
        {label === 'triangle' && <polygon points="9,7 16,16 2,16" style={{ fill: '#fff' }} />}
        {label === 'square' && <rect width="12" height="12" x="4" y="8" style={{ fill: '#fff' }} />}
        {label === 'circle' && <circle cx="8" cy="12" r="6" style={{ fill: '#fff' }} />}
      </svg>
    } else {
      return <>
        {label.includes('circle') && <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="12" r="6" style={{ fill: '#fff' }} />
        </svg>}
        {label.includes('square') && <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <rect width="12" height="12" x="4" y="8" style={{ fill: '#fff' }} />
        </svg>}
        {label.includes('triangle') && <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <polygon points="9,7 16,16 2,16" style={{ fill: '#fff' }} />
        </svg>}
      </>

    }

  }


  const validationRules = {
    node_name: {
      required: 'Node name is required',
    },
    link_name: {
      required: 'Link name is required',
    },
  };
  const [openDialog, setOpenDialog] = useState(false);
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const controls = Object.entries(props.data.controls);
  const [selectedNode, setSelectedNode] = useState(null);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [rerender, setRerender] = useState(false);
  const { id, label, width, height, childCount, isCollapsed, showBadge } = props.data;
  const [key, setKey] = useState(0);
  const [comment, setComment] = useState('');
  const handleNext = () => {
    setTimeout(() => {
      setKey(prevKey => prevKey + 1);
    }, 100)
  };
  // useEffect(() => {
  //   handleNext()
  // }, [isCollapsed])

  const [action, setActionType] = useState(controls[0][1].type);//'collapse' : 'expand'
  const isMobile = useMediaQuery('(max-width:600px)');
  const placeHolderCss = {
    "& input::placeholder": {
      fontSize: "0.8rem"
    },
    fontSize: '0.9rem'
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [reviewAnchorEl, setReviewAnchorEl] = useState(null);
  const openReview = Boolean(reviewAnchorEl);
  const handleReviewClick = (event) => {
    setReviewAnchorEl(event.currentTarget);
  };
  const handleReviewClose = () => {
    setReviewAnchorEl(null);
  };

  function versionDecision(status) {
    handleReviewClose();
    if (status) acceptDecision();
    else declineDecision();

  }

  function acceptDecision() {
    const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
    const body = {
      "auth_token": jsonStorage.auth_token,
      "version_id": props.data.editor.selectedReview.version_id,
      "message": comment,
      "data_type": "knowledge-graph",
      "node_data": {
        "node_list": [{
          "id": props.data.data.id,
          "text": props.data.data.name,
          "meta": { "color": props.data.data.color, "label": props.data.data.category }
        }],
        "edge_list": []
      }
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/merge-document-version`,
      data: body
    })
      .then((res) => {
        // console.log(res);
        // triggerEvent('refresh_version');
        props.data.data.action = "n";
        setRefreshIcon(!refreshIcon);
        triggerEvent('show-message', res.data.message, 'success');

      }).catch(err => {
        console.log(err);
      })
  }

  function declineDecision() {
    const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
    const body = {
      "auth_token": jsonStorage.auth_token,
      "version_id": props.data.editor.selectedReview.version_id,
      "message": comment,
      "data_type": "knowledge-graph",
      "node_data": {
        "node_list": [{
          "id": props.data.data.id,
          "text": props.data.data.name,
          "meta": { "color": props.data.data.color, "label": props.data.data.category }
        }],
        "edge_list": []
      }
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/decline-document-version-merge`,
      data: body
    })
      .then((res) => {
        // triggerEvent('refresh_version');
        props.data.data.action = "n";
        setRefreshIcon(!refreshIcon);
        triggerEvent('show-message', res.data.message, 'success');
      }).catch(err => {
        console.log(err);
      })
  }

  const [anchorLinkEl, setAnchorLinkEl] = useState(null);
  const openLink = Boolean(anchorLinkEl);
  const handleLinkClick = (event) => {
    setAnchorLinkEl(event.currentTarget);
  };
  const handleLinkClose = () => {
    setAnchorLinkEl(null);
  };


  sortByIndex(inputs);
  sortByIndex(outputs);
  sortByIndex(controls);

  const copyName = () => {
    setSelectedNode(props.data);
    navigator.clipboard.writeText(props.data.label);
    handleClose();
  }

  function closeMenu() {
    reset();
    closeDialog(false);
  }

  // const strokeMap = { "solid": 'Solid', "dash": 'Dash' };
  // const nodeLabelMap = { "circle": 'Circle', "square": 'Square', "triangle": 'Triangle' };

  const onSubmit = (form) => {
    // if (action === 'add') {
    //   controls[0][1].onClick(form);
    // } else if (action === 'delete') {
    //   controls[2][1].onClick(form);
    // } else if (action === 'edit') {
    //   controls[1][1].onClick(form);
    // } else
    if (action === 'collapse' || action === 'expand') {
      controls[2][1].onClick(action);
    }
    if (action === 'collapse-parent' || action === 'expand-parent') {
      controls[0][1].onClick(action);
    }
    closeMenu();
  };


  function closeDialog(status) {
    setOpenDialog(status);
  }



  function onView(type) {
    if (type === 'edit') {
      const rightLinks = props.data.editor.getConnections().filter(c => c.source === props.data.id);
      const leftLinks = props.data.editor.getConnections().filter(c => c.target === props.data.id);
      reset({
        node_name: props.data.label,
        node_color: props.data.data.color,
        version_name: null,
        version_id: null,
        version: null,
        node_label: Array.isArray(props.data.data.category) ? props.data.data.category.map(cat => ({ value: cat, label: nodeLabelMap[cat] })) : [{ value: props.data.data.category || "circle", label: nodeLabelMap[props.data.data.category || "circle"] }],
        node_id: props.data.id,
        leftLinks: leftLinks.map(l => ({ id: l.id, link_name: l.label, is_deleted: false, color: l.color, type: l.type })),
        rightLinks: rightLinks.map(l => ({ id: l.id, link_name: l.label, is_deleted: false, color: l.color, type: l.type }))
      });
    }
    setActionType(type);
    setSelectedNode(props.data);
    if (type === 'delete') {
      reset({
        node_name: props.data.label,
        node_id: props.data.id,
        version_name: null,
        version_id: null,
        version: null,
      });
    }
    if (type === 'add') {
      reset({
        node_color: props.data.color || "#808080",
        version: null,
        version_name: null,
        version_id: null,
        node_label: [{ value: "circle", label: nodeLabelMap["circle"] }],
        link_color: "#808080",
        link_stroke: { value: "solid", label: strokeMap["solid"] }
      });
    }
    if (['expand', 'collapse'].includes(type)) {
      handleClose();
      controls[1][1].onClick(action);
    } else if (['expand-parent', 'collapse-parent'].includes(type)) {
      handleClose();
      controls[0][1].onClick(action);
    }
    else {
      handleClose();
      setOpenDialog(!openDialog)
    }
    setTimeout(() => {
      handleNext();
    }, 200)

  }

  function getActionFormComponent() {
    return <Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Node Name</Typography>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="node_name"
              placeholder="Enter node name"
              name="node_name"
              autoComplete="node_name"
              autoFocus
              variant='standard'
              {...register('node_name', {
                ...validationRules.node_name,
              })}
              error={errors.node_name}
              helperText={errors.node_name && errors.node_name.message}
              sx={{
                width: isMobile ? '100%' : '300px', // Adjust the width as needed
                ...placeHolderCss
              }}
              disabled={action === 'delete'}
            />
          </Box>
        </Box>
        {action !== 'delete' && <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Node Color</Typography>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pt: 2, px: 2 }}>
            <Controller
              name="node_color"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <Box sx={{
                    display: 'flex', flex: 1,
                    width: isMobile ? '100%' : '300px', // Adjust the width as needed
                    ...placeHolderCss,
                    justifyContent: 'flex-start', alignItems: 'center'
                  }}>
                    <ColorPickerComponent id="node_color" {...field} {...register(`node_color`)} onColorChange={(_color) => { setValue('node_color', _color); }}></ColorPickerComponent>
                    <Box sx={{ backgroundColor: field.value, width: 50, height: 50, ml: 2 }}></Box>
                  </Box>
                </>
              )}
            />
          </Box>
        </Box>}
        {action !== 'delete' && <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pl: 2 }} variant="subtitle2">Node Label</Typography>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pt: 2, px: 0 }}>
            <Controller
              name="node_label"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <Box sx={{
                    display: 'flex', flex: 1, width: isMobile ? '100%' : '315px', // Adjust the width as needed
                    ...placeHolderCss, justifyContent: 'flex-start', alignItems: 'center'
                  }}>
                    <FormAutocomplete id="node_label" multiple={true} options={[{ label: 'Circle', value: 'circle' }, { label: 'Square', value: 'square' }, { label: 'Triangle', value: 'triangle' }]} {...field} {...register(`node_label`)} handleChange={(_, val) => { setValue('node_label', val); }}></FormAutocomplete>
                  </Box>
                </>
              )}
            />
          </Box>
        </Box>}

        {action === 'add' && <> <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Link Name</Typography>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="link_name"
              placeholder="Enter link name"
              name="link_name"
              autoComplete="link_name"
              autoFocus
              variant='standard'
              {...register('link_name', {
                ...validationRules.link_name,
              })}
              error={errors.link_name}
              helperText={errors.link_name && errors.link_name.message}
              sx={{
                width: isMobile ? '100%' : '300px', // Adjust the width as needed
                ...placeHolderCss
              }}
              disabled={action === 'delete'}
            />
          </Box>
        </Box>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Link Color</Typography>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pt: 2, px: 2 }}>
              <Controller
                name="link_color"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <Box sx={{
                      display: 'flex', flex: 1, width: isMobile ? '100%' : '300px', // Adjust the width as needed
                      ...placeHolderCss, justifyContent: 'flex-start', alignItems: 'center'
                    }}>
                      <ColorPickerComponent id="link_color" {...field} {...register(`link_color`)} onColorChange={(_color) => { setValue('link_color', _color); }}></ColorPickerComponent>
                      <Box sx={{ backgroundColor: field.value, width: 50, height: 50, ml: 2 }}></Box>
                    </Box>
                  </>
                )}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pl: 2 }} variant="subtitle2">Link Stroke</Typography>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pt: 2 }}>
              <Controller
                name="link_stroke"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <Box sx={{
                      display: 'flex', flex: 1, width: isMobile ? '100%' : '315px', // Adjust the width as needed
                      ...placeHolderCss, justifyContent: 'flex-start', alignItems: 'center'
                    }}>
                      <FormAutocomplete id="link_stroke" options={[{ value: "solid", label: 'Solid' }, { value: "dash", label: 'Dash' }]} {...field} {...register(`link_stroke`)} handleChange={(_, val) => { setValue('link_stroke', val); }}></FormAutocomplete>
                    </Box>
                  </>
                )}
              />
            </Box>
          </Box>
        </>}

        {action === 'edit' &&
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pt: 2, justifyContent: 'center', alignItems: 'center' }} >
              <Divider variant="fullWidth" textAlign="left" sx={{ width: "100%" }}>Child Link(s)</Divider>
              {fieldsRight.map((l, lIndex) => {
                return <> <Box sx={{ display: updatedFieldsRight[lIndex].is_deleted ? 'none' : 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography sx={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Link Name</Typography>
                  <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="link_name"
                      placeholder="Enter link name"
                      name="link_name"
                      autoComplete="link_name"
                      autoFocus
                      variant='standard'
                      {...register(`rightLinks[${lIndex}].link_name`, {
                        ...validationRules.link_name,
                      })}
                      error={errors.link_name}
                      helperText={errors.link_name && errors.link_name.message}
                      sx={{
                        width: isMobile ? '100%' : '300px', // Adjust the width as needed
                        ...placeHolderCss
                      }}
                    />
                    <IconButton
                      onPointerDown={(e) => e.stopPropagation()}
                      onDoubleClick={(e) => e.stopPropagation()}
                      onClick={() => {
                        updateField(lIndex, 'is_deleted', true, 'rightLinks')
                      }}
                      color={actionsProps['delete'].color}
                    >
                      {actionsProps['delete'].icon}
                    </IconButton>
                  </Box>
                </Box>
                </>
              })
              }
              {rerender && null /* Rendering a dummy component just to trigger re-render */}
            </Box>

          </>}
        <Box sx={{ px: 2, display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2, backgroundColor: '#797EF6' }}
          >
            {action === 'add' ? 'Add' : action === 'edit' ? 'Edit' : 'Delete'}
          </Button>
        </Box>
      </Box>
    </Box>
  }
  function getActions() {
    return <Box sx={{ px: 2, display: 'flex', flexDirection: 'row-reverse' }}>
      <Button
        type="submit"

        variant="contained"
        sx={{ mt: 1, mb: 1, backgroundColor: '#797EF6' }}
      >
        {action === 'add' ? 'Add' : action === 'edit' ? 'Edit' : 'Delete'}
      </Button>
    </Box>;
  }

  function ReviewContainer() {
    const colors = { 'c': '#c4f7c4', 'u': '#f7f5c4', 'd': '#f7d8c4' };
    const colorsBorder = { 'c': 'green', 'u': 'orange', 'd': 'red' };
    if (!props.data.data.action) return '';
    return <><Box sx={{
      position: 'absolute',
      right: "24px", top: "-24px",
      p: 0.5, pb: 0,
      border: `1px solid ${colorsBorder[props.data.data.action]}`,
      borderRadius: '4px',
      cursor: 'pointer',
      background: colors[props.data.data.action],
      color: '#000'
    }}

    >
      <Box id={"review-button" + id} sx={{ position: 'relative' }} onPointerDown={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        onClick={handleReviewClick}>
        <ReviewLabel></ReviewLabel>
      </Box>
    </Box>
      <Menu
        id={"review-menu" + id}
        anchorEl={reviewAnchorEl}
        open={openReview}
        onClose={handleReviewClose}
        MenuListProps={{
          'aria-labelledby': 'review-button' + id,
        }}
        className="padding-0"
      >

        <Box sx={{ display: 'flex', flexDirection: 'column', p: 0, m: 0, minHeight: '380px', minWidth: '500px' }}>
          {props.data.data.action === 'u' && <Box sx={{ px: 2, backgroundColor: "#fff" }}>
            <Box sx={{ display: 'flex', }}>
              <Box sx={{ display: 'flex' }}><Box component={'h3'} sx={{ my: 2, }}>Review Node Update </Box>
                <Box sx={{ pl: 3, mt: 1 }}>
                  <Box sx={{
                    position: 'absolute',
                    p: 0.5, pb: 0,
                    border: `1px solid ${colorsBorder[props.data.data.action]}`,
                    borderRadius: '4px',
                    cursor: 'pointer',
                    background: colors[props.data.data.action],
                    color: '#000'
                  }}

                  >
                    <Box id={"review-button" + id} sx={{ position: 'relative' }} onPointerDown={(e) => e.stopPropagation()}
                      onDoubleClick={(e) => e.stopPropagation()}
                      onClick={handleReviewClick}>
                      <ReviewLabel></ReviewLabel>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <IconButton onClick={handleReviewClose} sx={{ ml: 'auto', color: '#000' }}><CloseIcon></CloseIcon></IconButton>
            </Box>
            <Box sx={{ mb: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
            <Box sx={{ display: 'flex', fontWeight: 'bold', pb: 1 }}>
              <Box sx={{ flex: 4 }}>Field Name</Box>
              <Box sx={{ flex: 4 }}>Current Version</Box>
              <Box sx={{ flex: 4 }}>Review Version</Box>
            </Box>
            {<Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 4 }}>Name</Box>
              <Box sx={{ flex: 4 }}>{props.data.data.main?.name}</Box>
              <Box sx={{ flex: 4 }}>{props.data.data.name}</Box>
            </Box>}
            {<Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 4 }}>Label</Box>
              <Box sx={{ flex: 4 }}>{props.data.data.main?.category}</Box>
              <Box sx={{ flex: 4 }}>{props.data.data.category}</Box>
            </Box>}
            {<Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 4 }}>Color</Box>
              <Box sx={{ flex: 4 }}>{props.data.data.main?.color}</Box>
              <Box sx={{ flex: 4 }}>{props.data.data.color}</Box>
            </Box>}
            <Box sx={{ my: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
            <Box sx={{ display: 'flex', mt: 2, flexDirection: 'column' }}>
              <Box sx={{ flex: 4 }}>Comment</Box>
              <TextField
                margin="normal"
                required
                fullWidth
                id="comment"
                label=""
                name="email"
                autoComplete="email"
                autoFocus
                variant='outlined'
                value={comment}
                onChange={(event) => setComment(event.target.value)}
              />
            </Box>

            <Box sx={{ my: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important' }}></Box>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                sx={{ mt: 3, mr: 2, mb: 2, ml: 'auto', backgroundColor: '#797EF6' }}
                onClick={() => versionDecision(true)}
              >
                ACCEPT
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: '#797EF6' }}
                onClick={() => versionDecision(false)}
              >
                DECLINE
              </Button>
            </Box>
          </Box>}
          {props.data.data.action === 'c' && <Box sx={{ px: 2, backgroundColor: "#fff" }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex' }}><Box component={'h3'} sx={{ my: 2, }}>Review Node Addition</Box>
                <Box sx={{ pl: 3, mt: 1 }}>
                  <Box sx={{
                    position: 'absolute',
                    p: 0.5, pb: 0,
                    border: `1px solid ${colorsBorder[props.data.data.action]}`,
                    borderRadius: '4px',
                    cursor: 'pointer',
                    background: colors[props.data.data.action],
                    color: '#000'
                  }}

                  >
                    <Box id={"review-button" + id} sx={{ position: 'relative' }} onPointerDown={(e) => e.stopPropagation()}
                      onDoubleClick={(e) => e.stopPropagation()}
                      onClick={handleReviewClick}>
                      <ReviewLabel></ReviewLabel>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <IconButton onClick={handleReviewClose} sx={{ ml: 'auto', color: '#000' }}><CloseIcon></CloseIcon></IconButton>
            </Box>
            <Box sx={{ mb: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important' }}></Box>
            <Box sx={{ display: 'flex', fontWeight: 'bold', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Field Name</Box>
              <Box sx={{ flex: 6 }}>Added Field Value</Box>
            </Box>
            <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Name</Box>
              <Box sx={{ flex: 6 }}>{props.data.data.name}</Box>
            </Box>
            <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Label</Box>
              <Box sx={{ flex: 6 }}>{props.data.data.category}</Box>
            </Box>
            <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Color</Box>
              <Box sx={{ flex: 6 }}>{props.data.data.color}</Box>
            </Box>
            <Box sx={{ my: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important' }}></Box>
            <Box sx={{ display: 'flex', mt: 2, flexDirection: 'column' }}>
              <Box sx={{ flex: 4 }}>Comment</Box>
              <TextField
                margin="normal"
                required
                fullWidth
                id="comment"
                label=""
                name="comment"
                autoComplete="comment"
                autoFocus
                variant='outlined'
                value={comment}
                onChange={(event) => setComment(event.target.value)}
              />
            </Box>

            <Box sx={{ my: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important' }}></Box>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                onClick={() => versionDecision(true)}
                sx={{ mt: 3, mr: 2, mb: 2, ml: 'auto', backgroundColor: '#797EF6' }}
              >
                ACCEPT
              </Button>
              <Button
                variant="contained"
                onClick={() => versionDecision(false)}
                sx={{ mt: 3, mb: 2, backgroundColor: '#797EF6' }}
              >
                DECLINE
              </Button>
            </Box>
          </Box>}
          {props.data.data.action === 'd' && <Box sx={{ px: 2, backgroundColor: "#fff" }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex' }}><Box component={'h3'} sx={{ my: 2, }}>Review Node Delete</Box>
                <Box sx={{ pl: 3, mt: 1 }}>
                  <Box sx={{
                    position: 'absolute',
                    p: 0.5, pb: 0,
                    border: `1px solid ${colorsBorder[props.data.data.action]}`,
                    borderRadius: '4px',
                    cursor: 'pointer',
                    background: colors[props.data.data.action],
                    color: '#000'
                  }}
                  >
                    <Box id={"review-button" + id} sx={{ position: 'relative' }} onPointerDown={(e) => e.stopPropagation()}
                      onDoubleClick={(e) => e.stopPropagation()}
                      onClick={handleReviewClick}>
                      <ReviewLabel></ReviewLabel>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <IconButton onClick={handleReviewClose} sx={{ ml: 'auto', color: '#000' }}><CloseIcon></CloseIcon></IconButton>
            </Box>
            <Box sx={{
              mb: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important'
            }}></Box>
            <Box sx={{ display: 'flex', fontWeight: 'bold', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Field Name</Box>
              <Box sx={{ flex: 6 }}>Field Value</Box>
            </Box>
            <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Name</Box>
              <Box sx={{ flex: 6 }}>{props.data.data.name}</Box>
            </Box>
            <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Label</Box>
              <Box sx={{ flex: 6 }}>{props.data.data.category}</Box>
            </Box>
            <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Color</Box>
              <Box sx={{ flex: 6 }}>{props.data.data.color}</Box>
            </Box>
            <Box sx={{ my: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important' }}></Box>
            <Box sx={{ display: 'flex', mt: 2, flexDirection: 'column' }}>
              <Box sx={{ flex: 4 }}>Comment</Box>
              <TextField
                margin="normal"
                required
                fullWidth
                id="comment"
                label=""
                name="email"
                autoComplete="email"
                autoFocus
                variant='outlined'
                value={comment}
                onChange={(event) => setComment(event.target.value)}
              />
            </Box>

            <Box sx={{ my: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important' }}></Box>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                sx={{ mt: 3, mr: 2, mb: 2, ml: 'auto', backgroundColor: '#797EF6' }}
                onClick={() => versionDecision(true)}
              >
                ACCEPT
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: '#797EF6' }}
                onClick={() => versionDecision(false)}
              >
                DECLINE
              </Button>
            </Box>
          </Box>}
        </Box>
      </Menu ></>;
  }


  function ReviewLabel() {
    switch (props.data.data.action) {
      case 'c': {
        return <><Box sx={{ position: 'absolute', bottom: -4, left: 0, color: 'green' }}>C</Box>
          <AccountTreeIcon sx={{ color: 'green' }} /></>
      }
      case 'u': {
        return <><Box sx={{ position: 'absolute', bottom: -4, left: 0, color: 'orange' }}>U</Box>
          <AccountTreeIcon sx={{ color: 'orange' }} /></>
      }
      case "d": {
        return <><Box sx={{ position: 'absolute', bottom: -4, left: 0, color: 'red' }}>D</Box>
          <AccountTreeIcon sx={{ color: 'red' }} /></>
      }
    }
  }

  return (
    <>
      <Box key={'cn-' + id + key} id={'cn-' + id} className="container" sx={{ backgroundColor: props?.data?.data?.color || '#f0f0f0', color: '#fff', width:'350px' }}>
        {inputs.map(
          ([key, input]) =>
            input && (
              <div className="rounded-circle left" key={key} data-testid={`input-${key}`}>
                <RefSocket
                  name="input-socket"
                  emit={props.emit}
                  side="input"
                  socketKey={key}
                  nodeId={id}
                  payload={{ socket: input.socket, data: props.data }}
                />
              </div>
            )
        )}
        <Badge key={'badge-' + id + key} badgeContent={childCount} color="primary">
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
            <Box className="label" sx={{ pl: 1, color: '#fff', width: '260px' }} title={label}>{label}</Box>
            <Box sx={{ position: 'absolute', left: "12px", top: "-8px" }}>
              {getLabelIcon(props.data.data.category)}
            </Box>
            <Box key={refreshIcon}>
              {ReviewContainer()}
            </Box>
            <Box sx={{ marginLeft: 'auto', p: 0 }}>
              {/* <IconButton
                id={"link-button" + id}
                key={'link-button-' + id + key}
                aria-controls={openLink ? 'link-menu' + id : undefined}
                aria-haspopup="true"
                aria-expanded={openLink ? 'true' : undefined}
                onPointerDown={(e) => e.stopPropagation()}
                onDoubleClick={(e) => e.stopPropagation()}
                onClick={handleLinkClick}
                sx={{ p: 0, py: 1.1, ':hover': { backgroundColor: 'unset' } }}
              >
                {openLink ? <ExpandLessIcon sx={{ color: '#fff' }} /> : <ExpandMoreIcon sx={{ color: '#fff' }} />}
              </IconButton> */}
              {props && <NodeLabelLink props={props}></NodeLabelLink>}
              <IconButton
                id={"basic-button" + id}
                key={'icon-button-' + id + key}
                aria-controls={open ? 'basic-menu' + id : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onPointerDown={(e) => e.stopPropagation()}
                onDoubleClick={(e) => e.stopPropagation()}
                onClick={handleClick}
                sx={{ p: 0, py: 1, ':hover': { backgroundColor: 'unset' } }}
              >
                <MoreVertIcon sx={{ color: '#fff' }} />
              </IconButton>
            </Box>
          </Box>
        </Badge>

        {outputs.map(
          ([key, output]) =>
            output && (
              <div className="rounded-circle right" key={key} data-testid={`output-${key}`}>
                <RefSocket
                  name="output-socket"
                  side="output"
                  emit={props.emit}
                  socketKey={key}
                  nodeId={id}
                  payload={{ socket: output.socket, data: props.data }}
                />
              </div>
            )
        )}
      </Box>
      <Menu
        id={"basic-menu" + id}
        key={'menu-' + id + key}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button' + id,
        }}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
        {/* <MenuItem> */}
        <Box sx={{ display: 'flex', p: 0, m: 0 }}>
          {controls?.length && <Tooltip title={actionsProps[controls[0][1].type].title}> <IconButton
            onPointerDown={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()}
            onClick={() => {
              onView(controls[0][1].type);
            }}
            color={actionsProps[controls[0][1].type].color}
          >
            {actionsProps[controls[0][1].type].icon}
          </IconButton>
          </Tooltip>}
          {selectedNode?.label && selectedNode?.label === window?.clipboardData?.getData('Text') ? <IconButton
          ><CheckIcon />
          </IconButton>
            : <IconButton
              sx={{ color: '#797EF6' }}
              onClick={() => copyName()}
            ><ContentCopyIcon /></IconButton>}

          {/* {controls.map(([key, control], index) => {
            return control ? (
              <> */}
          {controls?.length &&
            <Tooltip title={actionsProps[controls[1][1].type].title}>
              <IconButton
                onPointerDown={(e) => e.stopPropagation()}
                onDoubleClick={(e) => e.stopPropagation()}
                onClick={() => {
                  onView(controls[1][1].type);
                }}
                color={actionsProps[controls[1][1].type].color}
              >
                {actionsProps[controls[1][1].type].icon}
              </IconButton>
            </Tooltip>
          }
        </Box>
      </Menu>
      <Menu
        id={"link-menu" + id}
        key={'link-menu-' + id + key}
        anchorEl={anchorLinkEl}
        open={openLink}
        onClose={handleLinkClose}
        MenuListProps={{
          'aria-labelledby': 'link-button' + id,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', px: 2, py: 1, m: 0, maxWidth: '300px' }}>
          <Box component={'h3'} sx={{ p: 0, m: 0 }}>Node:</Box>
          <Box>
            {label}
          </Box>
          {(props?.data?.data?.link || []).length > 0 && <Box component={'h3'} sx={{ p: 0, m: 0, mt: 1 }}>Link:</Box>}
          {(props?.data?.data?.link || []).map(link => {
            return <Box>
              <Link rel="noopener noreferrer" sx={{ cursor: 'pointer' }} onClick={
                () => window.open(link, "_blank")
              }>
                {link}
              </Link>

            </Box>
          })}
        </Box>
      </Menu>
      {/* <AppDialog
        openDialog={openDialog}
        onOpenDialog={closeDialog}
        dialogTitle={actionsProps[action]?.title + ' Node'}
        maxWidth={'sm'}
        fullWidth={false}
        attachedComponent={getActionFormComponent()}
        hideAction={true}
        actions={getActions()}
      ></AppDialog> */}
    </>
  );
}
