import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from "../../utility/api/api";
import uiAction from '../../store/actions/uiAction';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch } from 'react-redux';
export default function SampleSearch(props) {
    const [color, setColor] = useState("");
    const dispatch = useDispatch();
    const [itemList, setItemList] = useState([
        { id: 'sample-id-1', text: 'Summarize the data', description: 'To get short summary' },
        { id: 'sample-id-2', text: 'List all the medicines', description: 'Query to listout all the medicines' },
        { id: 'sample-id-3', text: 'Find all the proteins', description: 'Query to listout all the proteins' }
    ]);
    const [popularItemList, setPopularItemList] = useState([
        { id: 'popular-id-1', text: 'Summarize the data' },
        { id: 'popular-id-2', text: 'List all the medicines' },
        { id: 'popular-id-3', text: 'Find all the proteins' },
        { id: 'popular-id-4', text: 'Find all the proteins' },
        { id: 'popular-id-5', text: 'Find all the proteins' },
    ]);
    const [selectedColorIndex, setSelectedColorIndex] = useState(null);
    const [editCurrentColor, setEditCurrentColor] = useState("");
    const [isColorListLoading, setIsColorListLoading] = useState(false);
    const [showColorPalette, setShowColorPalette] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const updateSampleList = async () => {
        try {
            const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
            const body = { user_id: jsonStorage.user_id, query: itemList.map(item => item.text) };
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/update-sample-query`,
                data: body
            })
            if (!response.data.status) {
                dispatch(uiAction.showSnackbar({ type: 'error', message: response.data.message }));
                return;
            }
            dispatch(uiAction.showSnackbar({ type: 'success', message: response?.data?.message || 'Updated Successfuly!!!' }));
        } catch (error) {
            console.log(error);
            dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
        }

    }

    const updatePopularList = async () => {
        try {
            const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
            const body = { user_id: jsonStorage.user_id, query: popularItemList.map(item => item.text) };
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/update-popular-query`,
                data: body
            })
            if (!response.data.status) {
                dispatch(uiAction.showSnackbar({ type: 'error', message: response.data.message }));
                return;
            }
            dispatch(uiAction.showSnackbar({ type: 'success', message: response?.data?.message || 'Updated Successfuly!!!' }));
        } catch (error) {
            console.log(error);
            dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
        }

    }
    const fetchPopularApiData = async () => {
        try {
            setIsColorListLoading(true);
            const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
            const body = { user_id: jsonStorage.user_id };
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/get-popular-query`,
                data: body
            })
            setIsColorListLoading(false);
            if (!response.data.status) {
                dispatch(uiAction.showSnackbar({ type: 'error', message: response.data.message }));
                return;
            }
            if (response?.data?.data?.length) {
                const arr = response.data.data.map((s, i) => ({
                    id: 'popular-id' + i,
                    text: s,
                    description: ''
                }));
                setPopularItemList(arr);
            }
        } catch (error) {
            setIsColorListLoading(false);
            console.log(error);
            dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
        }

    }

    const fetchSampleApiData = async () => {
        try {
            setIsColorListLoading(true);
            const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
            const body = { user_id: jsonStorage.user_id };
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/get-sample-query`,
                data: body
            })
            setIsColorListLoading(false);
            if (!response.data.status) {
                dispatch(uiAction.showSnackbar({ type: 'error', message: response.data.message }));
                return;
            }
            if (response?.data?.data?.length) {
                const arr = response.data.data.map((s, i) => ({
                    id: 'sample-id' + i,
                    text: s,
                    description: ''
                }));
                setItemList(arr);
            }
        } catch (error) {
            setIsColorListLoading(false);
            console.log(error);
            dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
        }

    }

    const copyName = (value) => {
        setSelectedNode(value);
        navigator.clipboard.writeText(value);
        dispatch(uiAction.showSnackbar({ type: 'success', message: 'Query Copied!!!' }));
    }

    useEffect(() => {
        fetchPopularApiData();
        fetchSampleApiData();
    }, [])



    if (isColorListLoading) {
        return <Box sx={{ display: 'flex', minHeight: '300px', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </Box>
    }



    function updatePopularText(index, key, value) {
        const arr = popularItemList;
        arr[index][key] = value;
        setPopularItemList([...arr])
    }

    function updateSample(index, key, value) {
        const arr = itemList;
        arr[index][key] = value;
        setItemList(() => [...arr]);
    }

    function addSample() {
        if (itemList.length >= 5) {
            return;
        }
        const arr = itemList;
        setItemList(() => [{ id: 'sample-id' + arr.length + 1, text: '', description: '' }, ...arr]);
    }
    function addPopular() {
        if (popularItemList.length >= 5) {
            return;
        }
        const arr = popularItemList;
        setPopularItemList(() => [{ id: 'popular-id' + arr.length + 1, text: '', description: '' }, ...arr]);
    }

    function removeSample(index) {
        setItemList(() => itemList.filter((item, i) => index !== i));
    }

    function removePopular(index) {
        setPopularItemList(() => popularItemList.filter((item, i) => index !== i));
    }

    return <>
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
            <Box component={'h4'} sx={{ py: 0, minWidth: '600px', maxWidth: '50%', display: 'flex' }}>Sample Search List
                <IconButton onClick={addSample} sx={{ ml: 'auto' }}><AddIcon></AddIcon></IconButton>
            </Box>
            {itemList.map((col, index) => <Grid container key={col.id} sx={{ display: 'flex', py: 2, alignItems: 'center', minWidth: '600px', maxWidth: '50%', }}>
                <Grid item xs={10} sx={{ display: 'flex' }}>
                    <TextField
                        required
                        id={col.id + '-text'}
                        label=""
                        variant="standard"
                        value={col.text}
                        onChange={(event) => updateSample(index, 'text', event.target.value)}
                        sx={{ width: '100%', pr: 3 }}
                    />
                    {<IconButton
                        sx={{ color: '#797EF6' }}
                        onClick={() => copyName(col.text)}
                    ><ContentCopyIcon /></IconButton>}
                </Grid>
                <IconButton onClick={() => removeSample(index)} sx={{ ml: 2 }}><RemoveCircleOutlineIcon></RemoveCircleOutlineIcon></IconButton>
                {/* <Grid item xs={6}>
                    <TextField
                        required
                        id={col.id + '-desc'}
                        label=""
                        variant="standard"
                        value={col.description}
                        onChange={(event) => updateSample(index, 'description', event.target.value)}
                        sx={{ width: '100%' }}
                    /></Grid> */}
            </Grid>)}
            {/* <Box sx={{ borderTop: '1px solid #ccc', my: 1, maxWidth: '560px' }}></Box> */}
            <Box sx={{ pt: 2, minWidth: '600px', maxWidth: '50%', display: 'flex' }}>
                <Button variant='contained' sx={{ ml: 'auto' }} onClick={() => updateSampleList()}>Update</Button>
            </Box>

        </Box>
        <Box sx={{ borderTop: '1px solid #ccc', minWidth: '600px', maxWidth: '50%', my: 4 }}></Box>
        <Box sx={{ display: 'flex', flexDirection: 'column',p: 0 }}>
            <Box component={'h4'} sx={{ py: 0, minWidth: '600px', maxWidth: '50%', display: 'flex' }}>Popular Search
                <IconButton onClick={addPopular} sx={{ ml: 'auto' }}><AddIcon></AddIcon></IconButton>
            </Box>
            {popularItemList.map((col, index) => <Grid container key={col.id} sx={{ display: 'flex', py: 2, alignItems: 'center', minWidth: '600px', maxWidth: '50%', }}>
                {/* {index + 1}. */}
                <Grid item xs={10} sx={{ display: 'flex' }}>
                    <TextField
                        required
                        id={col.id + "popular-question"}
                        label=""
                        variant="standard"
                        value={col.text}
                        onChange={(event) => updatePopularText(index, 'text', event.target.value)}
                        sx={{ width: '100%', pr: 3 }}
                    />
                    {<IconButton
                        sx={{ color: '#797EF6' }}
                        onClick={() => copyName(col.text)}
                    ><ContentCopyIcon /></IconButton>}
                </Grid>
                <IconButton onClick={() => removePopular(index)} sx={{ ml: 2 }}><RemoveCircleOutlineIcon></RemoveCircleOutlineIcon></IconButton>
            </Grid>)}
            <Box sx={{ pt: 1, minWidth: '600px', maxWidth: '50%', display: 'flex' }}>
                <Button variant='contained' sx={{ ml: 'auto' }} onClick={() => updatePopularList()}>Update</Button>
            </Box>

        </Box>
    </>
}