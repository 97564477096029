import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AppDialog(props) {
    const [open, setOpen] = useState(props.open);
    const { onOpenDialog, openDialog, dialogTitle, attachedComponent, actions, maxWidth = 'md' } = props

    const handleClose = () => {
        setOpen(false);
        onOpenDialog(false)
    };

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog])

    const [fullWidth, setFullWidth] = useState([null, undefined].includes(props.fullWidth) ? props.fullWidth : true);
    // const [maxWidth, setMaxWidth] = useState(props.maxWidth || 'md');

    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open dialog
            </Button> */}
            <BootstrapDialog
                onClose={handleClose}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {dialogTitle}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[900],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {attachedComponent}
                </DialogContent>
                {!props.hideAction && <DialogActions sx={{ m: 1 }}>
                    {actions}
                </DialogActions>}
            </BootstrapDialog>
        </div>
    );
}