// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRef, useState, Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from "../../utility/api/api";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import Card from '@mui/material/Card';
import Loader from '../../Loader';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import ReteGraph from './rete/rete-graph';
import { useForm } from "react-hook-form";
import generateRandomTreeData from '../../constants/LoadChecker';//./constants/LoadChecker
import { showLoader, formatTimestamp, hideLoader, prepareReteTreeV3 } from '../../utility/helper';
import LeftMenuList from '../../common/LeftMenuList';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AppDialog from '../../common/AppDialog';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import { blueGrey } from '@mui/material/colors';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import uiAction from '../../store/actions/uiAction';
import Tooltip from '@mui/material/Tooltip';
import PDFViewer from '../../common/react-pdf/PDFViewer';
import CommitDetails from './commit-history/CommitDetails';
import HistoryIcon from '@mui/icons-material/History';
import HtmlTooltip from '../../common/htm-customized-tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ResponsiveSearchContainer from '../../ResponsiveSearchContainer';
import { Navigate } from "react-router-dom";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

export default function MeddraDetails(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [projectId, setProjectId] = useState("");
    const [showNodeView, setShowNodeView] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [docName, setDocName] = useState("");
    const [viewerWindow, setViewerWindow] = useState(1);
    const [docDetail, setDocDetail] = useState("");
    const [loading, setLoading] = useState(false);
    const [dataset, setDataset] = useState(null);
    const [empty, setEmpty] = useState(false);
    const [docType, setDocType] = useState('');
    const [pdfUrl, setPdfUrl] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [zoomVal, setZoomVal] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [isReviewLoading, setIsReviewLoading] = useState(true);
    const [selectedReview, setSelectedReview] = useState(null);
    // const [id, setid] = useState("700e2007-c46c-4926-b9b9-bdf43d1ffb70");
    const [openSearchBar, setOpenSearchBar] = useState(false);
    const [searchChartText, setSearchChartText] = useState('');
    const [show, setShow] = useState(true)
    const [selectedNodeIds, setSelectedNodeIds] = useState(() => {
        // Initialize from localStorage
        const saved = localStorage.getItem('selectedNodeIds');
        return saved ? JSON.parse(saved) : [];
    });
    const [fontSize, setFontSize] = useState(16)
    const [showRightCard, setShowRightCard] = useState(true)


    const resultTextStyle = {
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
        fontFamily: 'Google Sans, Helvetica Neue, sans - serif',
        letterSpacing: 'normal',
        whiteSpace: 'pre - wrap',
    }
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleGraphSearch = (status) => {
        setOpenSearchBar(status);
    };

    const reviewRef = useRef();
    const textChartFieldRef = useRef(null);

    function prepareLoadData(n) {
        const preparedDataSet = generateRandomTreeData(n);
        setLoading(true);
        showLoader();
        setTimeout(() => {
            setDataset(preparedDataSet);
            setLoading(false);
        }, 1000)
    }


    function initPage(id) {
        prepareLoadData(10);
    }


    useEffect(() => {
        const storage = localStorage.getItem('sielo_search_app');
        if (storage) {
            const storageJson = JSON.parse(storage);
            if (!storageJson.isLoggedIn) {
                navigate("/sign-in");
            }
            getVersionDetail();
        } else {
            navigate("/sign-in");
        }
    }, [navigate]);

    useEffect(() => {
        let interval;
        if (!showRightCard) {
            interval = setInterval(() => {
                const ids = JSON.parse(localStorage.getItem('selectedNodeIds')) || [];
                setSelectedNodeIds(ids);
            }, 2000);
        }
        return () => {
            clearInterval(interval);
            localStorage.removeItem('selectedNodeIds');
        };
    }, [showRightCard]);

    const setChildSearchText = (text) => {
        setSearchChartText(text);
        reviewRef.current.setSearchText(text);
    }

    const handleSearchChartKeyPress = (event) => {
        if (event.key === 'Enter') {
            reviewRef.current.handleSearch(searchChartText);
        }
    };

    function getVersionDetail() {
        showLoader();
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/medra/get-meddra-data`,
            data: body
        })
            .then((res) => {
                if (res?.data?.doc_data?.project_id) {
                    setProjectId(res.data.doc_data.project_id);
                }
                if (res?.data?.doc_data?.project_name) {
                    setProjectName(res.data.doc_data.project_name);
                }
                if (res?.data?.doc_data?.doc_detail) {
                    setDocDetail(res.data.doc_data.doc_detail);
                }
                if (res?.data?.doc_data?.doc_name) {
                    setDocName(res.data.doc_data.doc_name);
                }
                const doc_format = res.data.doc_data.doc_format;

                setDocType(doc_format);

                if (doc_format === 'text') {
                    const data = res.data?.doc_data?.data?.nodes;
                    if (!res.data.doc_data.data) {
                        hideLoader();
                        setEmpty(true);
                        // return;
                    } else {
                        setEmpty(false);
                        hideLoader();
                        setTimeout(() => {
                            setDataset(prepareReteTreeV3(data));
                            // prepareLoadData(10);
                        }, 100)
                    };
                    if ((res.data?.doc_data?.doc_url || '').includes('.pdf')) {
                        setPdfUrl(res.data?.doc_data?.doc_url);
                    }


                } else if (doc_format === "knowledge-graph") {
                    const data = res.data?.doc_data?.data?.nodes;
                    if (!res.data.doc_data.data) {
                        hideLoader();
                        setEmpty(true);
                        return;
                    };
                    setEmpty(false);
                    hideLoader();
                    setTimeout(() => {
                        setDataset(prepareReteTreeV3(data));
                        // prepareLoadData(10);
                    }, 100)
                } else {
                    const data = res.data.doc_data?.data?.nodes;
                    if (!res.data.doc_data.data) {
                        hideLoader();
                        setEmpty(true);
                        return;
                    };
                    setEmpty(false);
                    hideLoader();
                    setTimeout(() => {
                        setDataset(prepareReteTreeV3(data));
                        // prepareLoadData(10);
                    }, 100)
                }



            }).catch(err => {
                hideLoader();
            })

    }

    function openConfirmationDialog() {
        setOpenDialog(true);
    }
    function openUploadDoc() {
        navigate("/layout/uploads", { state: { tab: 1, project_id: projectId, project_name: projectName } });
    }

    function onViewCommitHistory() {
        // showLoader();
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token };
        setShowRightCard(!showRightCard)
        setViewerWindow(() => 3);
        // axios({
        //     method: "post",
        //     url: `${process.env.REACT_APP_BASE_URL}/api/delete-doc`,
        //     data: body
        // })
        //     .then((res) => {
        //         dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'Document deleted Successfuly!!!' }));
        //         setTimeout(() => {
        //             navigate("/layout/uploads");
        //         }, 1000);

        //     }).catch(err => {
        //         hideLoader();
        //     })




    }


    function closeConfirmationDialog() {
        setOpenDialog(false);
    }
    function onDeleteDocument() {
        showLoader();
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/delete-doc`,
            data: body
        })
            .then((res) => {
                dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'Document deleted Successfuly!!!' }));
                setTimeout(() => {
                    navigate("/layout/uploads");
                }, 1000);

            }).catch(err => {
                hideLoader();
            })
    }
    function pageChange(pageNo) {
        if (pageNo <= 0) {
            pageNo = numPages;
        }
        if (!(pageNo % (numPages + 1))) {
            pageNo = 1;
        }
        setPageNumber(pageNo % (numPages + 1));
    }
    function onSetNumPages(num) {
        console.log(num);
        setNumPages(num);
    }

    function onVisChange() {
        if (viewerWindow === 1) {
            if (!showNodeView) {
                setTimeout(() => {
                    if (reviewRef?.current) reviewRef.current.refresh();
                }, 100);
            }
            setShowNodeView(() => !showNodeView);
        } else {
            setViewerWindow(() => 1);
            setShowNodeView(() => !showNodeView);
        }


    }

    const updateShow = () => {
        setShow(!show)
    }

    const handleCompareNodes = (doc_id) => {
        const nodeObjects = selectedNodeIds.map(id => ({ id }));
        window.location.href = `${window.location.origin}/#/layout/meddra/${doc_id}/compare-nodes?nodes=${encodeURIComponent(JSON.stringify(nodeObjects))}`;
    };

    const isActive = selectedNodeIds && selectedNodeIds.length > 1
    return (
        <Box sx={{ borderRadius: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '0', width: '100%', }} >
            <CssBaseline />
            {showRightCard && <ResponsiveSearchContainer updateShow={() => updateShow()} isMeddra={true} />}

            <Box sx={{ display: 'flex', zIndex: 1, position: 'absolute', }} className="pqr">
                <Card sx={{
                    // maxWidth: '100%',
                    mt: 1,
                    pl: 1,
                    pr: 2
                    // boxShadow: 'unset',
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LeftMenuList></LeftMenuList>
                        {/* <IconButton
                              aria-label="close"
                              onClick={() => navigate("/layout/uploads")}
                              sx={{
                                  color: (theme) => theme.palette.grey[900],
                                  mx: 1
                              }}
                          >
                              <ArrowBackIcon />
                          </IconButton> */}
                        <Box component="h3" sx={{ m: 0, mt: 1.5, mb: 1, color: '#5a5f62' }}>MedDra
                            <span style={{ borderRight: '2px solid #ccc', paddingLeft: '15px' }}></span>
                            <IconButton
                                aria-label="close"
                                onClick={() => navigate(-1)}
                                sx={{
                                    color: '#9b9598',
                                    ml: 2,
                                    backgroundColor: '#f1f1f1',
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton></Box>

                    </Box>

                </Card>

            </Box>
            {
                showRightCard
                    ?
                    <>
                        <Box component={Card} sx={{ display: 'flex', flexDirection: 'column', zIndex: 1, position: 'absolute', right: 0, mr: 1, mt: 1, alignItems: 'center', p: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {docType === 'text' && <Button onClick={() => { onVisChange() }} sx={{ color: '#797EF6', mr: 1 }}>{showNodeView ? 'Show Pdf' : 'Show Node'}</Button>}
                                <Tooltip title={'Delete Document'}>
                                    <DeleteIcon sx={{ color: '#797EF6', cursor: "pointer", fontSize: 24 }} onClick={openConfirmationDialog} />
                                </Tooltip>
                                <Tooltip title={'Upload New Document'}>
                                    <UploadIcon sx={{ color: '#797EF6', cursor: "pointer", ml: 2, fontSize: 25, mb: '-1px' }} onClick={openUploadDoc} />
                                </Tooltip>
                                <Tooltip title={'Show Commit History'}>
                                    <HistoryIcon sx={{ color: '#797EF6', cursor: "pointer", ml: 2, fontSize: 24 }} onClick={onViewCommitHistory} />
                                </Tooltip>
                            </Box>


                            {!!projectName &&
                                <Accordion sx={{ mt: 2, boxShadow: 'none', }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        sx={{
                                            boxShadow: 'none', minHeight: '0 !important', borderTop: '1px solid #ccc', p: 0, m: 0, pt: 2, '.MuiAccordionSummary-content': {
                                                m: 0,
                                                p: 0,
                                            }
                                        }}
                                    >
                                        <Box sx={{ fontSize: '16px', fontWeight: 300, color: '#858585', pr: 1, }} >{'Project: '}</Box> <Box sx={{ pr: 3 }}>{projectName}</Box>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ px: 0 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Box component={'h4'} sx={{ mt: 0, mb: 1 }}>Document Detail</Box>
                                            {!!docName && <Grid container spacing={2} sx={{ pb: 1, display: 'flex', alignItems: 'center', pl: 0.5 }}>
                                                <Grid item xs={4} sx={{ fontSize: '16px', fontWeight: 300, color: '#858585', }} >{'Name: '}</Grid>
                                                <Grid item xs={8} sx={{ fontSize: '16px', fontWeight: 500, color: '#797EF6', pl: 0.5 }} >{docName}</Grid>
                                            </Grid>}
                                            <Grid sx={{ borderTop: '1px solid #ccc', my: 0.5 }}></Grid>
                                            {!!docDetail && <Grid container spacing={2} sx={{ pb: 1, display: 'flex', alignItems: 'center', pl: 0.5 }}>
                                                <Grid item xs={4} sx={{ fontSize: '16px', fontWeight: 300, color: '#858585' }} >{'Detail: '}</Grid>
                                                <Grid item xs={8} sx={{ fontSize: '16px', fontWeight: 500, color: '#797EF6', pl: 0.5 }} >{docDetail}</Grid>
                                            </Grid>}
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            }
                        </Box></>
                    :

                    <Box
                        component={Card}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            zIndex: 1,
                            position: 'absolute',
                            right: 0,
                            mr: 1,
                            mt: 1,
                            alignItems: 'center',
                            p: 2
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: 3,
                                mb: 2,
                                color: isActive ? '#797EF6' : 'gray',
                                cursor: isActive ? 'pointer' : 'default'
                            }}
                            onMouseDown={
                                isActive
                                    ? () => { handleCompareNodes('f9c542fb-8dfc-4368-9b6c-6e5e2bf4e48a') }
                                    : null
                            }
                        >
                            <CompareArrowsIcon sx={{ mr: 1 }} />
                            <Typography>
                                Compare Nodes
                            </Typography>
                        </Box>
                    </Box>
            }

            {<Card sx={{
                mt: -1,
                ml: -2,
                mr: 'auto',
                transform: 'translateX(0px)',
                boxShadow: 'unset',
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                width: '100%', maxWidth: '100vw'
            }}>

                {viewerWindow === 1 && (docType === 'knowledge-graph' || showNodeView) && <>
                    <Box sx={{ m: 0, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important' }}></Box>
                    {!loading && <Container className='abcd' maxWidth disableGutters sx={{ m: 0, display: 'flex', flexDirection: 'column', p: 0, backgroundColor: '#fff' }}>
                        {empty && <Box sx={{ display: 'flex', minHeight: '85vh', minWidth: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <Box>
                                No data found
                            </Box>
                        </Box>}
                        {!empty && show && <ReteGraph ref={reviewRef} dataset={dataset}></ReteGraph>}
                    </Container>}
                    {!empty && <Box sx={{ position: 'absolute', bottom: 0, left: 0, zIndex: 1, boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2);', backgroundColor: "#fff", p: 1, m: 2, borderRadius: '8px' }}>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <IconButton
                                aria-label="close"
                                onClick={() => reviewRef.current.onZoom(-1)}
                                sx={{
                                    color: (theme) => theme.palette.grey[900],
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <IconButton
                                aria-label="close"
                                onClick={() => reviewRef.current.onZoom(1)}
                                sx={{
                                    color: (theme) => theme.palette.grey[900],
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                            {!openSearchBar && <IconButton
                                id={"search-in-graph"}
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => handleGraphSearch(true)}
                                sx={{ mr: 1, color: blueGrey[900] }}
                            >
                                <SearchIcon sx={{ fontSize: 30 }} />
                            </IconButton>}
                            {openSearchBar && <Box sx={{ display: 'flex', p: 1 }}>
                                <OutlinedInput
                                    id="search-box-d3"
                                    placeholder="Search"
                                    autoComplete="off"
                                    size="small"
                                    inputRef={textChartFieldRef}
                                    endAdornment={<InputAdornment position="end"><SearchIcon sx={{ fontSize: 30, color: '#797EF6' }} /></InputAdornment>}
                                    variant="outlined"
                                    value={searchChartText}

                                    maxRows={5}
                                    sx={{ ...resultTextStyle, flex: isMobile ? 12 : 8, background: "#fff", borderRadius: '8px', maxWidth: "320px", my: 0, mr: isMobile ? 0 : 1, ml: 1 }}
                                    onChange={(e) => {
                                        // setSearchChartText(e.target.value);
                                        setChildSearchText(e.target.value);
                                    }}
                                    onKeyDown={handleSearchChartKeyPress}
                                />
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={() => handleGraphSearch(false)}
                                    sx={{ color: blueGrey[900] }}
                                >
                                    <CloseIcon sx={{ fontSize: 30 }} />
                                </IconButton>
                            </Box>}
                        </Box>
                    </Box>}
                </>}
                {(viewerWindow === 3) && <>
                    <CommitDetails ></CommitDetails>
                </>}
                {viewerWindow === 1 && (docType === 'text' && !showNodeView) && <>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        minWidth: '70vw',
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}>
                        {!pdfUrl && <Box>No pdf exist</Box>}
                        {pdfUrl && <PDFViewer url={pdfUrl} pageNumber={pageNumber} zoomVal={zoomVal} onSetNumPages={onSetNumPages}></PDFViewer>}
                    </Box>
                </>}
            </Card>}
            <AppDialog
                openDialog={openDialog}
                onOpenDialog={closeConfirmationDialog}
                dialogTitle={'Delete Document'}
                maxWidth="md"
                attachedComponent={<Box sx={{ p: 2 }}>Are You Sure you want to delete the document?</Box>}
                actions={<Box sx={{ px: 2, display: 'flex' }}>
                    <Button
                        type="submit"
                        onClick={onDeleteDocument}
                        variant="contained"
                        sx={{ mt: 1, mb: 1, ml: 'auto', backgroundColor: '#797EF6' }}
                    >
                        Yes
                    </Button>
                    <Button
                        type="submit"
                        onClick={closeConfirmationDialog}
                        variant="contained"
                        sx={{ mt: 1, mb: 1, ml: 1, backgroundColor: '#797EF6' }}
                    >
                        No
                    </Button>
                </Box>}
            ></AppDialog>
        </Box >



    );
}