import { useState, useEffect } from "react";
import styled from "styled-components";
import { ClassicScheme, Presets } from "rete-react-plugin";
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import AppDialog from '../../common/AppDialog';
import ColorPickerComponent from "../../common/ColorPickerComponent";
import FormAutocomplete from "../../common/FormAutocomplete";
import { actionsProps } from './vars';
import { strokeMap } from '../../utility/helper';
import axios from "../../utility/api/api";
import './background.css';


const { useConnection } = Presets.classic;

const Svg = styled.svg`
  overflow: visible !important;
  position: absolute;
  pointer-events: none;
`;

const Path = styled.path`
  fill: none;
  stroke-width: 3px;
  stroke-dasharray:${(props) => (props.type === 'dash' ? "10,10" : "")};
  stroke: ${(props) => (props.selected ? "orange" : props.color)};
  pointer-events: auto;
  ${(props) => props.styles && props.styles(props)}
`;

const HoverPath = styled.path`
  fill: none;
  stroke-width: 10px;
  pointer-events: auto;
  stroke: transparent;
`;



export function CustomConnection(props) {
  const { path } = useConnection();
  const id = props.data.id;
  const [openDialog, setOpenDialog] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [action, setActionType] = useState('');
  const [versions, setVersions] = useState([]);
  const [showVersionTextField, setShowVersionTextField] = useState(false);
  const [isReviewLoading, setIsReviewLoading] = useState(false);
  const placeHolderCss = {
    "& input::placeholder": {
      fontSize: "0.8rem"
    },
    fontSize: '0.9rem'
  }
  const {
    register,
    control, setValue,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    reset
  } = useForm({
    mode: 'onChange', // Enable live validation
  });
  const validationRules = {
    node_name: {
      required: 'Node name is required',
    },
    link_name: {
      required: 'Link name is required',
    },
    left_link_name: {
      required: 'Link name is required',
    },
    right_link_name: {
      required: 'Link name is required',
    },
  };

  function getReviewList() {
    setIsReviewLoading(true);
    const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
    const body = { "auth_token": jsonStorage.auth_token };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/get-doc-version-list`,
      data: body
    })
      .then((res) => {
        const data = res.data.version_list;
        //{ version_name: 'Review 1', requested_by: 'sumit@gmail.com', time: formatTimestamp('1708488842000') }
        setIsReviewLoading(false);
        setTimeout(() => {
          setVersions([...data.map(v => ({ value: v.version_id, label: v.version_name }))]);
        }, 100)

      }).catch(err => {
        setIsReviewLoading(false);
      })
  }


  useEffect(() => {
    setVersions(props.data.editor?.versions);
  }, [props.data.editor?.versions]);

  function getActionFormComponent() {
    return <Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Version</Typography>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pt: 2, px: 0 }}>
            <Controller
              name="version"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <Box sx={{
                    display: 'flex', flex: 1, width: isMobile ? '100%' : '315px', // Adjust the width as needed
                    ...placeHolderCss, justifyContent: 'flex-start', alignItems: 'center'
                  }}>
                    {!versions.length && <Box><TextField
                      margin="normal"
                      required
                      fullWidth
                      id="version"
                      placeholder="Enter version name"
                      name="version"
                      autoComplete="version"
                      autoFocus
                      variant='standard'
                      {...register('version_name', {
                        ...validationRules.version,
                      })}
                      error={errors.version}
                      helperText={errors.version && errors.version.message}
                      sx={{
                        width: isMobile ? '100%' : '300px', // Adjust the width as needed
                        ...placeHolderCss
                      }}
                    /></Box>}
                    {versions.length > 0 && <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      <FormAutocomplete id="version" multiple={false} options={versions} {...field} {...register(`version`)} handleChange={(_, val) => { setValue('version', val); }}></FormAutocomplete>
                      <IconButton
                        onPointerDown={(e) => e.stopPropagation()}
                        onDoubleClick={(e) => e.stopPropagation()}
                        onClick={() => {
                          setValue('version', null);
                          setShowVersionTextField(true);
                          // updateField(0, 'is_deleted', true, 'rightLinks')
                        }}
                        color={'#797EF6'}
                      >
                        <ControlPointIcon sx={{ color: '#797EF6' }}></ControlPointIcon>
                      </IconButton>
                      {showVersionTextField && <Box><TextField
                        margin="normal"
                        required
                        fullWidth
                        id="version"
                        placeholder="Enter version name"
                        name="version"
                        autoComplete="version"
                        autoFocus
                        variant='standard'
                        {...register('version_name', {
                          ...validationRules.version,
                        })}
                        error={errors.version}
                        helperText={errors.version && errors.version.message}
                        sx={{
                          width: isMobile ? '100%' : '300px', // Adjust the width as needed
                          ...placeHolderCss
                        }}
                      /></Box>}
                    </Box>}
                  </Box>
                </>
              )}
            />
          </Box>
        </Box>
        <Divider variant="fullWidth" textAlign="left" sx={{ width: "100%" }}>Child Details</Divider>
        {action === 'add' && <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
            <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Node Name</Typography>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="node_name"
                placeholder="Enter node name"
                name="node_name"
                autoComplete="node_name"
                autoFocus
                variant='standard'
                {...register('node_name', {
                  ...validationRules.node_name,
                })}
                error={errors.node_name}
                helperText={errors.node_name && errors.node_name.message}
                sx={{
                  width: isMobile ? '100%' : '300px', // Adjust the width as needed
                  ...placeHolderCss
                }}
                disabled={action === 'delete'}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
            <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Left Link Name</Typography>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="left_link_name"
                placeholder="Enter link name"
                name="left_link_name"
                autoComplete="left_link_name"
                autoFocus
                variant='standard'
                {...register('left_link_name', {
                  ...validationRules.left_link_name,
                })}
                error={errors.left_link_name}
                helperText={errors.left_link_name && errors.left_link_name.message}
                sx={{
                  width: isMobile ? '100%' : '300px', // Adjust the width as needed
                  ...placeHolderCss
                }}
                disabled={action === 'delete'}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
            <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Right Link Name</Typography>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="right_link_name"
                placeholder="Enter link name"
                name="right_link_name"
                autoComplete="right_link_name"
                autoFocus
                variant='standard'
                {...register('right_link_name', {
                  ...validationRules.right_link_name,
                })}
                error={errors.right_link_name}
                helperText={errors.right_link_name && errors.right_link_name.message}
                sx={{
                  width: isMobile ? '100%' : '300px', // Adjust the width as needed
                  ...placeHolderCss
                }}
                disabled={action === 'delete'}
              />
            </Box>
          </Box>
        </Box>}
        {(action === 'delete' || action === 'edit') && <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

          <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Link Name</Typography>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="link_name"
              placeholder="Enter link name"
              name="link_name"
              autoComplete="link_name"
              autoFocus
              variant='standard'
              {...register('link_name', {
                ...validationRules.link_name,
              })}
              error={errors.link_name}
              helperText={errors.link_name && errors.link_name.message}
              sx={{
                width: isMobile ? '100%' : '300px', // Adjust the width as needed
                ...placeHolderCss
              }}
              disabled={action === 'delete'}
            />
          </Box>
        </Box>}
        {(action === 'edit') && <><Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

          <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Link Color</Typography>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pt: 2, px: 2 }}>
            <Controller
              name="link_color"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <Box sx={{
                    display: 'flex', flex: 1, width: isMobile ? '100%' : '300px', // Adjust the width as needed
                    ...placeHolderCss, justifyContent: 'flex-start', alignItems: 'center'
                  }}>
                    <ColorPickerComponent id="link_color" {...field} {...register(`link_color`)} onColorChange={(_color) => { setValue('link_color', _color); }}></ColorPickerComponent>
                    <Box sx={{ backgroundColor: field.value, width: 50, height: 50, ml: 2 }}></Box>
                  </Box>
                </>
              )}
            />
          </Box>
        </Box>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

            <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Link Stroke</Typography>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pt: 2, px: 2 }}>
              <Controller
                name="link_stroke"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <Box sx={{
                      display: 'flex', flex: 1, width: isMobile ? '100%' : '300px', // Adjust the width as needed
                      ...placeHolderCss, justifyContent: 'flex-start', alignItems: 'center'
                    }}>
                      <FormAutocomplete id="link_stroke" options={[{ value: "solid", label: 'Solid' }, { value: "dash", label: 'Dash' }]} {...field} {...register(`link_stroke`)} handleChange={(_, val) => { setValue('link_stroke', val); }}></FormAutocomplete>
                    </Box>
                  </>
                )}
              />
            </Box>
          </Box>
        </>}
        <Box sx={{ px: 2, display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2, backgroundColor: '#797EF6' }}
          >
            {actionsProps[action]?.title}
          </Button>
        </Box>
      </Box>
    </Box>
  }

  const onSubmit = (form) => {
    props.data.actionClick({ action, id, form: { ...form } });
    closeDialog();
    reset();
  };



  if (!path) return null;

  function closeDialog() {
    setOpenDialog(false);
  }
  function onClickLink() {
    props.data.click(props);
    toggleActionBtns()
  }

  function toggleActionBtns() {
    const actionBox = document.querySelector("#link-button" + id);
    var path = document.getElementById(id);
    const pathLength = path.getTotalLength(); // Total length of the path
    const midpoint = path.getPointAtLength(pathLength / 2);
    actionBox.style.left = (midpoint.x - actionBox.offsetWidth / 2) + "px";
    actionBox.style.display = "flex";
    actionBox.style.top = (midpoint.y - actionBox.offsetHeight / 2) + "px";
  }

  function onActionClick(id, key) {
    setActionType(key);
    if (key === 'add') {
      reset({
        node_name: '',
        left_link_name: '',
        right_link_name: '',
        version_id: versions?.length ? versions[0].version_id : null,
        version: versions?.length ? versions[0] : null,
      });
    } else {
      reset({
        link_name: props.data.label,
        link_color: props.data.color,
        link_stroke: { value: props.data.type, label: strokeMap[props.data.type] },
        link_id: id,
        version_id: versions?.length ? versions[0].version_id : null,
        version: versions?.length ? versions[0] : null,
      });
    }

    setOpenDialog(true);
  }

  return (
    <>
      <div className={"svg-container"}>
        <Svg
          onPointerDown={(e) => e.stopPropagation()}
          onClick={() => onClickLink()}
          data-testid="connection"

        >
          <HoverPath d={path} />
          <Path selected={props.data.selected} id={id} styles={props.styles} d={path} type={props.data.type} color={props.data.color} />
          <text style={{ 'cusror': 'pointer' }} dy="-10" fill={props.data.color || "#000"} className="text-along-path">
            <textPath style={{ 'cusror': 'pointer' }} href={"#" + id} textAnchor="end" dominantBaseline="end"
              fontSize="14px" fontWeight="bold" startOffset="65%" >{props.data.label}
              <title>{props.data.label}</title>
            </textPath>
          </text>
        </Svg>

        <Box id={"link-button" + id} className="link-action-button">
          {['edit', 'delete'].map((key, index) => <IconButton
            onPointerDown={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()}
            onClick={() => {
              onActionClick(id, key);
            }}
            color={actionsProps[key].color}
          >
            {actionsProps[key].icon}
          </IconButton>


          )
          }
        </Box>
      </div>
      <AppDialog
        openDialog={openDialog}
        onOpenDialog={closeDialog}
        dialogTitle={actionsProps[action]?.title + ' Link'}
        maxWidth={'sm'}
        fullWidth={false}
        attachedComponent={getActionFormComponent()}
        hideAction={true}
      ></AppDialog>

    </>
  )
}
