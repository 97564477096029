import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import { visuallyHidden } from '@mui/utils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextField } from '@mui/material';

function createData(id, name, calories, fat, carbs, protein) {
    return {
        id,
        name,
        calories,
        fat,
        carbs,
        protein,
    };
}

const sampleRows = [
    createData(1, 'Cupcake', 305, 3.7, 67, 4.3),
    // createData(2, 'Donut', 452, 25.0, 51, 4.9),
    // createData(3, 'Eclair', 262, 16.0, 24, 6.0),
    // createData(4, 'Frozen yoghurt', 159, 6.0, 24, 4.0),
    // createData(5, 'Gingerbread', 356, 16.0, 49, 3.9),
    // createData(6, 'Honeycomb', 408, 3.2, 87, 6.5),
    // createData(7, 'Ice cream sandwich', 237, 9.0, 37, 4.3),
    // createData(8, 'Jelly Bean', 375, 0.0, 94, 0.0),
    // createData(9, 'KitKat', 518, 26.0, 65, 7.0),
    // createData(10, 'Lollipop', 392, 0.2, 98, 0.0),
    // createData(11, 'Marshmallow', 318, 0, 81, 2.0),
    // createData(12, 'Nougat', 360, 19.0, 9, 37.0),
    // createData(13, 'Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'name',
        numeric: false,
        label: 'Dessert (100g serving)',
    },
    {
        id: 'calories',
        numeric: true,
        label: 'Calories',
    },
    {
        id: 'fat',
        numeric: true,
        label: 'Fat (g)',
    },
    {
        id: 'carbs',
        numeric: true,
        label: 'Carbs (g)',
    },
    {
        id: 'protein',
        numeric: true,
        disablePadding: false,
        label: 'Protein (g)',
    },
];
function EnhancedTableHead(props) {
    const { checkBox, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, hideHeaderBorderTop, dense } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead sx={{ borderTop: !hideHeaderBorderTop ? '1px solid #ccc' : '', p: 0 }}>
            <TableRow >
                {checkBox && <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>}
                {columns.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}

                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ fontWeight: '600', padding: dense ? '12px' : 'normal' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    columns: PropTypes.array.isRequired,
    checkBox: PropTypes.bool,
    hideHeaderBorderTop: PropTypes.bool,
    dense: PropTypes.bool
};

function EnhancedTableToolbar(props) {
    const { numSelected, title, toolBarAction, showBack, onBack } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {showBack &&
                <IconButton
                    aria-label="close"
                    onClick={onBack}
                    sx={{
                        // position: 'absolute',
                        // right: 8,
                        // top: 8,
                        color: (theme) => theme.palette.grey[900],
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                // <ArrowBackIcon sx={{ mr: 1 }} onClick={onBack}>
                // </ArrowBackIcon>
            }
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {title}
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <>
                    {/* <Tooltip title="Filter list">
                        <IconButton>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip> */}
                    {toolBarAction}
                    {/* <Button sx={{ display: 'flex', textWrap: 'nowrap',fontSize:'x-small' }} variant="contained" startIcon={<AddIcon />}>
                        <Box >{toolbarActionTitle}</Box>
                    </Button>
                    <Button sx={{ display: 'flex', textWrap: 'nowrap',fontSize:'x-small' }} variant="contained" startIcon={<AddIcon />}>
                        <Box >{toolbarActionTitle}</Box>
                    </Button> */}

                </>

            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    title: PropTypes.string,
    toolBarAction: PropTypes.func,
    showBack: PropTypes.bool,
    onBack: PropTypes.func
};

export default function AppTable(props) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    // const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { toolBarAction,
        rows = sampleRows,
        columns = headCells,
        toolbarTitle = 'Db Detail',
        showCheckBox = false,
        toolbarActionTitle = 'Add',
        onViewClick,
        onDeleteClick,
        showBack,
        onBack,
        hideToolBar,
        hidePagination,
        tableElevation,
        hideHeaderBorderTop,
        dense,
        loading
    } = props;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        if (!showCheckBox) return;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (id) => selected.indexOf(id) !== -1;
    const placeHolderCss = {
        "& input::placeholder": {
            fontSize: "0.8rem"
        },
        fontSize: '0.9rem'
    }
    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = !!!rows.length;

    const visibleRows = useMemo(
        () => {
            if (hidePagination) {
                return stableSort(rows, getComparator(order, orderBy))
            }
            return stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            )
        }
        ,
        [order, orderBy, page, rowsPerPage, rows],
    );

    function onViewRow(row) {
        onViewClick(row);
    }

    function onValueChange(event, newValue, row, field) {
        row[field] = event.target.value;
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={tableElevation}>
                {!hideToolBar && <EnhancedTableToolbar numSelected={selected.length} title={toolbarTitle} toolbarActionTitle={toolbarActionTitle} toolBarAction={toolBarAction} showBack={showBack} onBack={onBack} />}
                <TableContainer sx={{ px: 1 }}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            dense={dense}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            columns={columns}
                            checkBox={showCheckBox}
                            hideHeaderBorderTop={hideHeaderBorderTop}
                        />
                        <TableBody>
                            {!loading && visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.id)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {showCheckBox && <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>}
                                        {columns.filter(col => col.id !== 'action').map(col => <TableCell align={col.numeric ? 'right' : 'left'}>
                                            {col.type === 'editable' && <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="account_name"
                                                placeholder={"Enter "}
                                                name="account_name"
                                                autoComplete="account_name"
                                                autoFocus
                                                variant='outlined'
                                                defaultValue={row[col.id]}
                                                onChange={(event, newValue) => onValueChange(event, newValue, row, col.id)}
                                                size="small"
                                                sx={{
                                                    width: '100%', // Adjust the width as needed
                                                    p: 0,
                                                    m: 0,
                                                    ...placeHolderCss
                                                }}
                                            />}
                                            {col.type === 'chip' && <Chip label={row[col.id]} color={col.colors[row[col.id]]} variant="outlined" />}
                                            {col.type === 'status' && <Chip label={col.statusMap[row[col.id]].value} sx={{ bgcolor: col.statusMap[row[col.id]].color, color: '#fff' }} variant="outlined" />}
                                            {!col.type && row[col.id]}
                                        </TableCell>)}
                                        {columns.find(col => col.id == 'action') && <TableCell align={'left'}>
                                            <Chip
                                                variant="contained"
                                                onClick={() => { onViewRow(row) }}
                                                color="primary"
                                                size="small"
                                                label="View"
                                                icon={<VisibilityIcon color={"red"} />}
                                            />
                                        </TableCell>}
                                    </TableRow>
                                );
                            })}
                            {!loading && emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} rowSpan={4} align='center' >
                                        No data found
                                    </TableCell>
                                </TableRow>
                            )}
                            {loading && <TableCell colSpan={6} rowSpan={4} align='center' ><Box sx={{ width: '100%', display: 'flex', minHeight: '200px', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress />
                            </Box></TableCell>}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!hidePagination && <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />}
            </Paper>
        </Box>
    );
}
