import { useRete } from "rete-react-plugin";
import { useDispatch } from 'react-redux';
import uiAction from '../../../store/actions/uiAction';//../../store/actions/uiAction
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { createEditor } from "./editor";
import Box from '@mui/material/Box';
import { useForm } from "react-hook-form";


const ReteGraph = forwardRef(function (props, ref) {
    const [ref1, editor] = useRete(createEditor);
    const dispatch = useDispatch();
    const [nodeCount, setNodeCount] = useState(0);
    useEffect(() => {
        if (editor) {
            editor.listeners = {};
            setNodeCount(props?.dataset?.nodes?.length);
            editor.addFromJson(props.dataset);
            editor.setDataId(props.dataId)
            editor.showHideGrid(true)

            // editor.addEventListener('customEvent', (log) => {
            //     console.log(log);
            // })
            // editor.addEventListener = function (eventName, callback) {
            //     console.log(eventName);
            // };
            // editor.dispatchEvent = function (eventName, eventData) {
            //     if (!this.listeners[eventName]) {
            //         return;
            //     }
            //     this.listeners[eventName].forEach(callback => {
            //         callback(eventData);
            //     });
            // };


        }
        window.addEventListener('localStorageChange', triggerEvent);
        return () => {
            window.removeEventListener('localStorageChange', triggerEvent);
        };
    }, [props.dataset])
    const [searchText, setSearchText] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        watch,
        reset
    } = useForm({
        mode: 'onChange', // Enable live validation
    });


    function onZoom(sign) {
        if (sign < 0) {
            editor.zoomAt(-0.1)
        } else {
            editor.zoomAt(0.1);
        };
    }

    function refresh() {
        if (editor) {
            editor.listeners = {};
            setNodeCount(props?.dataset?.nodes?.length);
            editor.addFromJson(props.dataset);
            editor.setDataId(props.dataId)
            editor.showHideGrid(true)
        }
    }

    function addIndependentNode(node) {
        editor.addIndependentNode(node);
    }

    function handleSearch(text) {
        const nodeIds = (props.dataset.nodes || []).filter(n => (n.name + '').trim().toLowerCase().includes(text.trim().toLowerCase())).map(n => n.id);
        const links = (props.dataset.links || []).filter(link => nodeIds.includes(link.source) || nodeIds.includes(link.target));
        let connectedNodeIds = [];
        links.forEach(link => {
            if (!connectedNodeIds.includes(link.source)) connectedNodeIds.push(link.source);
            if (!connectedNodeIds.includes(link.target)) connectedNodeIds.push(link.target);
        });
        const nodes = (props.dataset.nodes || []).filter(n => connectedNodeIds.includes(n.id));
        setNodeCount(nodes.length);
        editor.addFromJson({ nodes, links });
    }


    const refreshTree = async () => {
        editor.refreshTree();
    };

    useImperativeHandle(ref, () => ({
        refreshTree,
        setSearchText,
        searchText,
        onZoom,
        addIndependentNode,
        handleSearch,
        refresh
    }));

    function triggerEvent(event) {
        if (event.detail.key === 'nodeCount') {
            setNodeCount(event.detail.value);
        } else if (event.detail.key === 'show-message') {
            dispatch(uiAction.showSnackbar({ type: 'error', message: event.detail.value }));
        }
    }

    return (
        <>
            <Box className="rete-graph-container">
                <Box className="loader-container" id="loaderContainer">
                    <Box className="loader">Loading...</Box>
                </Box>
                {/* <Box sx={{
                    position: 'absolute', zIndex: 1, p: 1, px: 2,
                    top: 130,
                    backgroundColor: 'rgb(255 255 255 / 50%)',
                    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2);',
                    borderRadius: 2,
                    ml: 2
                }}>
                    Node Counts: <b>{nodeCount}</b>
                </Box> */}
                <Box ref={ref1} style={{ height: "calc(100vh - 81px)", position: "relative" }}></Box>
            </Box>
        </>
    )
});

export default ReteGraph;
