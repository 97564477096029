import axios from "../../utility/api/api";
const getNotificationList = async () => {
    return new Promise((resolve, reject) => {
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token, user_id: jsonStorage.user_id };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/get-user-notification`,
            data: body
        })
            .then((res) => {
                const pList = [];
                res.data.data.forEach(innerArray => {
                        pList.push({
                            value: innerArray.data.version_id,
                            label: innerArray.alert_text
                });
                });
                resolve({ error: false, data: pList });
            }).catch(err => {
                console.log(err);
                resolve({ error: true });
            })
    })

}

export default getNotificationList