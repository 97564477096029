import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { blue, blueGrey, orange } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import axios from "../utility/api/api";
import { useDispatch } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UsersComponent from './Users/UsersComponent';
import CustomTabPanel from '../common/CustomTabPanel';
import ProfileComponent from './Users/ProfileComponent';
export default function Profile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(true);
    const sampleFormData = { user_name: '', email_id: '', contact_num: '', org_url: '', org_name: '' }
    const [formData, setFormData] = useState(sampleFormData);
    const [value, setValue] = useState(0);
    const handleChangetabs = (event, newValue) => {
        setValue(newValue);
    };
    const getProfileData = async () => {
        try {
            setLoading(true);
            const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
            const body = { "auth_token": jsonStorage.auth_token };
            const res = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/get-user-details`,
                data: body
            })
            console.log(res.data.data);
            setLoading(false);
            setTimeout(() => {
                setFormData({ ...res.data.data });
            }, 200)
        } catch (error) {
            setLoading(false);
            // console.log(error);
        }


    }

    useEffect(() => {
        getProfileData();
    }, [])
    function onBack() {
        navigate(`/`)
    }
    const abbreviateWord = (word = "") => {
        let abbrWord = "";
        word
            .split(" ")
            .forEach((w) => (abbrWord = abbrWord + (w[0] || "").toUpperCase()));
        return abbrWord;
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box onClick={() => navigate(-1)} sx={{
                    display: 'flex',
                    pr: 2,
                    cursor: 'pointer',
                    '&:hover': { textDecorationLine: 'none', color: blue[500], },
                    color: '#797EF6'
                }}>
                    <ArrowBackIcon sx={{ mr: 1, }} />
                    <Box sx={{ fontWeight: '500'}}>Go Back</Box>
                    </Box>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Profile" {...a11yProps(0)} />
                        <Tab label="Users" {...a11yProps(1)} />
                    </Tabs>
                </Box>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <ProfileComponent></ProfileComponent>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <UsersComponent></UsersComponent>
            </CustomTabPanel>
        </Box >
    )
}
