import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import AppTable from '../common/AppTable';
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import uiAction from '../store/actions/uiAction';
import axios from "../utility/api/api";
const sampleRows = [
    [
        "ID",
        "NUMBER(38,0)",
        "ID"
    ],
    [
        "A",
        "VARCHAR(100)",
        "A"
    ],
    [
        "B",
        "NUMBER(38,0)",
        "B"
    ],
    [
        "C",
        "VARCHAR(100)",
        "C"
    ],
    [
        "D",
        "NUMBER(38,0)",
        "D"
    ],
    [
        "W",
        "VARCHAR(1000)",
        "W"
    ]
]
const DBColumn = forwardRef((props, ref) => {
    let params = useParams();
    const { table_id } = props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tableDesc, setTableDesc] = useState('');//table_desc
    const columns = [
        {
            id: 'num',
            numeric: false,
            label: '#',
        },
        {
            id: 'column',
            numeric: false,
            label: 'Column name',
        },
        {
            id: 'datatype',
            numeric: false,
            label: 'Data type',
        },
        {
            id: 'details',
            numeric: false,
            label: 'Detail',
            type: 'editable'
        }
    ];
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        let data = {
            user_id: jsonStorage.user_id || '',
            table_id: table_id || '',
            "auth_token": jsonStorage.auth_token 
        };
        setLoading(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/get-table-details`,
            data
        })
            .then((res) => {
                if (!res.data.status) {
                    dispatch(uiAction.showSnackbar({ type: 'error', message: res.data.message }));
                    return;
                }
                setRows((res.data.table_details.cols || []).map((row, index) => preparedRow(row, index + 1)))
                setTableDesc(res.data.table_details.table_desc);
                setLoading(false);
                // dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'User registered Successfuly!!!' }));
            })
            .catch((err) => {
                setLoading(false);
                dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
            });
    }, [])


    function preparedRow(item, index) {
        return {
            num: index,
            column: item[0],
            datatype: item[1],
            details: item[2],
            id: item[0],
        }
    }

    function onView(row) {
        // navigate('../column', { ...row })
    }
    function onBack() {
        navigate(`../${params.db_name}/table`)
        // navigate(`../${row.db_name}/table`, { ...row })
    }
    function onTableDescChange(event) {
        setTableDesc(event.target.value);
    }
    const placeHolderCss = {
        "& input::placeholder": {
            fontSize: "0.8rem"
        },
        fontSize: '0.9rem'
    }
    function getRows() {
        return { rows, table_desc: tableDesc }
    }

    useImperativeHandle(ref, () => ({
        getRows,
    }));
    return (
        <Box>
            <Box sx={{ pb: 2, display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%' }}>
                <Typography variant="h6" sx={{
                    textAlign: 'start',
                    fontSize: '1.0rem',
                    padding: 2,
                    paddingRight: 3,
                    // flex:0.5
                }}>Table Details</Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="table_desc"
                    placeholder={"Enter "}
                    name="table_desc"
                    autoComplete="table_desc"
                    autoFocus
                    variant='outlined'
                    value={tableDesc}
                    onChange={onTableDescChange}
                    sx={{
                        flex: 'auto',
                        maxWidth: '500px',
                        width: '100%', // Adjust the width as needed
                        p: 0,
                        m: 0,
                        ...placeHolderCss
                    }}
                />
            </Box>
            <AppTable tableElevation={0}
                hidePagination={true}
                hideToolBar={true}
                showBack={true}
                onBack={onBack}
                columns={columns}
                rows={rows}
                showCheckBox={false}
                toolbarTitle={'Columns'}
                toolbarActionTitle={'Add Column'}
                onViewClick={(row) => onView(row)}
                hideHeaderBorderTop={true}
                dense={true}
                loading={loading}></AppTable>
        </Box>
    )
})

export default DBColumn;
