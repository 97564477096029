import { useRef } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import RefreshIcon from '@mui/icons-material/Refresh';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import AppTable from '../../common/AppTable'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import uiAction from '../../store/actions/uiAction';
import axios from "../../utility/api/api";
import generateRandomTreeData from '../../constants/LoadChecker';
import { showLoader, formatTimestamp, hideLoader, prepareReteTreeV2 } from '../../utility/helper';
import ReteGraph from './rete/rete-graph';
export default function UploadListTable(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [datsetId, setDatsetId] = useState('');
    const [dataset, setDataset] = useState('');

    const columns = [
        {
            id: 'project_name',
            numeric: false,
            label: 'Project name',
        },
        {
            id: 'dataset_name',
            numeric: false,
            label: 'Dataset Name',
        },
        {
            id: 'dataset_details',
            numeric: false,
            label: 'Details',
        },
        {
            id: 'time',
            numeric: false,
            label: 'Upload Time',
        },
        {
            id: 'data_status',
            numeric: false,
            label: 'Status',
            type: 'status',
            statusMap: { '0': { value: 'Pending', color: '#fbc02d' }, '1': { value: 'Processing', color: '#E4A11B' }, '2': { value: 'Registered', color: '#14A44D' }, '3': { value: 'Failed', color: '#DC4C64' }, '4': { value: 'Deregistered', color: '#9FA6B2' } }
        },
        {
            id: 'action',
            numeric: false,
            label: 'Action',
        },
    ];
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(true);
    const reviewRef = useRef();
    useEffect(() => {
        refreshPage(true);
        const interval = setInterval(() => {
            refreshPage(false);
        }, 5000);
        return () => {
            clearInterval(interval);
        }
    }, []);


    function refreshPage(showLoader) {
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        let data = {
            // data_format: 'knowledge-graph',
            user_id: jsonStorage.user_id || '',
            auth_token: jsonStorage.auth_token
        };
        if (showLoader) setLoading(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/get-doc-list`,
            data
        })
            .then((res) => {
                if (!res.data.status) {
                    dispatch(uiAction.showSnackbar({ type: 'error', message: res.data.message }));
                    return;
                }
                setLoading(false);
                let size = res.data.doc_list.doc_details.length;
                const data_ids = res.data.doc_list.doc_id;
                const data_details = res.data.doc_list.doc_details;
                const data_name = res.data.doc_list.doc_name;
                const data_status = res.data.doc_list.doc_status;
                const project_name = res.data.doc_list.project_name;
                const project_id = res.data.doc_list.project_id;
                const time = res.data.doc_list.time;
                let prepRows = [];

                for (let i = 0; i < size; i++) {
                    prepRows.push({ id: data_ids[i], dataset_name: data_name[i], dataset_details: data_details[i], data_status: data_status[i], time: time[i], project_name: project_name[i], project_id: project_id[i] });
                }
                setRows(prepRows);
                // setRows((res.data.data_list.data_details || []).map((row) => preparedRow(row)))

                // dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'User registered Successfuly!!!' }));
            })
            .catch((err) => {
                setLoading(false);
                dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
            });
    }

    function preparedRow(item) {
        return { id: item[0], dataset_name: item[1], dataset_details: item[2] }
    }

    function onView(row) {
        // setDatsetId(row.id);
        // prepareLoadData(10);
        // setShowTable(false);
        // props.setIsGraphOpened(true);
        const { project_id, project_name } = row;
        navigate('' + row.id + "/details", { state: { project_id, project_name } })
    }
    function prepareLoadData(n) {
        const preparedDataSet = generateRandomTreeData(n);
        showLoader();
        setTimeout(() => {
            setDataset(preparedDataSet);
        }, 1000)
    }
    function onBack() {
        setShowTable(true);
    }


    return (
        <Box>
            {!props.isGraphOpened && <AppTable
                loading={loading}
                columns={columns}
                rows={rows}
                showCheckBox={false}
                toolbarTitle={'Document List'}
                toolbarActionTitle={'Add Dataset'}
                toolBarAction={<Box sx={{ mr: 2 }}><IconButton
                    aria-label="close"
                    onClick={refreshPage}
                    sx={{
                        color: (theme) => theme.palette.grey[900],
                    }}
                >
                    <RefreshIcon />
                </IconButton>
                </Box>}
                onViewClick={(row) => onView(row)}
            ></AppTable>}
            {props.isGraphOpened && <>
                {/* <Box sx={{ width: '100%', maxWidth: '100%', backgroundColor: "#fff", borderRadius: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', p: 4 }} component={Paper} elevation={1}>

                    <ResponsiveSearchContainer
                        hideDbType={true}
                        hideDefaultSampleQuery={true}
                        showBackBtn={true}
                        extraBody={{ data_id: datsetId }
                        }
                        onBack={onBack}
                        queryUrl='api/query-table-data'
                    ></ResponsiveSearchContainer>
                </Box> */}
                <Card sx={{
                    mt: -1,
                    ml: -4,
                    mr: 'auto',
                    transform: 'translateX(0px)',
                    boxShadow: 'unset',
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%', maxWidth: '99vw'
                }}>
                    {<Container maxWidth disableGutters sx={{ m: 0, display: 'flex', flexDirection: 'column', p: 0, backgroundColor: '#fff' }}>
                        {<ReteGraph ref={reviewRef} dataset={dataset} selectedReview={{}} ></ReteGraph>}
                    </Container>}
                </Card>
            </>}
        </Box>
    )
}
