// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRef, useState, Fragment } from 'react';
import axios from "../../utility/api/api";
import InfoIcon from '@mui/icons-material/Info';
import Card from '@mui/material/Card';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from 'react';
import ReteGraph from './rete/rete-graph';
import generateRandomTreeData from '../../constants/LoadChecker';//./constants/LoadChecker
import { showLoader, formatTimestamp, hideLoader, formateVersion } from '../../utility/helper';
import LeftMenuList from '../../common/LeftMenuList';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import { blueGrey } from '@mui/material/colors';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

export default function Review(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [dataset, setDataset] = useState(null);
    const [isReviewOpen, setIsReviewOpen] = useState(false);
    const [isReviewLoading, setIsReviewLoading] = useState(false);
    const [selectedReview, setSelectedReview] = useState(null);
    const [openSearchBar, setOpenSearchBar] = useState(false);
    const [searchChartText, setSearchChartText] = useState('');
    const [reviews, setReviews] = useState([{ version_name: 'Review 1', requested_by: 'sumit@gmail.com', time: formatTimestamp('1708488842000') },
    { version_name: 'Review 2', requested_by: 'sumit@gmail.com', time: formatTimestamp('1710994442000') },
    ]);
    const { id } = useParams();
    const resultTextStyle = {
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
        fontFamily: 'Google Sans, Helvetica Neue, sans - serif',
        letterSpacing: 'normal',
        whiteSpace: 'pre - wrap',
    }
    const isMobile = useMediaQuery('(max-width:600px)');

    const [anchorGraphSearchEl, setGraphSearchAnchorEl] = useState(null);
    const openGraphSearch = Boolean(anchorGraphSearchEl);
    const handleGraphSearch = (status) => {
        setOpenSearchBar(status);
    };
    const handleGraphSearchClose = () => {
        setGraphSearchAnchorEl(null);
    };

    const reviewRef = useRef();
    const textChartFieldRef = useRef(null);

    function prepareLoadData(n) {
        const preparedDataSet = generateRandomTreeData(n);
        showLoader();
        setTimeout(() => {
            setDataset(preparedDataSet);
        }, 1000)
    }

    function getReviewList() {
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/get-doc-version-list`,
            data: body
        })
            .then((res) => {
                const data = res.data.version_list;
                //{ version_name: 'Review 1', requested_by: 'sumit@gmail.com', time: formatTimestamp('1708488842000') }
                let filterdata = data.filter((d)=> d.version_id===id)
                setTimeout(() => {
                    setReviews([...data]);
                }, 100)
                onSelectReview(filterdata[0])
            }).catch(err => {
            })
    }

    function ReviewCard(props) {
        return <>
            <Box onClick={props.onSelect} sx={{
                minWidth: '200px',
                cursor: 'pointer', display: 'flex', flexDirection: 'column', backgroundColor: props.selected ? '#e3effa73' : '#f9fbfb', p: 1, mb: 1, borderRadius: 1,
                ':hover': {
                    backgroundColor: 'aliceblue',
                    '> h4': { fontWeight: '500' }
                }
            }}>
                <Box component={'h4'} sx={{
                    display: 'flex',
                    m: 0, color: props.selected ? '#797EF6' : '#000', fontWeight: '400',
                }}>{props.data.version_name}
                    <Tooltip title={props.data.version_detail}> <InfoIcon sx={{ color: props.selected ? '#797EF6' : '#ccc', pl: 1 }} /></Tooltip></Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Box sx={{ color: props.selected ? '#9499f7' : '#9ca1a0', fontSize: '16px', mr: 1 }}>{props.data.requested_by || 'dummy@gmail.com'}</Box> */}
                    <Box sx={{ color: props.selected ? '#9499f7' : '#9ca1a0', fontSize: '14px' }}>{props.data.time}</Box>
                </Box>
            </Box>

        </>;
    }
    function ReviewCardLabel(props) {
        return <>
            <Box sx={{
                cursor: 'pointer', display: 'flex', flexDirection: 'column', backgroundColor: '#fff', p: 2, py: 1, mb: 1, borderRadius: 1,
            }}>
                <Box component={'h4'} sx={{ m: 0, color: '#797EF6', display: 'flex' }}>{props.data.version_name}
                    <Tooltip title={props.data.version_detail}> <InfoIcon sx={{ color: '#797EF6', pl: 1 }} /></Tooltip></Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Box sx={{ color: '#9499f7', fontSize: '16px', mr: 1 }}>{props.data.requested_by || 'dummy@gmail.com'}</Box> */}
                    <Box sx={{ color: '#9499f7', fontSize: '14px' }}>{props.data.time}</Box>
                </Box>
            </Box>

        </>;
    }


    useEffect(() => {
        const storage = localStorage.getItem('sielo_search_app');
        if (storage) {
            const storageJson = JSON.parse(storage);
            if (!storageJson.isLoggedIn) {
                navigate("/sign-in");
            }
            getReviewList();
        } else {
            navigate("/sign-in");
        }
    }, []);

    useEffect(() => {
        onSelectReview({ version_id: id });
    }, [id]);


    function onSelectCloseBtn() {
        setIsReviewOpen(!isReviewOpen)
    }

    const setChildSearchText = (text) => {
        setSearchChartText(text);
        reviewRef.current.setSearchText(text);
    }

    const handleSearchChartKeyPress = (event) => {
        if (event.key === 'Enter') {
            reviewRef.current.handleSearch(searchChartText);
        }
    };

    function getVersionDetail(review) {
        showLoader();
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token, version_id: review.version_id };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/get-doc-version-detail`,
            data: body
        })
            .then((res) => {
                const data = res.data.nodes;
                hideLoader();
                setTimeout(() => {
                    setDataset(formateVersion(data, review.version_id));
                }, 100)
            }).catch(err => {
                hideLoader();
            })
    }

    const onSelectReview = (review) => {
        setSelectedReview(review);
        getVersionDetail(review);
    }



    return (
        <Box sx={{ borderRadius: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '0', width: '100%', }} >
            <CssBaseline />

            <Box sx={{ display: 'flex', zIndex: 1, position: 'absolute', }} className="pqr">
                <Card sx={{
                    // maxWidth: '100%',
                    mt: 1,
                    // boxShadow: 'unset',
                }}>
                    <Box sx={{ display: 'flex', px: 1, alignItems: 'flex-start', py: 1 }}>
                        <LeftMenuList></LeftMenuList>

                    </Box>
                </Card>
            </Box>
            <Box sx={{ display: 'flex', zIndex: 1, position: 'absolute', right: '16px' }} className="">
                <Card sx={{
                    // maxWidth: '100%',
                    mt: 1,
                    // boxShadow: 'unset',
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                        <Box sx={{ pl: 2, display: 'flex', alignItems: 'flex-start', pt: 1.5 }}>
                            <Box component="h3" sx={{ m: 0, pr: 1 }}>Review</Box>

                            <IconButton onClick={onSelectCloseBtn} sx={{ ml: 'auto', p: 0, mt: -0.5, mr: 1 }}>{isReviewOpen ? <ExpandLessIcon sx={{ width: '1.5em', height: '1.5em' }}></ExpandLessIcon> : <ExpandMoreIcon sx={{ width: '1.5em', height: '1.5em' }}></ExpandMoreIcon>}</IconButton>
                        </Box>
                        {!isReviewLoading && !isReviewOpen && selectedReview &&
                            <ReviewCardLabel data={selectedReview}></ReviewCardLabel>
                        }
                        {!isReviewLoading && isReviewOpen && <><Box sx={{ my: 1, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
                            <Box sx={{ maxHeight: '50vh', overflow: 'auto', px: 1 }}>
                                {reviews.map((review) => (<ReviewCard key={review.version_id} onSelect={() => onSelectReview(review)} selected={selectedReview === review} data={review}></ReviewCard>))}
                            </Box>
                        </>}

                        {isReviewLoading && <> <Box sx={{ display: 'flex', minHeight: '500px', minWidth: '300px', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </Box></>}

                    </Box>
                </Card>

            </Box>
            {!selectedReview && <Box sx={{ display: 'flex', minHeight: '70vh', minWidth: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Box>
                    Select a review from right
                </Box>
            </Box>}
            {selectedReview && <Card sx={{
                mt: -1,
                ml: -2,
                mr: 'auto',
                transform: 'translateX(0px)',
                boxShadow: 'unset',
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                width: '100%', maxWidth: '100vw'
            }}>
                <Box sx={{ m: 0, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
                {loading && <Box>Loading</Box>}
                {!loading && <Container className='abcd' maxWidth disableGutters sx={{ m: 0, display: 'flex', flexDirection: 'column', p: 0, backgroundColor: '#fff' }}>
                    {<ReteGraph ref={reviewRef} dataset={dataset} selectedReview={selectedReview}></ReteGraph>}
                </Container>}
                <Box sx={{ position: 'absolute', bottom: 0, left: 0, zIndex: 1, boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2);', backgroundColor: "#fff", p: 1, m: 2, borderRadius: '8px' }}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <IconButton
                            aria-label="close"
                            onClick={() => reviewRef.current.onZoom(-1)}
                            sx={{
                                color: (theme) => theme.palette.grey[900],
                            }}
                        >
                            <RemoveIcon />
                        </IconButton>
                        <IconButton
                            aria-label="close"
                            onClick={() => reviewRef.current.onZoom(1)}
                            sx={{
                                color: (theme) => theme.palette.grey[900],
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                        {!openSearchBar && <IconButton
                            id={"search-in-graph"}
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={() => handleGraphSearch(true)}
                            sx={{ mr: 1, color: blueGrey[900] }}
                        >
                            <SearchIcon sx={{ fontSize: 30 }} />
                        </IconButton>}
                        {openSearchBar && <Box sx={{ display: 'flex', p: 1 }}>
                            <OutlinedInput
                                id="search-box-d3"
                                placeholder="Search"
                                autoComplete="off"
                                size="small"
                                inputRef={textChartFieldRef}
                                endAdornment={<InputAdornment position="end"><SearchIcon sx={{ fontSize: 30, color: '#797EF6' }} /></InputAdornment>}
                                variant="outlined"
                                value={searchChartText}

                                maxRows={5}
                                sx={{ ...resultTextStyle, flex: isMobile ? 12 : 8, background: "#fff", borderRadius: '8px', maxWidth: "320px", my: 0, mr: isMobile ? 0 : 1, ml: 1 }}
                                onChange={(e) => {
                                    // setSearchChartText(e.target.value);
                                    setChildSearchText(e.target.value);
                                }}
                                onKeyDown={handleSearchChartKeyPress}
                            />
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => handleGraphSearch(false)}
                                sx={{ color: blueGrey[900] }}
                            >
                                <CloseIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                        </Box>}
                    </Box>
                </Box>
            </Card>}
        </Box >

    );
}