import { useState, useEffect } from 'react';
import * as d3 from 'd3';
export default function BarChart(props) {

    const id = "grouped-bar-chart";
    const tableau10 = [
        '#4E79A7', '#F28E2C', '#E15759', '#76B7B2', '#59A14F',
        '#EDC949', '#AF7AA1', '#FF9DA7', '#9C755F', '#BAB0AB'
    ];

    // Create a D3 ordinal scale with Tableau10 colors
    const colorScale = d3.scaleOrdinal().range(tableau10);

    const [data, setData] = useState([
    ]);


    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        if (data.length) {
            initGraph();
        }
    }, [data])

    function initGraph() {
        d3.select(`#${id}`).selectAll("*").remove();

        const legendWidth = 0;

        var margin = { top: 10, right: 30, bottom: 20, left: 50 },
            width = 800 - margin.left - margin.right,
            height = 400 - margin.top - margin.bottom;

        // append the svg object to the body of the page
        var svg = d3.select(`#${id}`)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");
        d3.select(`#${id}`)
            .append("div")
            .attr("id", "tooltip");

        var groups = data.map(d => d.xAxis);

        var x = d3.scaleBand()
            .domain(groups)
            .range([0, width])
            .padding([0.2])
        svg.append("g")
            .attr("transform", "translate(10," + (height - margin.bottom) + ")")
            .call(d3.axisBottom(x));

        // Add Y axis
        var y = d3.scaleLinear()
            .domain([0, d3.max(data, function (d) {
                return d.value;
            })])
            .range([height - margin.bottom, 0]);
        svg.append("g")
            .attr("transform", "translate(20,0)")
            .call(d3.axisLeft(y));
        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text("Value");
        svg.append("text")
            // .attr("transform", "rotate(-90)")
            .attr("y", height + 10)
            .attr("x", (width - margin.left - legendWidth) / 2)
            // .attr("dx", "1em")
            .style("text-anchor", "middle")
            .text("API");

        // Show the bars
        svg.append("g")
            .selectAll("rect")
            .data(data)
            .enter()
            .append("rect")
            .attr('x', (s) => x(s.xAxis) + x.bandwidth() / 2 - 10)
            .attr('y', (s) => y(s.value))
            .attr('height', (s) => height - y(s.value) - margin.bottom)
            .attr('fill', d => colorScale(d))
            .attr('id', (s) => "rect-" + s.xAxis)
            .attr('width', 40)
            .on('mouseover', function () {
                let el = document.getElementById("rect-" + this.__data__.xAxis);
                const clientRect = el.getBoundingClientRect();
                const div = d3.select("#tooltip")
                    .attr("class", "tooltip-bar-chart")
                    .style("position", "absolute")
                    .style("visibility", "visible")
                    .style("background", "#f5f5f5")
                    .style("left", clientRect.left + 40 + "px")
                    .style("top", clientRect.top + "px")

                div.append('div')
                    .attr('class', '')
                    .style("position", "relative")
                    .html(`<div style="display:flex;flex-direction:column; padding:8px;border-top:4px solid #8bc34a;font-size:12px">
                              <div>API: ${this.__data__.xAxis}</div>
                              <div>${props.yColumn}: ${this.__data__.value}</div>
                           </div>`);
            })
            .on('mouseout', function () {
                // svg.selectAll('.svg-tooltip').remove();
                // svg.selectAll('polygon').remove();
                d3.select("#tooltip").selectAll("*").remove();
                d3.select("#tooltip").style("visibility", "hidden")
            });

    }

    return <>
        <div id={id} style={{ width: 800, height: 450 }}></div>
    </>;
}