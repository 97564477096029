import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "../../utility/api/api";
import uiAction from '../../store/actions/uiAction';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import Typography from "@mui/material/Typography";
import { blue, blueGrey, lightBlue, grey } from '@mui/material/colors';
export default function DataAnalytics() {
    const fileInput = useRef();
    const resultTextStyle = {
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
        fontFamily: 'Google Sans, Helvetica Neue, sans - serif',
        letterSpacing: 'normal',
        whiteSpace: 'pre - wrap',
    }
    const isMobile = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const options = [{ label: 'My SQL', value: 'mysql' }, { label: 'Snowflake', value: 'snowflake' }];
    const [selectedValue, setSelectedValue] = useState({ label: 'Snowflake', value: 'snowflake' });
    const [uploadedFile, setUploadedFile] = useState(null);
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        watch,
        reset
    } = useForm({
        mode: 'onChange', // Enable live validation
    });

    const validationRules = {
        data_name: {
            required: 'Data name is required',
            // pattern: {
            //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            //     message: 'Invalid email',
            // },
        },
        dataset_details: {
            required: 'Dataset detail is required',
        },
        data_file: {
            required: 'Csv file detail is required',
        },
    };

    const [loading, setLoading] = useState(false);

    const placeHolderCss = {
        "& input::placeholder": {
            fontSize: "0.8rem"
        },
        fontSize: '0.9rem'
    }

    const onSubmit = (form) => {
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        setLoading(true);
        const formData = new FormData();
        // Append the file to the FormData object
        formData.append('data_file', uploadedFile);
        formData.append('data_name', form.data_name);
        formData.append('data_details', form.dataset_details);
        formData.append('auth_token', jsonStorage.auth_token);

        // Make a POST request using Axios
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/upload-table-data`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                if (!res.data.status) {
                    dispatch(uiAction.showSnackbar({ type: 'error', message: res.data.message }));
                    return;
                }
                setLoading(false);
                reset();
                dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'Dataset registered Successfuly!!!' }));
            })
            .catch((err) => {
                setLoading(false);
                dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
            });


    };
    function onFileChoose(e) {
        const MAX_FILE_SIZE = (5000 * 1024)

        if (e.target.files.length > 0) {

            const uploadedFileSize = e.target.files[0].size / 1024;

            if (uploadedFileSize < MAX_FILE_SIZE) {
                setUploadedFile(e.target.files[0])
                e.currentTarget.value = null // this the place where we need to assign null value

            } else {
                console.log('file is large')
            }
        }
    }
    return (
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <Box component={'form'} onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box sx={{ width: isMobile ? '100%' : '600px', maxWidth: '100%', backgroundColor: "#fff", borderRadius: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', p: 4 }} component={Paper} elevation={1}>
                    <Typography variant="h6" sx={{ pb: 1, justifySelf: 'flex-start', alignSelf: 'flex-start', lineHeight: 0 }}>Register Data</Typography>
                    <Divider color={blue[500]} sx={{ height: 2, width: '102px', mt: 1 }} />
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Dataset Name</Typography>
                        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="data_name"
                                placeholder="Enter Data name"
                                name="data_name"
                                autoComplete="data_name"
                                autoFocus
                                variant='standard'
                                {...register('data_name', {
                                    ...validationRules.data_name,
                                })}
                                error={errors.data_name}
                                helperText={errors.data_name && errors.data_name.message}
                                sx={{
                                    width: isMobile ? '100%' : '300px', // Adjust the width as needed
                                    ...placeHolderCss
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Data detail</Typography>
                        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="dataset_details"
                                placeholder="Enter Data detail"
                                name="dataset_details"
                                autoComplete="dataset_details"
                                autoFocus
                                variant='standard'
                                sx={{
                                    width: isMobile ? '100%' : '300px', // Adjust the width as needed
                                    ...placeHolderCss
                                }}
                                {...register('dataset_details', {
                                    ...validationRules.dataset_details,
                                })}
                                error={errors.dataset_details}
                                helperText={errors.dataset_details && errors.dataset_details.message}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', mb: 4, mt: 2 }}>
                        <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Choose CSV File</Typography>
                        <Box sx={{ display: 'flex', flex: 1, alignItems: 'start', justifyContent: 'start' }}>
                            <div style={{ width: isMobile ? '100%' : '320px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => fileInput.current.click()}
                                    sx={{ backgroundColor: '#797EF6' }}
                                >
                                    upload file
                                </Button>

                                <input
                                    ref={fileInput}
                                    type="file"
                                    // {...register('data_file', {
                                    //     ...validationRules.data_file,
                                    // })}
                                    onChange={(event) => {
                                        onFileChoose(event)
                                        return false
                                    }}
                                    style={{ display: 'none' }}
                                // accept=".csv" 
                                />
                                {uploadedFile?.name && <div>{uploadedFile?.name}</div>}
                                {errors.data_file && <span>{errors.data_file.message}</span>}
                            </div>
                        </Box>

                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', px: 1 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!isValid || loading || !uploadedFile}
                            startIcon={loading ? <CircularProgress size={20} /> : null}
                            sx={{ borderRadius: '8px', p: 1, mt: 0, px: 2, ml: 'auto',backgroundColor: '#797EF6' }}
                            disableElevation
                        >
                            Register
                        </Button>
                    </Box>

                </Box>
            </Box>



        </Container>
    )
}
