import { Outlet } from "react-router-dom";
import Container from '@mui/material/Container';
import LeftMenuList from "./common/LeftMenuList";
export default function Databases() {
  return (
    <>
     <LeftMenuList></LeftMenuList>
      <Container>
        <Outlet />
      </Container>
    </>
  )
}
