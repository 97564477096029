import Box from '@mui/material/Box'
import AppTable from '../common/AppTable'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import uiAction from '../store/actions/uiAction';
import axios from "../utility/api/api";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
const sampleRows = [
    {
        "db_name": "COLLAB_DB",
        "store_name": "Snowflake"
    },
    {
        "db_name": "FRAUD_VAL_DB1",
        "store_name": "Snowflake"
    }
];
export default function DataStore() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const columns = [
        {
            id: 'store_name',
            numeric: false,
            label: 'Store name',
        },
        {
            id: 'db_name',
            numeric: false,
            label: 'Database name',
        },
        {
            id: 'action',
            numeric: false,
            label: 'Action',
        },
    ];

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        let data = {
            user_id: jsonStorage.user_id || '',
            "auth_token": jsonStorage.auth_token 
        };
        setLoading(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/get-db-list`,
            data
        })
            .then((res) => {
                if (!res.data.status) {
                    dispatch(uiAction.showSnackbar({ type: 'error', message: res.data.message }));
                    return;
                }
                setLoading(false);
                setRows((res.data.data || []).map((row) => preparedRow(row)))

                // dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'User registered Successfuly!!!' }));
            })
            .catch((err) => {
                setLoading(false);
                dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
            });
    }, [])

    function preparedRow(item) {
        return { ...item, id: item.store_name, }
    }
    // const computedRows = rows.map((row) => preparedRow(row));
    function onView(row) {
        navigate(`../${row.db_name}/table`, { ...row })
    }

    return (
        <Box>
            <AppTable
                loading={loading}
                columns={columns}
                rows={rows}
                showCheckBox={false}
                toolbarTitle={'Datastore'}
                toolbarActionTitle={'Add Datastore'}
                onViewClick={(row) => onView(row)}
            ></AppTable>
        </Box>
    )
}
