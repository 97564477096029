import { useEffect, useState } from "react";
import styled from "styled-components";
import { ClassicScheme, Presets } from "rete-react-plugin";
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from "react-hook-form";
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
// import AppDialog from '../../../common/AppDialog';
import ColorPickerComponent from "../../../common/ColorPickerComponent";
import FormAutocomplete from "../../../common/FormAutocomplete";
import { actionsProps } from './vars';
import { strokeMap } from '../../../utility/helper';
import './background.css';
import Menu from '@mui/material/Menu';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

const { useConnection } = Presets.classic;

const Svg = styled.svg`
  overflow: visible !important;
  position: absolute;
  pointer-events: none;
`;

const Path = styled.path`
  fill: none;
  stroke-width: 3px;
  stroke-dasharray:${(props) => (props.type === 'dash' ? "10,10" : "")};
  stroke: ${(props) => (props.selected ? "orange" : props.color)};
  pointer-events: auto;
  ${(props) => props.styles && props.styles(props)}
`;

const HoverPath = styled.path`
  fill: none;
  stroke-width: 10px;
  pointer-events: auto;
  stroke: transparent;
`;



export function CustomConnection(props) {
  const { path } = useConnection();
  const id = props.data.id;
  const [openDialog, setOpenDialog] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [action, setActionType] = useState('');
  const [reviewAnchorEl, setReviewAnchorEl] = useState(null);
  const openReview = Boolean(reviewAnchorEl);
  const handleReviewClick = (event) => {
    setReviewAnchorEl(event.currentTarget);
  };
  const handleReviewClose = () => {
    setReviewAnchorEl(null);
  };
  useEffect(() => {
    setTimeout(() => {
      positionReviewBtn();
    }, 200)
  }, [path]);
  const placeHolderCss = {
    "& input::placeholder": {
      fontSize: "0.8rem"
    },
    fontSize: '0.9rem'
  }
  const {
    register,
    control, setValue,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    reset
  } = useForm({
    mode: 'onChange', // Enable live validation
  });
  const validationRules = {
    node_name: {
      required: 'Node name is required',
    },
    link_name: {
      required: 'Link name is required',
    },
    left_link_name: {
      required: 'Link name is required',
    },
    right_link_name: {
      required: 'Link name is required',
    },
  };

  function getActionFormComponent() {
    return <Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
        {action === 'add' && <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
            <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Node Name</Typography>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="node_name"
                placeholder="Enter node name"
                name="node_name"
                autoComplete="node_name"
                autoFocus
                variant='standard'
                {...register('node_name', {
                  ...validationRules.node_name,
                })}
                error={errors.node_name}
                helperText={errors.node_name && errors.node_name.message}
                sx={{
                  width: isMobile ? '100%' : '300px', // Adjust the width as needed
                  ...placeHolderCss
                }}
                disabled={action === 'delete'}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
            <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Left Link Name</Typography>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="left_link_name"
                placeholder="Enter link name"
                name="left_link_name"
                autoComplete="left_link_name"
                autoFocus
                variant='standard'
                {...register('left_link_name', {
                  ...validationRules.left_link_name,
                })}
                error={errors.left_link_name}
                helperText={errors.left_link_name && errors.left_link_name.message}
                sx={{
                  width: isMobile ? '100%' : '300px', // Adjust the width as needed
                  ...placeHolderCss
                }}
                disabled={action === 'delete'}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
            <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Right Link Name</Typography>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="right_link_name"
                placeholder="Enter link name"
                name="right_link_name"
                autoComplete="right_link_name"
                autoFocus
                variant='standard'
                {...register('right_link_name', {
                  ...validationRules.right_link_name,
                })}
                error={errors.right_link_name}
                helperText={errors.right_link_name && errors.right_link_name.message}
                sx={{
                  width: isMobile ? '100%' : '300px', // Adjust the width as needed
                  ...placeHolderCss
                }}
                disabled={action === 'delete'}
              />
            </Box>
          </Box>
        </Box>}
        {(action === 'delete' || action === 'edit') && <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

          <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Link Name</Typography>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="link_name"
              placeholder="Enter link name"
              name="link_name"
              autoComplete="link_name"
              autoFocus
              variant='standard'
              {...register('link_name', {
                ...validationRules.link_name,
              })}
              error={errors.link_name}
              helperText={errors.link_name && errors.link_name.message}
              sx={{
                width: isMobile ? '100%' : '300px', // Adjust the width as needed
                ...placeHolderCss
              }}
              disabled={action === 'delete'}
            />
          </Box>
        </Box>}
        {(action === 'edit') && <><Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

          <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Link Color</Typography>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pt: 2, px: 2 }}>
            <Controller
              name="link_color"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <Box sx={{
                    display: 'flex', flex: 1, width: isMobile ? '100%' : '300px', // Adjust the width as needed
                    ...placeHolderCss, justifyContent: 'flex-start', alignItems: 'center'
                  }}>
                    <ColorPickerComponent id="link_color" {...field} {...register(`link_color`)} onColorChange={(_color) => { setValue('link_color', _color); }}></ColorPickerComponent>
                    <Box sx={{ backgroundColor: field.value, width: 50, height: 50, ml: 2 }}></Box>
                  </Box>
                </>
              )}
            />
          </Box>
        </Box>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

            <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Link Stroke</Typography>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pt: 2, px: 2 }}>
              <Controller
                name="link_stroke"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <Box sx={{
                      display: 'flex', flex: 1, width: isMobile ? '100%' : '300px', // Adjust the width as needed
                      ...placeHolderCss, justifyContent: 'flex-start', alignItems: 'center'
                    }}>
                      <FormAutocomplete id="link_stroke" options={[{ value: "solid", label: 'Solid' }, { value: "dash", label: 'Dash' }]} {...field} {...register(`link_stroke`)} handleChange={(_, val) => { setValue('link_stroke', val); }}></FormAutocomplete>
                    </Box>
                  </>
                )}
              />
            </Box>
          </Box>
        </>}
        <Box sx={{ px: 2, display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2, backgroundColor: '#797EF6' }}
          >
            {actionsProps[action]?.title}
          </Button>
        </Box>
      </Box>
    </Box>
  }

  const onSubmit = (form) => {
    props.data.actionClick({ action, id, form: { ...form } });
    closeDialog();
    reset();
  };



  if (!path) return null;

  function closeDialog() {
    setOpenDialog(false);
  }
  function onClickLink() {
    props.data.click(props);
    // toggleActionBtns()
  }



  function toggleActionBtns() {
    const actionBox = document.querySelector("#link-button" + id);
    var path = document.getElementById(id);
    const pathLength = path.getTotalLength(); // Total length of the path
    const midpoint = path.getPointAtLength(pathLength / 2);
    actionBox.style.left = (midpoint.x - actionBox.offsetWidth / 2) + "px";
    actionBox.style.display = "flex";
    actionBox.style.top = (midpoint.y - actionBox.offsetHeight / 2) + "px";
  }

  function ReviewContainer() {
    const colors = { 'a': '#c4f7c4', 'u': '#f7f5c4', 'd': '#f7d8c4' };
    if (!props.data.action) return '';
    return <><Box sx={{
      position: 'absolute',
      right: "24px", top: "-24px",
      p: 0.5, pb: 0,
      border: '1px solid #000',
      borderRadius: '4px',
      cursor: 'pointer',
      background: colors[props.data.action],
      color: '#000'
    }}

    >
      <Box id={"review-link-button" + id} sx={{ position: 'relative' }} onPointerDown={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        onClick={handleReviewClick}>
        <ReviewLabel></ReviewLabel>
      </Box>
    </Box>
      <Menu
        id={"review-menu" + id}
        anchorEl={reviewAnchorEl}
        open={openReview}
        onClose={handleReviewClose}
        MenuListProps={{
          'aria-labelledby': 'review-link-button' + id,
        }}
        className="padding-0"
      >

        <Box sx={{ display: 'flex', flexDirection: 'column', p: 0, m: 0, minHeight: '380px', minWidth: '500px' }}>
          {props.data.action === 'u' && <Box sx={{ px: 2 }}>
            <Box sx={{ display: 'flex' }}>
              <Box component={'h3'} sx={{ my: 2 }}>Review Link Update</Box>
              <IconButton onClick={handleReviewClose} sx={{ ml: 'auto' }}><CloseIcon></CloseIcon></IconButton>
            </Box>
            <Box sx={{ mb: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
            <Box sx={{ display: 'flex', fontWeight: 'bold', pb: 1 }}>
              <Box sx={{ flex: 4 }}>Field Name</Box>
              <Box sx={{ flex: 4 }}>Current Version</Box>
              <Box sx={{ flex: 4 }}>Review Version</Box>
            </Box>
            {props.data['label'] && <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 4 }}>Name</Box>
              <Box sx={{ flex: 4 }}>{props.data.label}</Box>
              <Box sx={{ flex: 4 }}>{props.data['label']}</Box>
            </Box>}
            {props.data['type'] && <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 4 }}>Label</Box>
              <Box sx={{ flex: 4 }}>{props.data.type}</Box>
              <Box sx={{ flex: 4 }}>{props.data['type']}</Box>
            </Box>}
            {props.data['color'] && <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 4 }}>Color</Box>
              <Box sx={{ flex: 4 }}>{props.data.color}</Box>
              <Box sx={{ flex: 4 }}>{props.data['color']}</Box>
            </Box>}
            <Box sx={{ my: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
            <Box sx={{ display: 'flex', mt: 2 }}>
              <Box sx={{ flex: 4 }}>Comment</Box>
              <Box sx={{ flex: 8 }}>{props.data.comment}</Box>
            </Box>

            <Box sx={{ my: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                sx={{ mt: 3, mr: 2, mb: 2, ml: 'auto', backgroundColor: '#797EF6' }}
                onClick={handleReviewClose}
              >
                ACCEPT
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: '#797EF6' }}
                onClick={handleReviewClose}
              >
                DECLINE
              </Button>
            </Box>
          </Box>}
          {props.data.action === 'a' && <Box sx={{ px: 2 }}>
            <Box sx={{ display: 'flex' }}>
              <Box component={'h3'} sx={{ my: 2 }}>Review Link Addition</Box>
              <IconButton onClick={handleReviewClose} sx={{ ml: 'auto' }}><CloseIcon></CloseIcon></IconButton>
            </Box>
            <Box sx={{ mb: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important' }}></Box>
            <Box sx={{ display: 'flex', fontWeight: 'bold', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Field Name</Box>
              <Box sx={{ flex: 6 }}>Added Field Value</Box>
            </Box>
            <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 4 }}>Name</Box>
              <Box sx={{ flex: 4 }}>{props.data.label}</Box>
            </Box>
            <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 4 }}>Label</Box>
              <Box sx={{ flex: 4 }}>{props.data.type}</Box>
            </Box>
            <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 4 }}>Color</Box>
              <Box sx={{ flex: 4 }}>{props.data.color}</Box>
            </Box>
            <Box sx={{ my: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
            <Box sx={{ display: 'flex', mt: 2 }}>
              <Box sx={{ flex: 4 }}>Comment</Box>
              <Box sx={{ flex: 8 }}>{props.data.comment}</Box>
            </Box>

            <Box sx={{ my: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                onClick={handleReviewClose}
                sx={{ mt: 3, mr: 2, mb: 2, ml: 'auto', backgroundColor: '#797EF6' }}
              >
                ACCEPT
              </Button>
              <Button
                variant="contained"
                onClick={handleReviewClose}
                sx={{ mt: 3, mb: 2, backgroundColor: '#797EF6' }}
              >
                DECLINE
              </Button>
            </Box>
          </Box>}
          {props.data.action === 'd' && <Box sx={{ px: 2 }}>
            <Box sx={{ display: 'flex' }}>
              <Box component={'h3'} sx={{ my: 2 }}>Review Link Delete</Box>
              <IconButton onClick={handleReviewClose} sx={{ ml: 'auto' }}><CloseIcon></CloseIcon></IconButton>
            </Box>
            <Box sx={{ mb: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
            <Box sx={{ display: 'flex', fontWeight: 'bold', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Field Name</Box>
              <Box sx={{ flex: 6 }}>Field Value</Box>
            </Box>
            <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Name</Box>
              <Box sx={{ flex: 6 }}>{props.data.label}</Box>
            </Box>
            <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Label</Box>
              <Box sx={{ flex: 6 }}>{props.data.type}</Box>
            </Box>
            <Box sx={{ display: 'flex', pb: 1 }}>
              <Box sx={{ flex: 6 }}>Color</Box>
              <Box sx={{ flex: 6 }}>{props.data.color}</Box>
            </Box>
            <Box sx={{ my: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
            <Box sx={{ display: 'flex', mt: 2 }}>
              <Box sx={{ flex: 4 }}>Comment</Box>
              <Box sx={{ flex: 8 }}>{props.data.comment}</Box>
            </Box>

            <Box sx={{ my: 2, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                sx={{ mt: 3, mr: 2, mb: 2, ml: 'auto', backgroundColor: '#797EF6' }}
                onClick={handleReviewClose}
              >
                ACCEPT
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: '#797EF6' }}
                onClick={handleReviewClose}
              >
                DECLINE
              </Button>
            </Box>
          </Box>}
        </Box>
      </Menu ></>;
  }


  function ReviewLabel() {
    switch (props.data.action) {
      case 'a': {
        return <><Box sx={{ position: 'absolute', bottom: -4, left: 2 }}>A</Box>
          <AccountTreeIcon sx={{ color: 'black' }} /></>
      }
      case 'u': {
        return <><Box sx={{ position: 'absolute', bottom: -4, left: 2 }}>U</Box>
          <AccountTreeIcon sx={{ color: 'black' }} /></>
      }
      case "d": {
        return <><Box sx={{ position: 'absolute', bottom: -4, left: 2 }}>D</Box>
          <AccountTreeIcon sx={{ color: 'black' }} /></>
      }
    }
  }

  function positionReviewBtn() {
    const actionBox = document.querySelector("#review-link-button" + id);
    if(!actionBox) return;
    var path = document.getElementById(id);
    const startPoint = path.getPointAtLength(0);
    actionBox.style.left = (startPoint.x + 45) + "px";
    actionBox.style.display = "flex";
    actionBox.style.top = (startPoint.y + 20) + "px";
  }

  function onActionClick(id, key) {
    setActionType(key);
    if (key === 'add') {
      reset({
        node_name: '',
        left_link_name: '',
        right_link_name: '',
      });
    } else {
      reset({
        link_name: props.data.label,
        link_color: props.data.color,
        link_stroke: { value: props.data.type, label: strokeMap[props.data.type] },
        link_id: id
      });
    }

    setOpenDialog(true);
  }

  return (
    <>
      <div className={"svg-container"}>
        <Svg
          onPointerDown={(e) => e.stopPropagation()}
          onClick={() => onClickLink()}
          data-testid="connection"

        >
          <HoverPath d={path} />
          <Path selected={props.data.selected} id={id} styles={props.styles} d={path} type={props.data.type} color={props.data.color} />
          <text style={{ 'cusror': 'pointer' }} dy="-10" fill={props.data.color || "#000"} className="text-along-path">
            <textPath style={{ 'cusror': 'pointer' }} href={"#" + id} textAnchor="end" dominantBaseline="end"
              fontSize="14px" fontWeight="bold" startOffset="65%" >{props.data.label}
              <title>{props.data.label}</title>
            </textPath>
          </text>
        </Svg>

        {/* <Box id={"link-button" + id} className="link-action-button">
          {['edit', 'delete'].map((key, index) => <IconButton
            onPointerDown={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()}
            onClick={() => {
              onActionClick(id, key);
            }}
            color={actionsProps[key].color}
          >
            {actionsProps[key].icon}
          </IconButton>


          )
          }
        </Box> */}
        {/* <Box id={"review-link-button" + id} className="link-review-button">
          {ReviewContainer()}
        </Box> */}
      </div>
      {/* <AppDialog
        openDialog={openDialog}
        onOpenDialog={closeDialog}
        dialogTitle={actionsProps[action]?.title + ' Link'}
        maxWidth={'sm'}
        fullWidth={false}
        attachedComponent={getActionFormComponent()}
        hideAction={true}
      ></AppDialog> */}

    </>
  )
}
