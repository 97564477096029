import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { blue, orange } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import axios from "../../utility/api/api";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AppDialog from '../../common/AppDialog';
import CircularProgress from '@mui/material/CircularProgress';
const LoadingButton = ({ loading, onClick, children }) => {
    return (
        <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={onClick}
            sx={{ backgroundColor: '#797EF6' }}
        >
            {loading ? <CircularProgress size={24} color="inherit" /> : children}
        </Button>
    );
};
export default function Token() {
    const queryUrl = 'api/generate-auth-token';
    const [openDialog, setOpenDialog] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const sampleFormData = { user_name: 'Sumit Kumar', email_id: 'sumit0001.jnv@gmail.com', mobile_no: '7003207979', org_url: 'test.com', org_name: 'test' }
    const [formData, setFormData] = useState({})

    function closeDialog(status) {
        setOpenDialog(status)
    }
    function attachedDialogueComp() {
        return <>Are you sure you want to regenerate token?</>
    }

    function getActions() {
        return <>
            <LoadingButton loading={apiLoading} variant='contained' color="error" autoFocus onClick={() => onFormSubmit(true)}>
                Yes
            </LoadingButton>
            <Button variant='contained' sx={{ fontSize: 'sm', backgroundColor: '#797EF6' }} autoFocus color="error" onClick={() => onFormSubmit(false)}>
                No
            </Button></>
    }

    useEffect(() => {

        const storage = localStorage.getItem('sielo_search_app');
        if (storage) {
            const storageJson = JSON.parse(storage);
            setFormData({ ...formData, ...storageJson });
        }
    }, [])

    function onFormSubmit(isSubmit) {
        if (!isSubmit) {
            setOpenDialog(false);
            return;
        };
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token };
        setApiLoading(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/${queryUrl}`,
            data: body
        })
            .then((res) => {
                const data = res.data.auth_token;
                jsonStorage.auth_token = data;
                localStorage.setItem("sielo_search_app", JSON.stringify(jsonStorage));
                setFormData({ ...formData, ...jsonStorage });
                setApiLoading(false);
                setOpenDialog(false);
            })
    }

    function onBack() {
        navigate(`/`)
    }
    const abbreviateWord = (word = "") => {
        let abbrWord = "";
        word
            .split(" ")
            .forEach((w) => (abbrWord = abbrWord + (w[0] || "").toUpperCase()));
        return abbrWord;
    };

    return (
        <Paper sx={{ width: '70%', mb: 2, display: 'flex', flexDirection: 'column' }} elevation={1}>
            <Box
                aria-label="close"
                sx={{
                    // position: 'absolute',
                    // right: 8,
                    // top: 8,
                    p: 2,
                    // fontSize: '18px',
                    display: 'flex',
                    alignItems: 'center',
                    color: (theme) => theme.palette.grey[900],
                }}
            >

                <Box sx={{ fontWeight: '500' }}>Token</Box>
            </Box>
            <Divider></Divider>
            <Box sx={{ width: '100%', display: 'flex', minHeight: 'calc(100vh - 250px)', flexDirection: isMobile ? 'column' : 'row' }}>
                <Box sx={{ minHeight: isMobile ? '50%' : '100%', flex: 0.5, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: blue[400], color: '#000', px: '32px' }}>
                    <Avatar sx={{ bgcolor: orange[500], width: 100, height: 100, mb: 2, color: '#000', fontSize: '32px' }}>{abbreviateWord(formData.user_name)}</Avatar>
                    <Typography variant={'h6'} sx={{ fontWeight: '500', }}>{formData.user_name}</Typography>
                    <Typography variant={'p'} sx={{ fontWeight: '200' }}>{formData.email_id}</Typography>
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', px: 3, }}>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="org_url"
                            label="Token"
                            name="org_url"
                            type="text"
                            variant='standard'
                            autoFocus
                            value={formData.auth_token}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Tooltip title={"Regenerate Token"} placement={"bottom"} arrow>
                                <IconButton
                                    onClick={() => setOpenDialog(true)}
                                    sx={{ m: 1, mx: 2 }}
                                ><AutorenewIcon /></IconButton>
                            </Tooltip>

                        </Box>

                    </Box>
                    {/* <Box sx={{ display: 'flex', flexDirection: 'row-reverse', px: 0 }}>
                    <Button
                            margin="normal"
                            autoFocus
                            variant='contained'
                            sx={{ mt: 6, mb: isMobile ? 3 : 0 }}
                            onClick={onFormSubmit}
                        >Generate Token</Button>
                    </Box> */}

                </Box>
            </Box>
            <AppDialog
                openDialog={openDialog}
                onOpenDialog={closeDialog}
                dialogTitle={'Regenerate Token'}
                maxWidth="xs"
                attachedComponent={attachedDialogueComp()}
                actions={getActions()}
            ></AppDialog>
        </Paper >

    )
}
