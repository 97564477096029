// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RefreshIcon from '@mui/icons-material/Refresh';
import HomeIcon from '@mui/icons-material/Home';
import UploadIcon from '@mui/icons-material/Upload';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import CssBaseline from '@mui/material/CssBaseline';

import TextField from '@mui/material/TextField';
import ColorPickerComponent from './common/ColorPickerComponent';
import FormAutocomplete from './common/FormAutocomplete';
import Button from '@mui/material/Button';
import { useRef, useState, Fragment } from 'react';
import { blue, blueGrey, lightBlue } from '@mui/material/colors';
import axios from "./utility/api/api";
import { useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Loader from './Loader';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
// import NetworkGraph from './Pages/network-graphs/NetworkGraph';
// import TidyTree from './charts/TidyTree';
import ReteGraph from './charts/rete/rete-graph';
// import response from './demo-response.json';
import AppDialog from './common/AppDialog';
import { useForm, Controller } from "react-hook-form";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SendIcon from '@mui/icons-material/Send';
import generateRandomTreeData from './constants/LoadChecker';
import { showLoader, colorMap } from './utility/helper';
import LeftMenuList from './common/LeftMenuList';
import CloseIcon from '@mui/icons-material/Close';
import uiAction from './store/actions/uiAction';
export default function ResponsiveSearchContainer(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const allData = {
        nodes: [
            { id: 1, name: 'AGGR', label: 'Final Calc/Aggregation', group: 'Team C', runtime: 20 },
            { id: 2, name: 'ASMT', label: 'State Data/Assessment Repository', group: 'Team B', runtime: 60 },
            { id: 3, name: 'CALC', label: 'Reporting/Final Calc', group: 'Team C', runtime: 30 },
            { id: 4, name: 'DEMOFDDGGGD', label: 'Snapshot/Demographic', group: 'Team A', runtime: 40 },
            { id: 5, name: 'ELIG', label: 'State Data/Eligibility', group: 'Team B', runtime: 20 },
            { id: 6, name: 'GOAL', label: 'MOSL/Goal Setting', group: 'Team D', runtime: 60 },
            { id: 7, name: 'GROW', label: 'MOSL/Growth Model', group: 'Team D', runtime: 60 },
            { id: 8, name: 'LINK DFSSD', label: 'Snapshot/Linkage', group: 'Team A', runtime: 100 },
            { id: 9, name: 'MOSL', label: 'Reporting/MOSL', group: 'Team D', runtime: 80 },
            { id: 10, name: 'MOTP', label: 'Final Calc/MOTP', group: 'Team C', runtime: 20 },
            { id: 11, name: 'REPTFFF', label: 'Reportingsgfs', group: 'Team E', runtime: 240 },
            { id: 12, name: 'SEDD', label: 'Reporting/State Data', group: 'Team B', runtime: 30 },
            { id: 13, name: 'SNAP', label: 'Reporting/Snapshot', group: 'Team A', runtime: 40 },
            { id: 14, name: 'PTRN', label: 'State Data/pattern', group: 'Team B', runtime: 40 }
        ],
        links: [
            { source: 3, target: 1, type: 'Next -->>' },
            { source: 13, target: 8, type: 'Next -->>' },
            { source: 3, target: 10, type: 'Next -->>' },
            { source: 11, target: 3, type: 'Next -->>' },
            { source: 9, target: 6, type: 'Go to ->>' },
            { source: 9, target: 7, type: 'Go to ->>' },
            { source: 11, target: 9, type: 'Go to ->>' },
            { source: 11, target: 12, type: 'Go to ->>' },
            { source: 12, target: 5, type: 'Go to ->>' },
            { source: 12, target: 14, type: 'Go to ->>' },
            { source: 12, target: 2, type: 'Go to ->>' },
            { source: 11, target: 13, type: 'Go to ->>' },
            { source: 13, target: 4, type: 'This way>>' },
        ]
    }
    const [dataset, setDataset] = useState(allData);
    const [versions, setVersions] = useState([]);
    const [sampleQueries, setSampleQueries] = useState([]);
    const [showVersionTextField, setShowVersionTextField] = useState(false);
    const [isReviewLoading, setIsReviewLoading] = useState(false);
    const [isGridBg, setIsGridBg] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const queryUrl = props.queryUrl || 'api/sielo-search'
    const isMobile = useMediaQuery('(max-width:600px)');
    // const classes = useStyles();
    const textFieldRef = useRef(null);
    const textChartFieldRef = useRef(null);
    const networkChartRef = useRef();
    const options = [{ label: 'Knowledge Graph', value: 'knowledge-graph' }, { label: 'Table Data', value: 'table-data' }, { label: 'Snowflake Data', value: 'snowflake-data' }];

    const [searchText, setSearchText] = useState('');
    const [searchChartText, setSearchChartText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openSearchBar, setOpenSearchBar] = useState(false);
    const [selectedValue, setSelectedValue] = useState({ label: 'Knowledge Graph', value: 'knowledge-graph' });
    const [queryResData, setQueryResData] = useState(null);
    const [isSourceSelected, setisSourceSelected] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorDrawerEl, setDrawerAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openDrawer = Boolean(anchorDrawerEl);
    const handleDrawerClick = (event) => {
        setDrawerAnchorEl(event.currentTarget);
    };
    const handleDrawerClose = () => {
        setDrawerAnchorEl(null);
    };
    const handleGraphSearch = (status) => {
        setOpenSearchBar(status);
    };

    const [anchorGraphSearchEl, setGraphSearchAnchorEl] = useState(null);
    const openGraphSearch = Boolean(anchorGraphSearchEl);
    const handleGraphSearchClick = (event) => {
        setGraphSearchAnchorEl(event.currentTarget);
    };
    const handleGraphSearchClose = () => {
        handleGraphSearch(false)
    };

    const focusTextField = () => {
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    };

    function removeDuplicate(arr) {
        let newArr = [];
        arr.filter(l => l.source !== l.target).forEach(item => {
            const hasSome = newArr.some(i => i.source === item.source && i.target === item.target);
            if (!hasSome) {
                newArr.push(item);
            }
        })
        return newArr;
    }

    function prepareReteTreeV2(nodeObj) {
        let preparedNodes = (nodeObj.nodes_list || []).map(n => ({
            id: n.id,
            name: n.text,
            link: n.link || [],
            doc_id: n.doc_id || '',
            color: n.meta.color || "#f0f0f0",
            category: n.meta.label,
            child_count: n.child_count
        }));
        let preparedLinks = (nodeObj.edge_list || []).map(link => ({
            id: link.id,
            source: link.pc[0],
            target: link.pc[1],
            type: link.meta.stroke,
            label: link.text,
            color: link.meta.color || "steelblue"
        }));
        preparedLinks = removeDuplicate(preparedLinks);
        // updateLinks(preparedLinks);
        const preparedDataSet = {
            nodes: preparedNodes,
            links: preparedLinks
        }
        setTimeout(() => {
            setDataset(preparedDataSet);
        }, 100)
    }
    function prepareLoadData(n) {
        showLoader();
        const preparedDataSet = generateRandomTreeData(n);
        setDataset(preparedDataSet);
    }

    function onSourceSelect(sourceRes) {
        const nodeObj = sourceRes.nodes;
        // prepareTidyTreeData(nodeObj);
        const loadTesting = localStorage.getItem('loadtesting')
        if (!loadTesting) prepareReteTreeV2(nodeObj);
        else prepareLoadData(+loadTesting);
    }
    const handleButtonClick = (resSelected) => {
        setisSourceSelected(resSelected);
        if (resSelected) {
            setTimeout(() => {
                onSourceSelect(queryResData.source_data);
            })
        }
    }
    const handleSearch = async (text = '') => {
        // Handle the search action here (e.g., make an API request)
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        setIsLoading(true);
        setQueryResData(null);
        if (!text) text = searchText;
        if (selectedValue && text) {
            // const headers = {
            //     'Content-Type': 'application/json', // Indicate that you're sending JSON data
            // };
            const body = { ...(props.extraBody || {}), "data_source": selectedValue.value, "text_query": text };
            if (props.isMeddra) props.updateShow()
            axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/${queryUrl}`,
                data: body
            })
                .then((res) => {
                    const data = res.data;
                    setIsLoading(false);
                    setTimeout(() => {
                        setQueryResData(data);
                        setTimeout(() => {
                            if (selectedValue.value === 'knowledge-graph') onSourceSelect(data.source_data);
                        }, 100)
                    }, 100)

                }).catch(err => {
                    setIsLoading(false);
                    setDataset({
                        nodes: [],
                        links: []
                    });
                    dispatch(uiAction.showSnackbar({ type: 'error', message: 'Oops! Something went wrong. Please try again later.' }));
                    //
                })
        }

    };

    const searchCard = (text) => {
        setSearchText(text);
        setTimeout(() => {
            handleSearch(text);
        }, 100)

    }

    const timeout = {}

    const handleSelectChange = (newValue) => {
        setSelectedValue(newValue);
        handleClose();
        // You can perform any additional actions here when an option is selected
    };






    const handleSearchKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (!event.shiftKey) {
                event.preventDefault();
            } else {
                // Move cursor to the next line
                // You can add your custom logic here if needed
                console.log('Shift + Enter pressed', event.target.value);
                setSearchText(event.target.value);
            }
            if (timeout.searchTimeout) clearTimeout(timeout.searchTimeout);
            timeout.searchTimeoutsearchTimeout = setTimeout(() => {
                handleSearch();
            }, 100)
            // event.preventDefault();
        } else {
            setSearchText(event.target.value);
        }
        // setSearchText(event.target.value);
        // if (event.key === 'Enter') {
        //     if (timeout.searchTimeout) clearTimeout(timeout.searchTimeout);
        //     timeout.searchTimeoutsearchTimeout = setTimeout(() => {
        //         handleSearch();
        //     }, 100)
        // }
    };

    const handleSearchChartKeyPress = (event) => {
        if (event.key === 'Enter') {
            networkChartRef.current.handleSearch(searchChartText);
        }
    };

    const setChildSearchText = (text) => {
        setSearchChartText(text);
        networkChartRef.current.setSearchText(text);
    }

    const tabBtnStyle = (isSource) => {
        if ((isSource && isSourceSelected) || (!isSource && !isSourceSelected)) {
            return { textDecoration: 'underline', fontSize: '14px', color: blue['A700'], 'text-underline-offset': '4px', fontWeight: 600, p: 0, ':hover': { backgroundColor: 'white' } };
        } else {
            return { textDecoration: '', fontSize: '14px', color: blueGrey['600'], 'text-underline-offset': '4px', fontWeight: 600, p: 0, ':hover': { backgroundColor: 'white' } };
        }

    }

    const resultTextStyle = {
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
        fontFamily: 'Segoe UI, Tahoma, sans-serif',
        letterSpacing: '0.16px',
        whiteSpace: 'pre - wrap',
    }

    const sampleCardClass = { '>:first-child': { color: "#5a5f62" }, letterSpacing: '0.16px', fontSize: '16px', border: '1px solid #dde3e8', borderRadius: '6px', display: 'flex', flexDirection: 'column', p: '30px 30px 36px', boxShadow: '0px 2px 10px 0px #5e748226', backgroundColor: '#ffffff', fontWeight: '400', '&:hover': { background: '#f7f9fb', cursor: 'pointer', '>:first-child': { fontWeight: '400', color: "#395FF0" } } };
    useEffect(() => {
        const storage = localStorage.getItem('sielo_search_app');
        if (storage) {
            const storageJson = JSON.parse(storage);
            if (!storageJson.isLoggedIn) {
                navigate("/sign-in");
            }
            getReviewList();
            fetchSampleApiData();
        } else {
            navigate("/sign-in");
        }

    }, []);

    const fetchSampleApiData = async () => {
        try {
            const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
            const body = { user_id: jsonStorage.user_id };
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/get-sample-query`,
                data: body
            })
            if (!response.data.status) {
                dispatch(uiAction.showSnackbar({ type: 'error', message: response.data.message }));
                return;
            }
            if (response?.data?.data?.length) {
                setSampleQueries(response.data.data.filter(i => !!i));
            }
        } catch (error) {
            console.log(error);
            dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
        }

    }

    function getReviewList() {
        setIsReviewLoading(true);
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/get-doc-version-list`,
            data: body
        })
            .then((res) => {
                const data = res.data.version_list;
                //{ version_name: 'Review 1', requested_by: 'sumit@gmail.com', time: formatTimestamp('1708488842000') }
                setIsReviewLoading(false);
                setTimeout(() => {
                    setVersions([...data.map(v => ({ value: v.version_id, label: v.version_name }))]);
                }, 100)

            }).catch(err => {
                setIsReviewLoading(false);
            })
    }

    function closeDialog(status) {
        setOpenDialog(status);
    }
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isValid },
        watch,
        reset,
        setValue
    } = useForm({
        mode: 'onChange', // Enable live validation
    });
    const validationRules = {
        node_name: {
            required: 'Node name is required',
        },
    };
    const onSubmit = (form) => {
        networkChartRef.current.addIndependentNode(form);
        setOpenDialog(false);
        reset();

    };
    const placeHolderCss = {
        "& input::placeholder": {
            fontSize: "0.8rem"
        },
        fontSize: '0.9rem'
    };
    function getActionFormComponent() {
        return <Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Version</Typography>
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pt: 2, px: 0 }}>
                        <Controller
                            name="version"
                            control={control}
                            defaultValue={versions.length ? versions[0] : null}
                            render={({ field }) => (
                                <>
                                    <Box sx={{
                                        display: 'flex', flex: 1, width: isMobile ? '100%' : '315px', // Adjust the width as needed
                                        ...placeHolderCss, justifyContent: 'flex-start', alignItems: 'center'
                                    }}>
                                        {!versions.length && <Box><TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="version"
                                            placeholder="Enter version name"
                                            name="version"
                                            autoComplete="version"
                                            autoFocus
                                            variant='standard'
                                            {...register('version_name', {
                                                ...validationRules.version,
                                            })}
                                            error={errors.version}
                                            helperText={errors.version && errors.version.message}
                                            sx={{
                                                width: isMobile ? '100%' : '300px', // Adjust the width as needed
                                                ...placeHolderCss
                                            }}
                                        /></Box>}
                                        {versions.length > 0 && <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <FormAutocomplete id="version" multiple={false} options={versions} {...field} {...register(`version`)} handleChange={(_, val) => { setValue('version', val); }}></FormAutocomplete>
                                            <IconButton
                                                onPointerDown={(e) => e.stopPropagation()}
                                                onDoubleClick={(e) => e.stopPropagation()}
                                                onClick={() => {
                                                    setValue('version', null);
                                                    setShowVersionTextField(true);
                                                    // updateField(0, 'is_deleted', true, 'rightLinks')
                                                }}
                                                color={'#797EF6'}
                                            >
                                                <ControlPointIcon sx={{ color: '#797EF6' }}></ControlPointIcon>
                                            </IconButton>
                                            {showVersionTextField && <Box><TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="version"
                                                placeholder="Enter version name"
                                                name="version"
                                                autoComplete="version"
                                                autoFocus
                                                variant='standard'
                                                {...register('version_name', {
                                                    ...validationRules.version,
                                                })}
                                                error={errors.version}
                                                helperText={errors.version && errors.version.message}
                                                sx={{
                                                    width: isMobile ? '100%' : '300px', // Adjust the width as needed
                                                    ...placeHolderCss
                                                }}
                                            /></Box>}
                                        </Box>}
                                    </Box>
                                </>
                            )}
                        />
                    </Box>
                </Box>
                <Divider variant="fullWidth" textAlign="left" sx={{ width: "100%" }}>Node Details</Divider>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Node Name</Typography>
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="node_name"
                            placeholder="Enter node name"
                            name="node_name"
                            autoComplete="node_name"
                            autoFocus
                            variant='standard'
                            {...register('node_name', {
                                ...validationRules.node_name,
                            })}
                            error={errors.node_name}
                            helperText={errors.node_name && errors.node_name.message}
                            sx={{
                                width: isMobile ? '100%' : '300px', // Adjust the width as needed
                                ...placeHolderCss
                            }}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Node Color</Typography>
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pt: 2, px: 2 }}>
                        <Controller
                            name="node_color"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <>
                                    <Box sx={{
                                        display: 'flex', flex: 1, width: isMobile ? '100%' : '300px', // Adjust the width as needed
                                        ...placeHolderCss, justifyContent: 'flex-start', alignItems: 'center'
                                    }}>
                                        <ColorPickerComponent id="node_color" {...field} {...register(`node_color`)} onColorChange={(_color) => { setValue('node_color', _color); }}></ColorPickerComponent>
                                        <Box sx={{ backgroundColor: field.value, width: 50, height: 50, ml: 2 }}></Box>
                                    </Box>
                                </>
                            )}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', px: 2 }} variant="subtitle2">Node Label</Typography>
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', pt: 2, px: 0 }}>
                        <Controller
                            name="node_label"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <>
                                    <Box sx={{
                                        display: 'flex', flex: 1, width: isMobile ? '100%' : '315px', // Adjust the width as needed
                                        ...placeHolderCss, justifyContent: 'flex-start', alignItems: 'center'
                                    }}>
                                        <FormAutocomplete id="node_label" pqr={6} multiple={true} options={[{ label: 'Circle', value: 'circle' }, { label: 'Square', value: 'square' }, { label: 'Triangle', value: 'triangle' }]} {...field} {...register(`node_label`)} handleChange={(_, val) => { setValue('node_label', val); }}></FormAutocomplete>
                                    </Box>
                                </>
                            )}
                        />
                    </Box>
                </Box>

                <Box sx={{ px: 2, display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, backgroundColor: '#797EF6' }}
                    >
                        Create
                    </Button>
                </Box>
            </Box>
        </Box>
    }
    function getActions() {
        return <Box sx={{ px: 2, display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
                type="submit"

                variant="contained"
                sx={{ mt: 1, mb: 1, backgroundColor: '#797EF6' }}
            >
                Create
            </Button>
        </Box>;
    }

    const id = 'db-dropdown';
    const formattedText = (text) => text.split('\n').map((line, index) => (
        <Fragment key={index}>
            {line}
            <br />
        </Fragment>
    ));

    function resetGrid() {
        networkChartRef.current.showHideGrid(!isGridBg);
        setIsGridBg(!isGridBg);
    }

    return (
        <Box sx={{ borderRadius: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '0', width: '100%' }}>
            <CssBaseline />
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', zIndex: 1 }}>
                <Box>
                    <Card sx={{
                        maxWidth: '100%',
                        mt: 1,
                        // boxShadow: 'unset',
                    }}>
                        <CardActions sx={{}}>
                            {/* <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerClick}
                                sx={{ mr: 0, color: blueGrey[900] }}
                            >
                                <MenuIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                            <Menu
                                id={"basic-menu" + id}
                                key={id}
                                anchorEl={anchorDrawerEl}
                                open={openDrawer}
                                onClose={handleDrawerClose}
                                MenuListProps={{
                                    'aria-labelledby': 'db-dropdown-button',
                                }}
                            >
                                <LeftMenuList refresh={() => { setQueryResData(null); setSearchText(''); }}></LeftMenuList>
                            </Menu> */}
                            {!props.isMeddra &&
                                <LeftMenuList refresh={() => { setQueryResData(null); setSearchText(''); }}></LeftMenuList>
                            }
                            {isSourceSelected && !isLoading && queryResData && <>
                                <Button onClick={() => handleButtonClick(false)} sx={{ ...tabBtnStyle(false), mr: 1, lineHeight: -1 }}>Response</Button>
                                <Button onClick={() => handleButtonClick(true)} sx={{ ...tabBtnStyle(true), mr: 1, lineHeight: -1 }}>Source</Button>
                            </>}


                        </CardActions>
                    </Card>
                </Box>
                <form onSubmit={() => handleSearch()} style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', display: 'flex', maxWidth: '800px', paddingTop: '24px' }}>
                    <Box className="search-container" sx={{ width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center', alignItems: isMobile ? 'flex-start' : 'center', boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2)', backgroundColor: "#fff", p: 1, rowGap: 1 }}>
                        {props.showBackBtn &&
                            <Box sx={{ mr: 2 }}><IconButton
                                aria-label="close"
                                onClick={props.onBack}
                                sx={{
                                    // position: 'absolute',
                                    // right: 8,
                                    // top: 8,
                                    color: (theme) => theme.palette.grey[900],
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            </Box>
                        }
                        {!props.hideDbType && !props.isMeddra && <>
                            <Box sx={{ borderRight: '2px solid #ccc', padding: '4px', mr: 1 }}>
                                <IconButton
                                    id={"db-dropdown-button"}
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    {open ? <ArrowDropUpIcon sx={{ fontSize: "30px" }} /> : <ArrowDropDownIcon sx={{ fontSize: "30px" }} />}
                                </IconButton>
                            </Box>
                            <Menu
                                id={"basic-menu" + id}
                                key={id}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'db-dropdown-button',
                                }}
                            >
                                {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                                {options.map(op => <MenuItem selected={selectedValue.value === op.value} onClick={() => handleSelectChange(op)}>
                                    {op.label}
                                </MenuItem>)}

                            </Menu>
                        </>}
                        <OutlinedInput
                            id="search-box" margin="normal"
                            placeholder="Search"
                            autoComplete="off"
                            inputRef={textFieldRef}
                            endAdornment={<InputAdornment position="end"><SendIcon onClick={() => handleSearch()} sx={{ fontSize: 30, color: '#5a5f62', cursor: "pointer" }} /></InputAdornment>}
                            variant="standard"
                            value={searchText}
                            maxRows={5}
                            multiline
                            sx={{ ...resultTextStyle, flex: isMobile ? 12 : 8, border: 'none', "& fieldset": { border: 'none' }, background: "#fff", width: isMobile ? "100%" : "80%", my: 0, mr: isMobile ? 0 : 1 }}
                            onChange={(e) => {
                                setSearchText(e.target.value)
                            }}
                            onKeyDown={handleSearchKeyPress}
                        />
                    </Box>
                </form>
                <Box>
                    {isSourceSelected && selectedValue.value === 'knowledge-graph' && dataset?.nodes?.length > 0 &&

                        <Box sx={{ boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2);', backgroundColor: "#fff", p: 1, m: 1, pl: 2, borderRadius: '8px' }}>
                            <Button
                                variant="text"
                                // size="small"
                                onClick={() => { setOpenDialog(true); reset({ node_color: "#808080", node_label: [{ label: 'Circle', value: 'circle' }] }); }}
                                sx={{
                                    color: "#fff",
                                    fontSize: "10px",
                                    backgroundColor: '#797EF6',
                                    maxHeight: '28px',
                                    mr: 1,
                                    alignSelf: 'center',
                                    textTransform: 'uppercase',
                                    ':hover': {
                                        background: '#676bd2'
                                    }
                                }}
                            >
                                + Create Node
                            </Button>
                            {/* <Button
                                variant="text"
                                // size="small"
                                onClick={() => { resetGrid() }}
                                sx={{
                                    ml: 1,
                                    color: "#fff",
                                    fontSize: "10px",
                                    backgroundColor: '#797EF6',
                                    maxHeight: '28px',
                                    alignSelf: 'center',
                                    textTransform: 'uppercase',
                                    ':hover': {
                                        background: '#676bd2'
                                    }
                                }}
                            >
                                {!isGridBg ? 'Grid background' : 'Plain background'}

                            </Button> */}

                            <FormControlLabel sx={{
                                mx: 0, ml: 1, '>span': {
                                    mr: 0.5,
                                    ml: -1.5
                                }
                            }} control={<Switch
                                checked={isGridBg}
                                onChange={resetGrid}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />} label="Grid" />
                            <IconButton
                                aria-label="close"
                                onClick={() => networkChartRef.current.refreshTree()}
                                sx={{
                                    // marginLeft: 'auto',
                                    color: (theme) => theme.palette.grey[900],
                                }}
                            >
                                <RefreshIcon />
                            </IconButton>

                        </Box>
                    }
                </Box>

            </Box>
            <Box sx={{ position: 'absolute', bottom: 0, left: 0, zIndex: 1, boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2);', backgroundColor: "#fff", p: 1, m: 2, borderRadius: '8px' }}>
                {isSourceSelected && selectedValue.value === 'knowledge-graph' && <Box sx={{ display: 'flex', width: '100%' }}>
                    <IconButton
                        aria-label="close"
                        onClick={() => networkChartRef.current.onZoom(-1)}
                        sx={{
                            color: (theme) => theme.palette.grey[900],
                        }}
                    >
                        <RemoveIcon />
                    </IconButton>
                    <IconButton
                        aria-label="close"
                        onClick={() => networkChartRef.current.onZoom(1)}
                        sx={{
                            color: (theme) => theme.palette.grey[900],
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                    {!openSearchBar && <IconButton
                        id={"search-in-graph"}
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={() => handleGraphSearch(true)}
                        sx={{ mr: 1, color: blueGrey[900] }}
                    >
                        <SearchIcon sx={{ fontSize: 30 }} />
                    </IconButton>}
                    {openSearchBar && <Box sx={{ display: 'flex', p: 1 }}>
                        <OutlinedInput
                            id="search-box-d3"
                            placeholder="Search"
                            autoComplete="off"
                            size="small"
                            inputRef={textChartFieldRef}
                            endAdornment={<InputAdornment position="end"><SearchIcon sx={{ fontSize: 30, color: '#797EF6' }} /></InputAdornment>}
                            variant="outlined"
                            value={searchChartText}

                            maxRows={5}
                            sx={{ ...resultTextStyle, flex: isMobile ? 12 : 8, background: "#fff", borderRadius: '8px', maxWidth: "320px", my: 0, mr: isMobile ? 0 : 1, ml: 1 }}
                            onChange={(e) => {
                                setChildSearchText(e.target.value);
                            }}
                            onKeyDown={handleSearchChartKeyPress}
                        />
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleGraphSearchClose}
                            sx={{ mr: 1, color: blueGrey[900] }}
                        >
                            <CloseIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                    </Box>}
                </Box>}
            </Box>
            {(isLoading) && <Loader></Loader>}
            {!isLoading && !queryResData && !props.isMeddra && <>
                <Box sx={{ px: 16, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 240px)', fontFamily: 'Segoe UI, Tahoma, sans-serif', pt: 5 }}>
                    {!props.hideDefaultSampleQuery && <Grid container sx={{ display: 'flex', rowGap: "30px", columnGap: '40px', justifyContent: 'center', alignItems: 'center', paddingLeft: '70px' }}>
                        <Grid container spacing={2} sx={{ maxWidth: '800px' }}>
                            {sampleQueries.map((query, index) => (
                                <Grid key={index} item xs={12} sm={6} >
                                    <Box onClick={() => searchCard(query)} sx={{ ...sampleCardClass }}>
                                        <Box sx={{ fontSize: '16px', wordWrap: 'break-word', minHeight: '40px', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {query}
                                        </Box>
                                        {/* <Box sx={{ opacity: 0.5 }}>{query} </Box> */}
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        {/* <Grid item xs="12" sm="4" md="4">
                            <Box onClick={() => searchCard('List all the medicines')} sx={{ ...sampleCardClass }}>
                                <Box sx={{ fontSize: '1.25rem', pb: 2 }}>List all the medicines</Box>
                                <Box sx={{ opacity: 0.5 }}>Query to listout all the medicines</Box>
                            </Box>
                        </Grid>
                        <Grid item xs="12" sm="4" md="4">
                            <Box onClick={() => searchCard('Find all the proteins')} sx={{ ...sampleCardClass, mr: 0 }}>
                                <Box sx={{ fontSize: '1.25rem', pb: 2 }}>Find all the proteins</Box>
                                <Box sx={{ opacity: 0.5 }}>Query to listout all the proteins</Box>
                            </Box>
                        </Grid> */}
                    </Grid>}
                    {/* <img onClick={focusTextField} className='image-container-left' src="/dbms.png" width="220" height="201" alt='img'></img>
                    <Typography onClick={focusTextField} sx={{ color: '#000', opacity: 0.5, fontSize: '20px' }}>Try Search on top</Typography> */}
                </Box>
            </>}
            {!isLoading && queryResData &&
                <>
                    <Card sx={{
                        mt: isSourceSelected ? -1 : 0,
                        ml: isSourceSelected ? -2 : 'auto',
                        mr: 'auto',
                        transform: isSourceSelected ? 'translateX(0px)' : 'translateX(30px)',

                        // background: lightBlue['100'],
                        boxShadow: 'unset',
                        position: isSourceSelected ? 'absolute' : '',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%', maxWidth: isSourceSelected ? '100vw' : '800px'

                        // width: '100%'
                        // border: selectedValue.value === 'knowledge-graph' && isSourceSelected ? `1px solid ${lightBlue[100]}` : '',
                    }}>
                        {!isSourceSelected && <CardActions sx={{ py: 2, px: 0, boxShadow: selectedValue.value === 'knowledge-graph' && isSourceSelected ? '3px 1px 3px 1px rgba(0,0,0,0.14)' : '', display: 'flex' }}>
                            <Button size="large" onClick={() => handleButtonClick(false)} sx={{ ...tabBtnStyle(false), mr: 1 }}>Response</Button>
                            <Button size="large" onClick={() => handleButtonClick(true)} sx={{ ...tabBtnStyle(true), mr: 1 }}>Source</Button>
                            {/* <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => { setQueryResData(null); setSearchText('') }} sx={{ ...tabBtnStyle(true), color: blueGrey['600'], fontSize: '12px', minWidth: 0, ml: 3 }}
                            >
                                <HomeIcon sx={{}} />
                            </IconButton> */}
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => { navigate(-1); }} sx={{ ...tabBtnStyle(true), color: blueGrey['600'], fontSize: '14px', minWidth: 0, ml: 'auto !important', mr: 1, color: '#797EF6' }}
                            >Go Back</IconButton>
                        </CardActions>
                        }
                        <Box sx={{ m: 0, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
                        {!isSourceSelected && <CardContent sx={{ p: 2, backgroundColor: '#f0f4f9', mt: 2, borderRadius: '8px' }}>
                            <Typography gutterBottom variant="h5" component="div" sx={{ ...resultTextStyle, fontSize: '1.25rem' }}>
                                Results:
                            </Typography>
                            {/* <Typography variant="h6" sx={{ ...resultTextStyle, fontWeight: 400, color: blue['A400'], }}>
                                Search Result ({queryResData.query_id})
                            </Typography> */}
                            {queryResData.plots_list && queryResData.plots_list.map(img => {
                                return <><img src={`${process.env.REACT_APP_BASE_URL}/${img}`} /></>
                            })}
                            <Typography variant="h6" sx={{ ...resultTextStyle, color: 'rgba(0, 0, 0, 0.87)' }}>
                                {formattedText(queryResData.query_response)}
                            </Typography>
                        </CardContent>}
                        {isSourceSelected && <Container className='abcd' maxWidth disableGutters sx={{ m: 0, display: 'flex', flexDirection: 'column', p: 0, backgroundColor: selectedValue.value === 'knowledge-graph' ? '#fff' : '' }}>
                            {selectedValue.value !== 'knowledge-graph' && <><Typography gutterBottom variant="h5" component="div" sx={{ ...resultTextStyle, fontSize: '1.25rem' }}>
                                References:
                            </Typography>
                                <Typography variant="h6" sx={{ ...resultTextStyle, fontWeight: 400, color: blue['A400'] }}>
                                    Data sources ({queryResData.query_id})
                                </Typography>
                                <Typography variant="h6" sx={{ ...resultTextStyle }} >
                                    <div dangerouslySetInnerHTML={{ __html: queryResData.source_data }}></div>
                                </Typography>
                            </>}
                            {selectedValue.value === 'knowledge-graph' &&
                                // <TidyTree ref={networkChartRef} dataset={dataset} actualData={actualData} setParentData={data => setDataset(data)}></TidyTree>
                                // <NetworkGraph ref={networkChartRef} dataset={dataset} setParentData={data => setDataset(data)}></NetworkGraph>
                                <>
                                    {<ReteGraph ref={networkChartRef} dataset={dataset} versions={versions} isGridBg={isGridBg} refreshVersions={() => getReviewList()}></ReteGraph>}</>
                            }
                        </Container>}

                    </Card>
                </>
            }

            <AppDialog
                openDialog={openDialog}
                onOpenDialog={closeDialog}
                dialogTitle={'Create Node'}
                maxWidth={'sm'}
                fullWidth={false}
                attachedComponent={getActionFormComponent()}
                hideAction={true}
                actions={getActions()}
            ></AppDialog>

        </Box >

    );
}