import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DescriptionIcon from '@mui/icons-material/Description';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import DatasetIcon from '@mui/icons-material/Dataset';
import KeyIcon from '@mui/icons-material/Key';
import UploadIcon from '@mui/icons-material/Upload';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import axios from "../utility/api/api";
import { blue, blueGrey, lightBlue } from '@mui/material/colors';
import { useNavigate, redirect } from "react-router-dom";
export default function LeftMenuList(props) {
    const navigate = useNavigate();
    const { id} = props
    const [anchorDrawerEl, setDrawerAnchorEl] = useState(null);
    const [reviewList, setReviewList] = useState([])
    const openDrawer = Boolean(anchorDrawerEl);

    useEffect(()=>{
        getReviewList()
    }, [])

    const handleDrawerClick = (event) => {
        setDrawerAnchorEl(event.currentTarget);
    };
    const handleDrawerClose = () => {
        setDrawerAnchorEl(null);
    };

    const menuObject = [{ name: 'Search', path: '/layout/search', icon: ContentPasteSearchIcon },
    { name: 'Database', path: '/layout/databases', icon: DatasetIcon },
    { name: 'Credentials', path: '/layout/credentials', icon: KeyIcon },
    { name: 'Data Analytics', path: '/layout/data-analytics', icon: AnalyticsIcon },
    { name: 'Uploads', path: '/layout/uploads', icon: UploadIcon },
    { name: 'Review', path: '/layout/review', icon: CallMergeIcon },
    { name: 'Meddra', path: '/layout/meddra', icon: DescriptionIcon }

    ];

    function getReviewList() {
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/get-doc-version-list`,
            data: body
        })
            .then((res) => {
                const data = res.data.version_list;
                setReviewList(data)
            }).catch(err => {
            })
    }

    const selectMenu = (path) => {
        if (path === '/layout/search' && window.location.hash === '#/layout/search') {
            if (props.refresh) {
                props.refresh();
            }
            return;
        }
        navigate(path);
    }
    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerClick}
                sx={{ mr: 0, color: blueGrey[900] }}
            >
                <MenuIcon sx={{ fontSize: 30 }} />
            </IconButton>
            <Menu
                id={"basic-menu" + id}
                key={id}
                anchorEl={anchorDrawerEl}
                open={openDrawer}
                onClose={handleDrawerClose}
                MenuListProps={{
                    'aria-labelledby': 'db-dropdown-button',
                }}
            >
                <List>
                    {menuObject.map((obj, index) => (
                        obj.path !== '/layout/review'
                        ?
                        <ListItem key={obj.name} disablePadding>
                            <ListItemButton onClick={() => selectMenu(obj.path)}>
                                <ListItemIcon>
                                    {<obj.icon />}
                                </ListItemIcon>
                                <ListItemText primary={obj.name} />
                            </ListItemButton>
                        </ListItem>
                        :
                        <ListItem key={obj.name} disablePadding>
                        <ListItemButton onClick={() => navigate(`/layout/review/${reviewList[0].version_id}`)}>
                            <ListItemIcon>
                                {<obj.icon />}
                            </ListItemIcon>
                            <ListItemText primary={obj.name} />
                        </ListItemButton>
                    </ListItem>
                        
                    ))}
                </List>
            </Menu>
        </>

    )
}
