// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRef, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import axios from "../../utility/api/api";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import Card from '@mui/material/Card';
import Loader from '../../Loader';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import ReteGraph from './rete/rete-graph';
import { useForm } from "react-hook-form";
import generateRandomTreeData from '../../constants/LoadChecker';//./constants/LoadChecker
import { showLoader, formatTimestamp, hideLoader, prepareReteTreeV3, formateVersion } from '../../utility/helper';
import LeftMenuList from '../../common/LeftMenuList';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AppDialog from '../../common/AppDialog';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import { blueGrey } from '@mui/material/colors';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import uiAction from '../../store/actions/uiAction';
import Tooltip from '@mui/material/Tooltip';
import PDFViewer from '../../common/react-pdf/PDFViewer';
import CommitDetails from './commit-history/CommitDetails';
import HistoryIcon from '@mui/icons-material/History';
import HtmlTooltip from '../../common/htm-customized-tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ResponsiveSearchContainer from '../../ResponsiveSearchContainer';
import { useParams, useLocation } from 'react-router-dom';
import meddrajsondata from './meddrajson.json'
import { Select, MenuItem, Divider } from '@mui/material';
import { ExpandMore as ChevronDownIcon, ExpandLess as ChevronUpIcon } from '@mui/icons-material';

export default function MeddraCompareNode(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [projectId, setProjectId] = useState("");
    const [showNodeView, setShowNodeView] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [docName, setDocName] = useState("");
    const [viewerWindow, setViewerWindow] = useState(1);
    const [docDetail, setDocDetail] = useState("");
    const [loading, setLoading] = useState(false);
    const [dataset, setDataset] = useState(null);
    const [empty, setEmpty] = useState(false);
    const [docType, setDocType] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [zoomVal, setZoomVal] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [isReviewLoading, setIsReviewLoading] = useState(true);
    const [selectedReview, setSelectedReview] = useState(null);
    const [openSearchBar, setOpenSearchBar] = useState(false);
    const [searchChartText, setSearchChartText] = useState('');
    const [show, setShow] = useState(true)
    const [selectedButtons, setSelectedButtons] = useState([]);
    const [nodeWidth, setNodeWidth] = useState(100);
    const [nodeHeight, setNodeHeight] = useState(50);
    const [currentZoom, setCurrentZoom] = useState(1);
    const [focusedNode, setFocusedNode] = useState(null);

    const location = useLocation();
    let queryParams = new URLSearchParams(window.location.hash.split('?')[1]);
    let nodes = queryParams.get('nodes');
    if (nodes) {
        nodes = JSON.parse(decodeURIComponent(nodes));
    }

    const reviewRef = useRef();
    const textChartFieldRef = useRef(null);
    const [selectedNode, setSelectedNode] = useState(nodes[0]?.id || '');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
    const handleSelectChange = (event) => {
      setSelectedNode(event.target.value);
      setIsDropdownOpen(false);
    };  

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };
  
  
    function prepareLoadData(n) {
        const preparedDataSet = generateRandomTreeData(n);
        setLoading(true);
        showLoader();
        setTimeout(() => {
            setDataset(preparedDataSet);
            setLoading(false);
        }, 1000)
    }

    function initPage(id) {
        prepareLoadData(10);
    }

    useEffect(() => {
        const storage = localStorage.getItem('sielo_search_app');
        if (storage) {
            const storageJson = JSON.parse(storage);
            if (!storageJson.isLoggedIn) {
                navigate("/sign-in");
            }
        } else {
            navigate("/sign-in");
        }
        getVersionDetail(meddrajsondata, 0)
    }, []);

    const setChildSearchText = (text) => {
        setSearchChartText(text);
        reviewRef.current.setSearchText(text);
    }

    const handleSearchChartKeyPress = (event) => {
        if (event.key === 'Enter') {
            reviewRef.current.handleSearch(searchChartText);
        }
    };

    function openConfirmationDialog() {
        setOpenDialog(true);
    }
    
    function openUploadDoc() {
        navigate("/layout/uploads", { state: { tab: 1, project_id: projectId, project_name: projectName } });
    }

    function onViewCommitHistory() {
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token };
        setViewerWindow(() => 3);
    }

    function closeConfirmationDialog() {
        setOpenDialog(false);
    }

    function onDeleteDocument() {
        showLoader();
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/delete-doc`,
            data: body
        })
        .then((res) => {
            dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'Document deleted Successfuly!!!' }));
            setTimeout(() => {
                navigate("/layout/uploads");
            }, 1000);
        }).catch(err => {
            hideLoader();
        })
    }

    function pageChange(pageNo) {
        if (pageNo <= 0) {
            pageNo = numPages;
        }
        if (!(pageNo % (numPages + 1))) {
            pageNo = 1;
        }
        setPageNumber(pageNo % (numPages + 1));
    }

    function onSetNumPages(num) {
        console.log(num);
        setNumPages(num);
    }

    function onVisChange() {
        if (viewerWindow === 1) {
            if (!showNodeView) {
                setTimeout(() => {
                    if (reviewRef?.current) reviewRef.current.refresh();
                }, 100);
            }
            setShowNodeView(() => !showNodeView);
        } else {
            setViewerWindow(() => 1);
            setShowNodeView(() => !showNodeView);
        }
    }

    const updateShow = () => {
        setShow(!show)
    }

    const handleButtonClick = async (node, index, res) => {
        setSelectedNode(node.id);
        if (selectedButtons.includes(index)) {
            setSelectedButtons(selectedButtons.filter(item => item !== index));
        } else {
            setSelectedButtons([...selectedButtons, index]);
        }
        getVersionDetail(res, index)
    };

 function getVersionDetail(res, index) {
        showLoader();
        if (res?.doc_data?.project_id) {
            setProjectId(res.doc_data.project_id);
        }
        if (res?.doc_data?.project_name) {
            setProjectName(res.doc_data.project_name);
        }
        if (res?.doc_data?.doc_detail) {
            setDocDetail(res.doc_data.doc_detail);
        }
        if (res?.doc_data?.doc_name) {
            setDocName(res.doc_data.doc_name);
        }
        const doc_format = res.doc_data.doc_format;
        setDocType(doc_format);
        
        const data = res.doc_data?.data[index];
        if (!res.doc_data.data[index]) {
            hideLoader();
            setEmpty(true);
            return;
        }
        setEmpty(false);
        hideLoader();
        setTimeout(() => {
            setDataset(prepareReteTreeV3(data));
            if (focusedNode) {
                reviewRef.current.focusNode(focusedNode);
            } else {
                reviewRef.current.focusFirstNode();
            }
        }, 100)
    }
    return (
        <Box sx={{ borderRadius: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '0', width: '100%', }} >
            <CssBaseline />

            <Box sx={{ display: 'flex', zIndex: 1, position: 'absolute', }} className="pqr">
                <Card sx={{
                    // maxWidth: '100%',
                    mt: 1,
                    pl: 1,
                    pr: 2
                    // boxShadow: 'unset',
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LeftMenuList></LeftMenuList>
                        {/* <IconButton
                            aria-label="close"
                            onClick={() => navigate("/layout/uploads")}
                            sx={{
                                color: (theme) => theme.palette.grey[900],
                                mx: 1
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton> */}
                        <Box component="h3" sx={{ m: 0, mt: 1.5, mb: 1, color: '#5a5f62' }}>MedDra
                            <span style={{ borderRight: '2px solid #ccc', paddingLeft: '15px' }}></span>
                            <IconButton
                                aria-label="close"
                                onClick={() => navigate(-1)}
                                sx={{
                                    color: '#9b9598',
                                    ml: 2,
                                    backgroundColor: '#f1f1f1',
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton></Box>

                    </Box>

                    {/* {!!docName && <Box sx={{ ml: 6, pb: 1, pt: 0.5, mt: 0.5, borderTop: '1px solid #ccc', display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ fontSize: '16px', fontWeight: 300, color: '#858585' }} >{'Document Name: '}</Box>
                        <Box sx={{ fontSize: '16px', fontWeight: 500, color: '#797EF6', pl: 1 }} >{docName}</Box>
                    </Box>}
                    {!!docDetail && <Box sx={{ ml: 6, pb: 1, pt: 0.5, mt: 0.5, borderTop: '1px solid #ccc', display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ fontSize: '16px', fontWeight: 300, color: '#858585' }} >{'Document Detail: '}</Box>
                        <Box sx={{ fontSize: '16px', fontWeight: 500, color: '#797EF6', pl: 1 }} >{docDetail}</Box>
                    </Box>} */}
                </Card>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', mt: 3, zIndex: 1 }}>
      <Card sx={{ p: 2, minWidth: 200, maxWidth: 250, position: 'absolute', top: 0, right: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
          <Typography variant="h6">
            <Box component="span" sx={{ fontWeight: 'bold', color:'#797EF6' }}>
              {`Version ${nodes.findIndex(node => node.id === selectedNode) + 1}`}
            </Box>
          </Typography>
          <IconButton size="small" onClick={handleDropdownToggle} sx={{ fontWeight: 'bold', color:'#797EF6' }}>
            {isDropdownOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </IconButton>
        </Box>
        <Select
        onChange={handleSelectChange}
        value={''}
          displayEmpty
          sx={{ 
            display: isDropdownOpen ? 'block' : 'none', 
            border: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '.MuiSelect-select': { padding: 0 }  // Remove padding
          }}
          open={isDropdownOpen}
          onClose={() => setIsDropdownOpen(false)}
          onOpen={() => setIsDropdownOpen(true)}
          IconComponent={() => null} // Remove default arrow icon
          MenuProps={{
            PaperProps: {
              sx: {
                width: '200px' // Ensure dropdown has equal width
              }
            }
          }}
        >
          {nodes.map((node, index) => (
            <MenuItem key={index} value={node.id}           
            onClick={()=>handleButtonClick(node, index, meddrajsondata)}
            sx={{
              fontWeight: selectedNode === node.id ? 'bold' : 'normal',
              color: selectedNode === node.id ? '#797EF6' : 'inherit',
              '&.Mui-selected': {
                backgroundColor: 'transparent', // Remove default background color on selected item
                '&:hover': {
                  backgroundColor: 'rgba(121, 126, 246, 0.1)', // Add hover effect for selected item
                },
              },
            }}>
              {`Version ${index + 1}`}
            </MenuItem>
          
))}
        </Select>
      </Card>
    </Box>
                        
            <Card sx={{
                mt: -1,
                ml: -2,
                mr: 'auto',
                transform: 'translateX(0px)',
                boxShadow: 'unset',
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                width: '100%', maxWidth: '100vw'
            }}>
                <Box sx={{ m: 0, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important', }}></Box>
                {loading && <Box>Loading</Box>}
                {!loading && <Container className='abcd' maxWidth disableGutters sx={{ m: 0, display: 'flex', flexDirection: 'column', p: 0, backgroundColor: '#fff' }}>
                    {<ReteGraph ref={reviewRef} dataset={dataset} nodeWidth={nodeWidth} nodeHeight={nodeHeight}></ReteGraph>}
                </Container>}
                <Box sx={{ position: 'absolute', bottom: 0, left: 0, zIndex: 1, boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2)', backgroundColor: "#fff", p: 1, m: 2, borderRadius: '8px' }}>
                {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                Node Height
                                <IconButton
                                    aria-label="decrease height"
                                    onClick={() => handleNodeHeightMinusChange()}
                                    sx={{ color: (theme) => theme.palette.grey[900] }}
                                >
                                    <RemoveIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="increase height"
                                    onClick={() => handleNodeHeightPlusChange()}
                                    sx={{ color: (theme) => theme.palette.grey[900] }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                Node Width
                                <IconButton
                                    aria-label="decrease width"
                                    onClick={() => handleNodeWidthMinusChange()}
                                    sx={{ color: (theme) => theme.palette.grey[900] }}
                                >
                                    <RemoveIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="increase width"
                                    onClick={() => handleNodeWidthPlusChange()}
                                    sx={{ color: (theme) => theme.palette.grey[900] }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Box>
                        </Box> */}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                aria-label="zoom out"
                                onClick={() => reviewRef.current.onZoom(-1)}
                                sx={{ color: (theme) => theme.palette.grey[900] }}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <IconButton
                                aria-label="zoom in"
                                onClick={() =>  reviewRef.current.onZoom(currentZoom)}
                                sx={{ color: (theme) => theme.palette.grey[900] }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Card>
            <AppDialog
                openDialog={openDialog}
                onOpenDialog={closeConfirmationDialog}
                dialogTitle={'Delete Document'}
                maxWidth="md"
                attachedComponent={<Box sx={{ p: 2 }}>Are You Sure you want to delete the document?</Box>}
                actions={<Box sx={{ px: 2, display: 'flex' }}>
                    <Button
                        type="submit"
                        onClick={onDeleteDocument}
                        variant="contained"
                        sx={{ mt: 1, mb: 1, ml: 'auto', backgroundColor: '#797EF6' }}
                    >
                        Yes
                    </Button>
                    <Button
                        type="submit"
                        onClick={closeConfirmationDialog}
                        variant="contained"
                        sx={{ mt: 1, mb: 1, ml: 1, backgroundColor: '#797EF6' }}
                    >
                        No
                    </Button>
                </Box>}
            ></AppDialog>
        </Box >



    );
}