import * as React from "react";
import styled from "styled-components";
import { $socketsize } from "./vars";

const Styles = styled.div`
  display: inline-block;
  cursor: pointer;
  border:${(props) =>
    (props?.data?.data?.data?.color) ? `1px solid  ${props?.data?.data?.data?.color}` : `1px solid grey`};
  border-radius:4px;
  width: ${$socketsize}px;
  height: ${$socketsize * 2}px;
  vertical-align: middle;
  padding:6px;
  background: #fff;
  z-index: 2;
  box-sizing: border-box;
  &:hover {
    background: #ddd;
  }
`;

export function CustomSocket(props) {
  return <Styles title={props.data.name} data={props.data} />;
}
