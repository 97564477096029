import { useState, useEffect } from "react";
import { Presets } from "rete-react-plugin";
import styled, { css } from "styled-components";
import { $nodewidth, $socketmargin, $socketsize } from "./vars";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import TextField from '@mui/material/TextField';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import Tooltip from "@mui/material/Tooltip";
// import AppDialog from '../../common/AppDialog';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { actionsProps } from './vars';
import useMediaQuery from '@mui/material/useMediaQuery';
import { numberOfConnectedNodes, strokeMap, nodeLabelMap } from '../../../utility/helper';
import ColorPickerComponent from "../../../common/ColorPickerComponent";
import FormAutocomplete from "../../../common/FormAutocomplete";
import axios from "../../../utility/api/api";
import NodeLabelLink from "../../../custom-hooks/node-label-link";
const { RefSocket } = Presets.classic;


function sortByIndex(
  entries
) {
  entries.sort((a, b) => {
    const ai = a[1]?.index || 0;
    const bi = b[1]?.index || 0;

    return ai - bi;
  });
}

export function CustomNode(props) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    reset,
    setValue
  } = useForm({
    mode: 'onChange', // Enable live validation
  });

  const { fields: fieldsLeft, append: append1, remove: remove1 } = useFieldArray({
    control,
    name: "leftLinks" // Name of the first array field
  });

  const { fields: fieldsRight, append: append2, remove: remove2 } = useFieldArray({
    control,
    name: "rightLinks" // Name of the second array field
  });

  const updatedFieldsLeft = watch("leftLinks");
  const updatedFieldsRight = watch("rightLinks");

  const updateField = (index, fieldName, value, type) => {
    const fields = type === 'leftLinks' ? updatedFieldsLeft : updatedFieldsRight;
    fields[index][fieldName] = value;
    setRerender(prev => !prev);
    // const updatedFields = [...fields]; // Create a shallow copy of the fields array
    // updatedFields[index][fieldName] = value; // Update the specified field in the object at the given index
    // setValue(`${type}[${index}].${fieldName}`, value); // Update the form value for the field
  };


  const getLabelIcon = (label) => {
    if (typeof label === 'string') {
      return <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
        {label === 'triangle' && <polygon points="9,7 16,16 2,16" style={{ fill: '#fff' }} />}
        {label === 'square' && <rect width="12" height="12" x="4" y="8" style={{ fill: '#fff' }} />}
        {label === 'circle' && <circle cx="8" cy="12" r="6" style={{ fill: '#fff' }} />}
      </svg>
    } else {
      return <>
        {label.includes('circle') && <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="12" r="6" style={{ fill: '#fff' }} />
        </svg>}
        {label.includes('square') && <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <rect width="12" height="12" x="4" y="8" style={{ fill: '#fff' }} />
        </svg>}
        {label.includes('triangle') && <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <polygon points="9,7 16,16 2,16" style={{ fill: '#fff' }} />
        </svg>}
      </>

    }

  }


  const validationRules = {
    node_name: {
      required: 'Node name is required',
    },
    link_name: {
      required: 'Link name is required',
    },
  };

  const [openDialog, setOpenDialog] = useState(false);
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const controls = Object.entries(props.data.controls);
  const [selectedNode, setSelectedNode] = useState(null);
  const [versions, setVersions] = useState([]);
  const [showVersionTextField, setShowVersionTextField] = useState(false);
  const [isReviewLoading, setIsReviewLoading] = useState(false);
  const [rerender, setRerender] = useState(false);
  const { id, label, width, height, childCount, isCollapsed, showBadge } = props.data;
  const [key, setKey] = useState(0);
  const handleNext = () => {
    setTimeout(() => {
      setKey(prevKey => prevKey + 1);
    }, 100)
  };
  useEffect(() => {
    setVersions(props.data.editor.versions);
  }, [props.data.editor.versions]);


  const [action, setActionType] = useState(controls[0][1].type);//'collapse' : 'expand'
  const isMobile = useMediaQuery('(max-width:600px)');
  const placeHolderCss = {
    "& input::placeholder": {
      fontSize: "0.8rem"
    },
    fontSize: '0.9rem'
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  sortByIndex(inputs);
  sortByIndex(outputs);
  sortByIndex(controls);

  const copyName = () => {
    setSelectedNode(props.data);
    navigator.clipboard.writeText(props.data.label);
    handleClose();
  }

  function closeMenu() {
    reset();
    closeDialog(false);
  }

  // const strokeMap = { "solid": 'Solid', "dash": 'Dash' };
  // const nodeLabelMap = { "circle": 'Circle', "square": 'Square', "triangle": 'Triangle' };

  const onSubmit = (form) => {
    // if (action === 'add') {
    //   controls[0][1].onClick(form);
    // } else if (action === 'delete') {
    //   controls[2][1].onClick(form);
    // } else if (action === 'edit') {
    //   controls[1][1].onClick(form);
    // } else
    if (action === 'collapse' || action === 'expand') {
      controls[0][1].onClick(action);
    }
    closeMenu();
  };


  function closeDialog(status) {
    setOpenDialog(status);
  }

  function onView(type) {
    if (type === 'edit') {
      const rightLinks = props.data.editor.getConnections().filter(c => c.source === props.data.id);
      const leftLinks = props.data.editor.getConnections().filter(c => c.target === props.data.id);
      reset({
        node_name: props.data.label,
        node_color: props.data.data.color,
        version_name: null,
        version_id: null,
        version: null,
        node_label: Array.isArray(props.data.data.category) ? props.data.data.category.map(cat => ({ value: cat, label: nodeLabelMap[cat] })) : [{ value: props.data.data.category || "circle", label: nodeLabelMap[props.data.data.category || "circle"] }],
        node_id: props.data.id,
        leftLinks: leftLinks.map(l => ({ id: l.id, link_name: l.label, is_deleted: false, color: l.color, type: l.type })),
        rightLinks: rightLinks.map(l => ({ id: l.id, link_name: l.label, is_deleted: false, color: l.color, type: l.type }))
      });
    }
    setActionType(type);
    setSelectedNode(props.data);
    if (type === 'delete') {
      reset({
        node_name: props.data.label,
        node_id: props.data.id,
        version_name: null,
        version_id: null,
        version: null,
      });
    }
    if (type === 'add') {
      reset({
        node_color: props.data.color || "#808080",
        version: null,
        version_name: null,
        version_id: null,
        node_label: [{ value: "circle", label: nodeLabelMap["circle"] }],
        link_color: "#808080",
        link_stroke: { value: "solid", label: strokeMap["solid"] }
      });
    }
    if (['expand', 'collapse'].includes(type)) {
      handleClose();
      controls[1][1].onClick(action);
    } else if (['expand-parent', 'collapse-parent'].includes(type)) {
      handleClose();
      controls[0][1].onClick(action);
    } else {
      handleClose();
      setOpenDialog(!openDialog);
    }
    setTimeout(() => {
      handleNext();
    }, 200)

  }

  
  return (
    <>
      <Box key={'cn-' + id + key} id={'cn-' + id} className="container-branch" sx={{ backgroundColor: props?.data?.data?.color || '#f0f0f0', color: '#fff' }}>
        {inputs.map(
          ([key, input]) =>
            input && (
              <div className="rounded-circle left" key={key} data-testid={`input-${key}`}>
                <RefSocket
                  name="input-socket"
                  emit={props.emit}
                  side="input"
                  socketKey={key}
                  nodeId={id}
                  payload={{ socket: input.socket, data: props.data }}
                />
              </div>
            )
        )}
        <Badge key={'badge-' + id + key} badgeContent={childCount} color="primary">
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
            {/* <Box className="label" sx={{ pl: 2, color: '#fff', width: '150px' }} title={label}>{label}</Box> */}
            {/* <Box sx={{ position: 'absolute', left: "12px", top: "-8px" }}>
              {getLabelIcon(props.data.data.category)}
            </Box> */}
            <Box sx={{ marginLeft: 'auto', p: 0 }}>
              {/* {props && <NodeLabelLink props={props}></NodeLabelLink>} */}
              {/* <IconButton
                id={"basic-button" + id}
                key={'icon-button-' + id + key}
                aria-controls={open ? 'basic-menu' + id : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onPointerDown={(e) => e.stopPropagation()}
                onDoubleClick={(e) => e.stopPropagation()}
                onClick={handleClick}
              >
                <MoreVertIcon sx={{ color: '#fff' }} />
              </IconButton> */}
            </Box>
          </Box>
        </Badge>

        {outputs.map(
          ([key, output]) =>
            output && (
              <div className="rounded-circle right" key={key} data-testid={`output-${key}`}>
                <RefSocket
                  name="output-socket"
                  side="output"
                  emit={props.emit}
                  socketKey={key}
                  nodeId={id}
                  payload={{ socket: output.socket, data: props.data }}
                />
              </div>
            )
        )}
      </Box>
      <Menu
        id={"basic-menu" + id}
        key={'menu-' + id + key}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button' + id,
        }}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
        {/* <MenuItem> */}
        <Box sx={{ display: 'flex', p: 0, m: 0 }}>
          {controls?.length && <Tooltip title={actionsProps[controls[0][1].type].title}> <IconButton
            onPointerDown={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()}
            onClick={() => {
              onView(controls[0][1].type);
            }}
            color={actionsProps[controls[0][1].type].color}
          >
            {actionsProps[controls[0][1].type].icon}
          </IconButton>
          </Tooltip>}
          {selectedNode?.label && selectedNode?.label === window?.clipboardData?.getData('Text') ? <IconButton
          ><CheckIcon />
          </IconButton>
            : <IconButton
              sx={{ color: '#797EF6' }}
              onClick={() => copyName()}
            ><ContentCopyIcon /></IconButton>}

          {controls?.length &&
            <Tooltip title={actionsProps[controls[1][1].type].title}>
              <IconButton
                onPointerDown={(e) => e.stopPropagation()}
                onDoubleClick={(e) => e.stopPropagation()}
                onClick={() => {
                  onView(controls[1][1].type);
                }}
                color={actionsProps[controls[1][1].type].color}
              >
                {actionsProps[controls[1][1].type].icon}
              </IconButton>
            </Tooltip>
          }
        </Box>
      </Menu>
      {/* <AppDialog
        openDialog={openDialog}
        onOpenDialog={closeDialog}
        dialogTitle={actionsProps[action]?.title + ' Node'}
        maxWidth={'sm'}
        fullWidth={false}
        attachedComponent={getActionFormComponent()}
        hideAction={true}
        actions={getActions()}
      ></AppDialog> */}
    </>
  );
}
