// import { Document, Page, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';


export default function PDFViewer(props) {
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  // const zoomPluginInstance = zoomPlugin();
  function onDocumentLoad(doc) {
    const ele = document.querySelector('#pdf-viewer-container > .rpv-core__viewer');
    if (ele) ele.style.display = 'unset';
    console.log(`Document loaded with ${doc?._pdfInfo?.numPages} page(s).`);
    props.onSetNumPages(doc?._pdfInfo?.numPages);
  }

  const handleDocumentError = (error) => {
    console.error('Error occurred while loading the document:', error);
    const ele = document.querySelector('#pdf-viewer-container > .rpv-core__viewer');
    if (ele) ele.style.display = 'flex';
    // You can handle the error here, e.g., display an error message to the user
  };

  return <>
    {/* <Document file={props.url} onLoadSuccess={onDocumentLoadSuccess} style={{ height: '300px', maxHeight: '300px', overflow: 'auto' }}>
      <Page pageNumber={1} scale={props.zoomVal} />
    </Document> */}

    <Worker style={{ width: '100%' }} workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
      <div
        id="pdf-viewer-container"
        style={{
          width: '100%',
          minHeight: '500px'
          // marginLeft: "auto",
          // marginRight: "auto"
        }}
      >
        <Viewer
          // fileUrl='/sample-pdf-file-2.pdf'
          fileUrl={props.url}
          plugins={[]}
          onError={handleDocumentError}
          onDocumentLoad={onDocumentLoad}
        />
      </div>
    </Worker>
    {/* <Viewer
      fileUrl='/sample-pdf-file-2.pdf'
      plugins={[defaultLayoutPluginInstance]}
    /> */}
  </>;
}