import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
export const $nodewidth = 200;
export const $socketmargin = 1;
export const $socketsize = 8;

export const actionsProps = {
    'add': { icon: <AddCircleIcon sx={{ color: '#797EF6' }} />, color: 'primary', title: 'Add' },
    'edit': { icon: <ModeEditIcon sx={{ color: '#797EF6' }} />, color: 'warning', title: 'Edit' },
    'delete': { icon: <RemoveCircleIcon sx={{ color: '#797EF6' }} />, color: 'error', title: 'Delete' },
    'collapse-parent': { icon: <FastForwardIcon sx={{ color: '#797EF6' }} />, color: 'warning', title: 'Collapse parents' },
    'expand-parent': { icon: <FastRewindIcon sx={{ color: '#797EF6' }} />, color: 'error', title: 'Expand parents' },
    'collapse': { icon: <FastRewindIcon sx={{ color: '#797EF6' }} />, color: 'primary', title: 'Collapse Children' },
    'expand': { icon: <FastForwardIcon sx={{ color: '#797EF6' }} />, color: 'primary', title: 'Expand Children' }
}