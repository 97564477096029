import { MuiColorInput } from 'mui-color-input';
import { useEffect, useState } from 'react';
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axios from "../utility/api/api";
import uiAction from '../store/actions/uiAction';
// import { useDispatch } from 'react-redux';
const colors = ["#FF0000",
  "#808080",
  "#FFFF00",
  "#00FF00",
  "#008000",
  "#00FFFF",
  "#000080",
  "#FF00FF"];
const ColorPickerComponent = (props) => {
  const [colorList, setColorList] = useState(colors);
  // const dispatch = useDispatch();
  const handleChange = (event, color) => {
    props.onColorChange(color);
  };
  const fetchApiData = async () => {
    try {
      const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
      const body = { "auth_token": jsonStorage.auth_token };
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/get-node-color`,
        data: body
      })
      if (!response.data.status) {
        // dispatch(uiAction.showSnackbar({ type: 'error', message: response.data.message }));
        return;
      }
      if (response?.data?.data?.length) {
        setColorList(response.data.data);
      }
    } catch (error) {
      console.log(error);
      // dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
    }

  }

  useEffect(() => {
    fetchApiData();
  }, [])

  return (
    <>
      {/* <MuiColorInput format="hex8" value={props.value}
        onChange={handleChange} /> */}
      <Autocomplete
        id={"choose-color" + props.id}
        sx={{ width: 200 }}
        options={colorList}
        disableClearable
        autoHighlight
        value={props.value}
        // inputValue={props.color}
        onChange={handleChange}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <Box sx={{ backgroundColor: option, width: 24, height: 24 }}></Box>
            {option}
          </Box>
        )}
        renderInput={(params) => (
          <>
            <TextField
              disabled
              {...params}
              variant="standard"
              label="Choose a color"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          </>
        )}
      />
    </>
  );
};

export default ColorPickerComponent;
