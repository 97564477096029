import { MuiColorInput } from 'mui-color-input';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "../../utility/api/api";
import uiAction from '../../store/actions/uiAction';
import { useDispatch } from 'react-redux';
export default function ColorConfig(props) {
    const [color, setColor] = useState("");
    const dispatch = useDispatch();
    const [colorList, setColorList] = useState([]);
    const [selectedColorIndex, setSelectedColorIndex] = useState(null);
    const [editCurrentColor, setEditCurrentColor] = useState("");
    const [isColorListLoading, setIsColorListLoading] = useState(true);
    const [showColorPalette, setShowColorPalette] = useState(false);

    const updateColorList = async () => {
        try {
            const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
            const body = { "auth_token": jsonStorage.auth_token, node_color: colorList };
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/update-node-color`,
                data: body
            })
            if (!response.data.status) {
                dispatch(uiAction.showSnackbar({ type: 'error', message: response.data.message }));
                return;
            }
            dispatch(uiAction.showSnackbar({ type: 'success', message: response?.data?.message || 'Colorlist updated Successfuly!!!' }));
        } catch (error) {
            console.log(error);
            dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
        }

    }
    const fetchApiData = async () => {
        try {
            setIsColorListLoading(true);
            const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
            const body = { "auth_token": jsonStorage.auth_token };
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/get-node-color`,
                data: body
            })
            setIsColorListLoading(false);
            if (!response.data.status) {
                dispatch(uiAction.showSnackbar({ type: 'error', message: response.data.message }));
                return;
            }
            if (response?.data?.data?.length) {
                setColorList(response.data.data);
            }
        } catch (error) {
            setIsColorListLoading(false);
            console.log(error);
            dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
        }

    }

    useEffect(() => {
        fetchApiData();
    }, [])

    const handleChange = (event, color) => {
        setColor(color.hex8);
    };
    const onUpdate = () => {
        updateColorList();
    };

    const editColor = (index) => {
        setSelectedColorIndex(index)
    }
    const handleEditColor = (event, color, index) => {
        setEditCurrentColor(color.hex8);
        const colors = [...colorList];
        colors[index] = color.hex8;
        setColorList(colors);
    }

    const addColor = () => {
        setColorList([...colorList, color]);
        setColor("");
        setShowColorPalette(false);
    }
    if (isColorListLoading) {
        return <Box sx={{ display: 'flex', minHeight: '300px', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </Box>
    }
    return <>
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
            <Box sx={{ display: 'flex' }}><Box component={'h4'} sx={{ py: 0 }}>Node/Edge Color List</Box>
                <Button sx={{ ml: 'auto', mr: 1 }} onClick={() => onUpdate()}>Update</Button>
            </Box>
            <Box sx={{ borderTop: '1px solid #ccc' }}></Box>

            {colorList.map((col, index) => <Box key={col} sx={{ display: 'flex', py: 2, alignItems: 'center', maxWidth: '360px' }}>
                {index + 1}. <MuiColorInput sx={{ ml: 0.5 }} size="small" variant="outlined" value={col} onChange={(event, c) => handleEditColor(event, c, index)} disabled={selectedColorIndex !== index} />
                <Box sx={{ ml: 'auto' }}><EditIcon sx={{ color: "#797EF6" }} onClick={() => editColor(index)}></EditIcon></Box>
                <Box sx={{ ml: 1 }}><DeleteIcon sx={{ color: "#797EF6" }} onClick={() => setColorList(colorList.filter(c => c !== col))}></DeleteIcon></Box>
            </Box>)}


            <Box sx={{ borderTop: '1px solid #ccc', my: 1, maxWidth: '400px' }}></Box>
            <Box sx={{ display: 'flex', }}>
                <Button sx={{ mr: 1 }} onClick={() => setShowColorPalette(true)}>CHOOSE NEW COLOR +</Button>
                {showColorPalette && <Box sx={{ display: 'flex', alignItems: 'center' }}><MuiColorInput format="hex8" value={color}
                    onChange={handleChange} />
                    <AddCircleIcon sx={{ ml: 1 }} onClick={addColor}></AddCircleIcon>
                    <CloseIcon sx={{ ml: 1 }} onClick={() => setShowColorPalette(false)}></CloseIcon>
                </Box>}
            </Box>

        </Box>

    </>
}