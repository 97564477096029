import { useRete } from "rete-react-plugin";
import { useDispatch } from 'react-redux';
import uiAction from '../../../store/actions/uiAction';//../../store/actions/uiAction
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { createEditor } from "./editor";
import Box from '@mui/material/Box';
import { useForm } from "react-hook-form";


const ReteGraph = forwardRef(function (props, ref) {
    const [ref1, editor] = useRete(createEditor);
    const dispatch = useDispatch();
    const [nodeCount, setNodeCount] = useState(0);
    useEffect(() => {
        if (editor) {
            editor.listeners = {};
            setNodeCount(props?.dataset?.nodes?.length);
            editor.addFromJson(props.dataset);
            editor.setSelectedReview(props.selectedReview)
            editor.showHideGrid(true);
        }
        window.addEventListener('localStorageChange', triggerEvent);
        return () => {
            window.removeEventListener('localStorageChange', triggerEvent);
        };
    }, [props.dataset])
    const [searchText, setSearchText] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        watch,
        reset
    } = useForm({
        mode: 'onChange', // Enable live validation
    });


    function onZoom(sign) {
        if (sign < 0) {
            editor.zoomAt(-0.1)
        } else {
            editor.zoomAt(0.1);
        };
    }

    function addIndependentNode(node) {
        editor.addIndependentNode(node);
    }

    function handleSearch(text) {
        editor.searchInEditor(text);
    }


    const refreshTree = async () => {
        editor.refreshTree();
    };

    useImperativeHandle(ref, () => ({
        refreshTree,
        setSearchText,
        searchText,
        onZoom,
        addIndependentNode,
        handleSearch
    }));

    function triggerEvent(event) {
        if (event.detail.key === 'nodeCount') {
            setNodeCount(event.detail.value);
        } else if (event.detail.key === 'show-message') {
            dispatch(uiAction.showSnackbar({ type: event.detail.type || 'error', message: event.detail.value }));
        } 
    }

    return (
        <>
            <Box className="rete-graph-container">
                <Box className="loader-container" id="loaderContainer">
                    <Box className="loader">Loading...</Box>
                    {/* <CircularProgress className="loader" color="secondary" />  */}
                    {/* <LinearProgress className="loader" color="inherit" /> */}
                </Box>
                <Box sx={{
                    position: 'absolute', zIndex: 1, p: 1, px: 2,
                    top: 100,
                    backgroundColor: 'rgb(255 255 255 / 50%)',
                    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2);',
                    borderRadius: 2,
                    ml: 2
                }}>
                    Node Counts: <b>{nodeCount}</b>
                </Box>
                <Box ref={ref1} style={{ height: "calc(100vh - 81px)", position: "relative" }}></Box>
            </Box>
        </>
    )
});

export default ReteGraph;
