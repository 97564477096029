import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
export default function FormAutocomplete(props) {
    return (
        <Autocomplete
            id={"autocomplete-" + props.id}
            sx={{ width: 200 }}
            options={props.options}
            getOptionLabel={(option) => typeof option === 'object' ? option.label : option}
            getOptionKey={(option) => typeof option === 'object' ? option.value : option}
            disableClearable
            autoHighlight
            value={props.value}
            multiple={props.multiple}
            // inputValue={props.color}
            onChange={props.handleChange}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {typeof option === 'object' ? option.label : option}
                </Box>
            )}
            renderInput={(params) => (
                <>
                    <TextField
                        disabled
                        {...params}
                        variant="standard"
                        label={props.selectLabel}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                </>
            )}
        />
    )
}
