import { useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
const NodeLabelLink = (prop) => {
    const { props } = prop;
    const { id, label } = props.data;
    const { doc_id } = props?.data?.data;
    const [anchorLinkEl, setAnchorLinkEl] = useState(null);
    const openLink = Boolean(anchorLinkEl);
    const handleLinkClick = (event) => {
        setAnchorLinkEl(event.currentTarget);
    };
    const handleLinkClose = () => {
        setAnchorLinkEl(null);
    };
    return <>
        <IconButton
            id={"link-button" + id}
            key={'link-button-' + id + 'key'}
            aria-controls={openLink ? 'link-menu' + id : undefined}
            aria-haspopup="true"
            aria-expanded={openLink ? 'true' : undefined}
            onPointerDown={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()}
            onClick={handleLinkClick}
            sx={{ p: 0, py: 1.1, ':hover': { backgroundColor: 'unset' } }}
        >
            {openLink ? <ExpandLessIcon sx={{ color: '#fff' }} /> : <ExpandMoreIcon sx={{ color: '#fff' }} />}
        </IconButton>
        <Menu
            id={"link-menu" + id}
            key={'link-menu-' + id + 'key'}
            anchorEl={anchorLinkEl}
            open={openLink}
            onClose={handleLinkClose}
            MenuListProps={{
                'aria-labelledby': 'link-button' + id,
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2, py: 1, m: 0, maxWidth: '300px', maxHeight: '500px', overflow: 'auto' }}>
                <Box component={'h3'} sx={{ p: 0, m: 0 }}>Node: {!!doc_id && <Tooltip title={'View Source Document'}>
                    <IconButton
                        onPointerDown={(e) => e.stopPropagation()}
                        onDoubleClick={(e) => e.stopPropagation()}
                        onClick={() => {
                            window.open(`${window.location.origin}/#/layout/uploads/${doc_id}/details`, "_blank");
                        }}
                    >
                        <OpenInNewIcon />
                    </IconButton>
                </Tooltip>}</Box>
                <Box>
                    {label}
                </Box>
                {(props?.data?.data?.link || []).length > 0 && <Box component={'h3'} sx={{ p: 0, m: 0, mt: 1 }}>References:</Box>}
                {(props?.data?.data?.link || []).map(link => {
                    return <Box>
                        <Link rel="noopener noreferrer" sx={{ cursor: 'pointer' }} onClick={
                            () => window.open(link, "_blank")
                        }>
                            {link}
                        </Link>

                    </Box>
                })}
            </Box>
        </Menu>
    </>

}

export default NodeLabelLink;