import { useState } from "react";
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Card from '@mui/material/Card';
import LeftMenuList from '../../common/LeftMenuList';
import Uploads from "./Uploads";
import UploadListTable from "./UploadListTable";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function UploadContainer() {
    const location = useLocation();
    const state = location.state;
    const [value, setValue] = useState(state?.tab || 0);
    const [isGraphOpened, setIsGraphOpened] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <LeftMenuList></LeftMenuList>
                <Box component="h3" sx={{ pl: 1 }}> Uploads</Box>
            </Box>
            <Box sx={{ width: '100%', }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Documents" {...a11yProps(0)} />
                        <Tab label="Upload Data" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={1}>
                    <Uploads></Uploads>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={0}>
                    <UploadListTable isGraphOpened={isGraphOpened} setIsGraphOpened={setIsGraphOpened}></UploadListTable>
                </CustomTabPanel>
            </Box>
            {/* <Box sx={{ borderRadius: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '0', width: '100%' }}>
                <CssBaseline />

                <Box sx={{ display: 'flex', width: '100%', zIndex: 1 }}>
                    <Card sx={{
                        maxWidth: '100%',
                        mt: 1,
                    }}>
                        <Box sx={{ display: 'flex', px: 1 }}>
                            <LeftMenuList></LeftMenuList>
                            <Box component="h3" sx={{ px: 2 }}>Uploads</Box>
                        </Box>
                    </Card>
                </Box>
                <Uploads></Uploads>
            </Box > */}
        </>
    )
}
