import { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';


import Container from '@mui/material/Container';
import OutlinedInput from '@mui/material/OutlinedInput';
import Input from '@mui/material/Input';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import axios from "./utility/api/apiWithoutToken";
import uiAction from './store/actions/uiAction';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { blue, grey } from "@mui/material/colors";
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            Sielo App
            {' ' + new Date().getFullYear()}
            {'.'}
            All Rights Reserved
        </Typography>
    );
}
const theme = createTheme();
export default function ForgetPassword() {
    const isMobile = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        watch,
        reset
    } = useForm({
        mode: 'onChange', // Enable live validation
    });

    const [formData, setformData] = useState({
        email_id: '',
        otp_sent: false,
        auth_token: '',
        password: '',
        _confirmPassword: '',
        _showPassword: false,
        _showConfirmPassword: false,
        _isValid: true,
    });
    const [loading, setLoading] = useState(false);
    const onSubmit = (form) => {
        let data = {
            'email_id': form.email_id,
        };
        setLoading(true);
        if (formData.otp_sent) {
            data['auth_token'] = form.auth_token;
            data['password'] = formData.password;
            axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/update-user-password`,
                data,
                // headers: {
                //     "Content-Type": "multipart/form-data",
                // },
            })
                .then((res) => {
                    if (!res.data.status) {
                        // reset();
                        // setformData({
                        //     ...formData,
                        //     otp_sent: false,
                        // });
                        dispatch(uiAction.showSnackbar({ type: 'error', message: res.data.message }));
                        setLoading(false);
                        return;
                    }
                    setLoading(false);
                    reset();
                    navigate('/sign-in')
                    setformData({
                        ...formData,
                        otp_sent: false,
                    });
                    dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'Password updated Successfuly!!!' }));
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
                });
        } else {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/generate-password-token`,
                data,
                // headers: {
                //     "Content-Type": "multipart/form-data",
                // },
            })
                .then((res) => {
                    if (!res.data.status) {
                        dispatch(uiAction.showSnackbar({ type: 'error', message: res.data.message }));
                        setLoading(false);
                        return;
                    }
                    setLoading(false);
                    // reset();
                    // navigate('/sign-in')
                    setformData({
                        ...formData,
                        otp_sent: true,
                    });
                    dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'OTP sent Successfuly!!!' }));
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
                });
        }



    };


    const handleChange = (prop) => (event) => {
        setformData({ ...formData, [prop]: event.target.value });
    };

    const handleClickShowPassword = (prop) => () => {
        setformData({
            ...formData,
            [prop]: !formData[prop],
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validationRules = {
        email_id: {
            required: 'Email is required',
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email',
            },
        },
        user_name: {
            required: 'Name is required',
        },
        org_name: {
            required: 'Organization name is required',
        },
        password: {
            required: 'Password is required',
            // pattern: {
            //   value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&]).{6,}(?=.*[@$!%*?&]).*$/,
            //   message: 'Invalid password',
            // },
        },
        _confirmPassword: {
            required: 'Confirm Password is required',
            // pattern: {
            //   value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&]).{6,}(?=.*[@$!%*?&]).*$/,
            //   message: 'Invalid password',
            // },
            validate: (value) => value === watch('password') || 'Passwords do not match',
        },
        contact_num: {
            required: 'Mobile number is required',
            // pattern: {
            //   value: /^[0-9]{10}$/,
            //   message: 'Invalid mobile number',
            // },
        },
    };
    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ mt: 4, justifyContent: 'center', backgroundColor: '#fff' }}>
                <CssBaseline />
                <Grid xs={12} sm={8} md={5} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 3,
                    m: isMobile ? 1 : 3
                }} component={Paper} elevation={1}>
                    {/* <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <Avatar sx={{ width: 20, height: 20, m: 1, border: `2px solid ${theme.palette.background.paper}`, bgcolor: 'secondary.main' }}>
                                <EditIcon sx={{ width: 12, height: 12, }} />
                            </Avatar>
                        }
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <PersonIcon />
                        </Avatar>
                    </Badge> */}
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <Avatar sx={{ width: 24, height: 24, m: 1, border: `2px solid ${theme.palette.background.paper}`, bgcolor: blue[500] }}>
                                <LockOutlinedIcon sx={{ width: 16, height: 16, }} />
                            </Avatar>
                        }
                    >
                        <Avatar sx={{ m: 1, bgcolor: blue[500], width: 56, height: 56 }} src="/company-logo.jpeg">
                            {/* <PersonIcon /> */}
                        </Avatar>
                    </Badge>
                    <Typography component="h1" variant="h5">
                        Forget Password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                        <TextField
                            required
                            margin="normal"
                            fullWidth
                            id="email_id"
                            label="Email Address"
                            variant='standard'
                            name="email_id"
                            {...register('email_id', {
                                ...validationRules.email_id,
                            })}
                            error={errors.email_id}
                            helperText={errors.email_id && errors.email_id.message}
                        />
                        {formData.otp_sent && <><FormControl sx={{ mt: 2 }} variant="standard" fullWidth>
                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={formData.showPassword ? 'text' : 'password'}
                                value={formData.password}
                                onChange={handleChange('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword('showPassword')}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {formData.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                            <TextField
                                required
                                margin="normal"
                                fullWidth
                                id="auth_token"
                                label="OTP"
                                variant='standard'
                                name="auth_token"
                                {...register('auth_token', {
                                    ...validationRules.auth_token,
                                })}
                                error={errors.auth_token}
                                helperText={errors.auth_token && errors.auth_token.message}
                            />
                        </>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, backgroundColor: '#797EF6' }}
                            disabled={!isValid || loading}
                            startIcon={loading ? <CircularProgress size={20} /> : null}
                        >
                            {formData.otp_sent ? 'Save password' : 'Generate OTP'}
                        </Button>
                        <Grid container sx={{ mt: 4 }}>
                            <Grid item xs={12}>
                                <Link href="/#/sign-up" variant="body2">
                                    {"Do not have an account? Sign Up"}
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <Link href="/#/sign-in" variant="body2">
                                    {"Already have an account? Sign In"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {/* <Copyright sx={{ ...resultTextStyle, mt: 1, p: 1, width: '100%', order: 1, backgroundColor: blue['100'], fontWeight: 400, color: '#000' }} /> */}
            </Grid>
        </ThemeProvider>
    );
}