import { useEffect, useState } from 'react';
import BarChart from '../../charts/Barchart';
import axios from "../../utility/api/api";
import Box from '@mui/material/Box';
export default function Api() {
    const fetchUrl = 'api/get-api-usage';
    const [apiLoading, setApiLoading] = useState(true);
    const [chartData, setChartData] = useState([]);
    const fetchApiData = async () => {
        try {
            const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
            const body = { "auth_token": jsonStorage.auth_token };
            setApiLoading(true);
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/${fetchUrl}`,
                data: body
            })
            const data = response.data.data;
            console.log(data);
            let arr = []
            for (let key in data) {
                arr.push({ xAxis: key, value: data[key] });
            }
            setChartData(arr);
            setApiLoading(false);
        } catch (error) {
            console.log(error);
            setApiLoading(false);
        }

    }

    useEffect(() => {
        fetchApiData();
    }, [])
    if (apiLoading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',height:'100%' }}>Loading...</Box>
    }
    return (
        <>
            <BarChart yColumn="Count" xAxis="xAxis" data={chartData}></BarChart>
        </>
    )
}
