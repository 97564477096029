// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRef, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import axios from "../../../utility/api/api";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import UploadIcon from '@mui/icons-material/Upload';
import Card from '@mui/material/Card';
import Loader from '../../../Loader';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from 'react';
import ReteGraph from './rete/rete-graph';
import { sampleVersionDetail } from '../../../constants/LoadChecker';//./constants/LoadChecker
import { showLoader, formatTimestamp, hideLoader, prepareReteTreeV3 } from '../../../utility/helper';
import LeftMenuList from '../../../common/LeftMenuList';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AppDialog from '../../../common/AppDialog';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import { blueGrey } from '@mui/material/colors';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import uiAction from '../../../store/actions/uiAction';
import Tooltip from '@mui/material/Tooltip';
import PDFViewer from '../../../common/react-pdf/PDFViewer';

export default function CommitDetails(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { dataId } = useParams();
    const [projectId, setProjectId] = useState("");
    const [showNodeView, setShowNodeView] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [loading, setLoading] = useState(false);
    const [dataset, setDataset] = useState(null);
    const [empty, setEmpty] = useState(false);
    const [docType, setDocType] = useState('');
    const [pdfUrl, setPdfUrl] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    // const [dataId, setDataId] = useState("700e2007-c46c-4926-b9b9-bdf43d1ffb70");
    const [openSearchBar, setOpenSearchBar] = useState(false);
    const [searchChartText, setSearchChartText] = useState('');

    const resultTextStyle = {
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
        fontFamily: 'Google Sans, Helvetica Neue, sans - serif',
        letterSpacing: 'normal',
        whiteSpace: 'pre - wrap',
    }
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleGraphSearch = (status) => {
        setOpenSearchBar(status);
    };


    const branchRef = useRef();
    const textChartFieldRef = useRef(null);

    function prepareLoadData(n) {
        const preparedDataSet = sampleVersionDetail();
        setLoading(true);
        showLoader();
        setTimeout(() => {
            setDataset(preparedDataSet);
            setTimeout(() => {
                setLoading(false);
            }, 300)
        }, 200)
    }


    function initPage(dataId) {
        prepareLoadData(10);
    }


    useEffect(() => {
        const storage = localStorage.getItem('sielo_search_app');
        if (storage) {
            const storageJson = JSON.parse(storage);
            if (!storageJson.isLoggedIn) {
                navigate("/sign-in");
            }
            // getVersionDetail(dataId);
            getVersionHistory(dataId);
            prepareLoadData(10);
            setEmpty(false);
            hideLoader();
            setTimeout(() => {
                onVisChange();
            }, 2000)

        } else {
            navigate("/sign-in");
        }
       
    }, []);

    const setChildSearchText = (text) => {
        setSearchChartText(text);
        branchRef.current.setSearchText(text);
    }

    const handleSearchChartKeyPress = (event) => {
        if (event.key === 'Enter') {
            branchRef.current.handleSearch(searchChartText);
        }
    };

    function getVersionHistory(doc_id) {
        showLoader();
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token, doc_id };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/document-version-history`,
            data: body
        })
            .then((res) => {
                console.log(res);
            }).catch(err => {
                hideLoader();
            })

    }

    function openConfirmationDialog() {
        setOpenDialog(true);
    }
    function openUploadDoc() {
        navigate("/layout/uploads", { state: { tab: 1, project_id: projectId, project_name: projectName } });
    }

    function closeConfirmationDialog() {
        setOpenDialog(false);
    }
    function onDeleteDocument() {
        showLoader();
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const body = { "auth_token": jsonStorage.auth_token, doc_id: dataId };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/delete-doc`,
            data: body
        })
            .then((res) => {
                dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'Document deleted Successfuly!!!' }));
                setTimeout(() => {
                    navigate("/layout/uploads");
                }, 1000);

            }).catch(err => {
                hideLoader();
            })
    }
    function pageChange(pageNo) {
        if (pageNo <= 0) {
            pageNo = numPages;
        }
        if (!(pageNo % (numPages + 1))) {
            pageNo = 1;
        }
        setPageNumber(pageNo % (numPages + 1));
    }
    function onSetNumPages(num) {
        console.log(num);
        setNumPages(num);
    }

    function onVisChange() {
        if (!showNodeView) {
            setTimeout(() => {
                if (branchRef?.current) branchRef.current.refresh();
            }, 100);
        }
        setShowNodeView(!showNodeView);
    }

    return (
        <Box sx={{ borderRadius: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '0', width: '100%', height: '100vh' }} >
            <CssBaseline />
            {<Card sx={{
                mt: -1,
                ml: -2,
                mr: 'auto',
                transform: 'translateX(0px)',
                boxShadow: 'unset',
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                width: '100%', maxWidth: '100vw'
            }}>
                <Box sx={{ m: 0, width: '100%', border: 'none', borderBottom: '1px solid #ebebeb !important' }}></Box>
                {!loading && <Container className='abcd' maxWidth disableGutters sx={{ m: 0, display: 'flex', flexDirection: 'column', p: 0, backgroundColor: '#fff' }}>
                    {empty && <Box sx={{ display: 'flex', minHeight: '85vh', minWidth: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Box>
                            No data found
                        </Box>
                    </Box>}
                    {!empty && <ReteGraph ref={branchRef} dataset={dataset} dataId={dataId}></ReteGraph>}
                </Container>}
                {!empty && <Box sx={{ position: 'absolute', bottom: 0, left: 0, zIndex: 1, boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2);', backgroundColor: "#fff", p: 1, m: 2, borderRadius: '8px' }}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <IconButton
                            aria-label="close"
                            onClick={() => branchRef.current.onZoom(-1)}
                            sx={{
                                color: (theme) => theme.palette.grey[900],
                            }}
                        >
                            <RemoveIcon />
                        </IconButton>
                        <IconButton
                            aria-label="close"
                            onClick={() => branchRef.current.onZoom(1)}
                            sx={{
                                color: (theme) => theme.palette.grey[900],
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                        {!openSearchBar && <IconButton
                            id={"search-in-graph"}
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={() => handleGraphSearch(true)}
                            sx={{ mr: 1, color: blueGrey[900] }}
                        >
                            <SearchIcon sx={{ fontSize: 30 }} />
                        </IconButton>}
                        {openSearchBar && <Box sx={{ display: 'flex', p: 1 }}>
                            <OutlinedInput
                                id="search-box-d3"
                                placeholder="Search"
                                autoComplete="off"
                                size="small"
                                inputRef={textChartFieldRef}
                                endAdornment={<InputAdornment position="end"><SearchIcon sx={{ fontSize: 30, color: '#797EF6' }} /></InputAdornment>}
                                variant="outlined"
                                value={searchChartText}

                                maxRows={5}
                                sx={{ ...resultTextStyle, flex: isMobile ? 12 : 8, background: "#fff", borderRadius: '8px', maxWidth: "320px", my: 0, mr: isMobile ? 0 : 1, ml: 1 }}
                                onChange={(e) => {
                                    // setSearchChartText(e.target.value);
                                    setChildSearchText(e.target.value);
                                }}
                                onKeyDown={handleSearchChartKeyPress}
                            />
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => handleGraphSearch(false)}
                                sx={{ color: blueGrey[900] }}
                            >
                                <CloseIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                        </Box>}
                    </Box>
                </Box>}


            </Card>}
            <AppDialog
                openDialog={openDialog}
                onOpenDialog={closeConfirmationDialog}
                dialogTitle={'Delete Document'}
                maxWidth="md"
                attachedComponent={<Box sx={{ p: 2 }}>Are You Sure you want to delete the document?</Box>}
                actions={<Box sx={{ px: 2, display: 'flex' }}>
                    <Button
                        type="submit"
                        onClick={onDeleteDocument}
                        variant="contained"
                        sx={{ mt: 1, mb: 1, ml: 'auto', backgroundColor: '#797EF6' }}
                    >
                        Yes
                    </Button>
                    <Button
                        type="submit"
                        onClick={closeConfirmationDialog}
                        variant="contained"
                        sx={{ mt: 1, mb: 1, ml: 1, backgroundColor: '#797EF6' }}
                    >
                        No
                    </Button>
                </Box>}
            ></AppDialog>
        </Box >



    );
}