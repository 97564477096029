import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

const sampleUsers = [
    { fullName: 'John Doe', email: 'john@example.com', contact: '123-456-7890', time: '10:00 AM' },
    { fullName: 'Jane Smith', email: 'jane@example.com', contact: '123-456-7891', time: '11:00 AM' },
    { fullName: 'Alice Johnson', email: 'alice@example.com', contact: '123-456-7892', time: '12:00 PM' },
    { fullName: 'Bob Brown', email: 'bob@example.com', contact: '123-456-7893', time: '01:00 PM' },
    { fullName: 'Charlie Davis', email: 'charlie@example.com', contact: '123-456-7894', time: '02:00 PM' },
    { fullName: 'David Wilson', email: 'david@example.com', contact: '123-456-7895', time: '03:00 PM' },
    { fullName: 'Eve Clark', email: 'eve@example.com', contact: '123-456-7896', time: '04:00 PM' },
    { fullName: 'Frank Miller', email: 'frank@example.com', contact: '123-456-7897', time: '05:00 PM' },
    { fullName: 'Grace Lee', email: 'grace@example.com', contact: '123-456-7898', time: '06:00 PM' },
    { fullName: 'Henry Martin', email: 'henry@example.com', contact: '123-456-7899', time: '07:00 PM' },
];

const UsersComponent = () => {
    const [users, setUsers] = useState(sampleUsers);
    const [open, setOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [formData, setFormData] = useState({ fullName: '', email: '', contact: '', time: '', password: '' });

    const handleClickOpen = () => {
        setFormData({ fullName: '', email: '', contact: '', time: '', password: '' });
        setEditIndex(-1);
        setOpen(true);
    };

    const handleEditClickOpen = (index) => {
        setFormData({ ...users[index], password: '' });
        setEditIndex(index);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = () => {
        if (editIndex === -1) {
            setUsers((prevUsers) => [...prevUsers, formData]);
        } else {
            setUsers((prevUsers) => prevUsers.map((user, index) => (index === editIndex ? formData : user)));
        }
        handleClose();
    };

    return (
        <div>
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
                sx={{ backgroundColor: '#888888', '&:hover': { backgroundColor: '#777777' } }} // optional: change the hover color
            >
                Add User
            </Button>
            <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Full Name</TableCell>
                            <TableCell>Email Id</TableCell>
                            <TableCell>Contact</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user, index) => (
                            <TableRow key={index}>
                                <TableCell>{user.fullName}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.contact}</TableCell>
                                <TableCell>{user.time}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEditClickOpen(index)}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{editIndex === -1 ? 'Add User' : 'Edit User'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="fullName"
                        label="Full Name"
                        type="text"
                        fullWidth
                        value={formData.fullName}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="email"
                        label="Email Id"
                        type="email"
                        fullWidth
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="contact"
                        label="Contact"
                        type="text"
                        fullWidth
                        value={formData.contact}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="time"
                        label="Time"
                        type="text"
                        fullWidth
                        value={formData.time}
                        onChange={handleChange}
                    />
                    {editIndex !== -1 && (
                        <TextField
                            margin="dense"
                            name="password"
                            label="Password"
                            type="password"
                            fullWidth
                            value={formData.password}
                            onChange={handleChange}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        {editIndex === -1 ? 'Add' : 'Edit'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UsersComponent;
