import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import AppTable from '../common/AppTable';
import { useDispatch } from 'react-redux';
import uiAction from '../store/actions/uiAction';
import axios from "../utility/api/api";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import AppDialog from '../common/AppDialog';
import DBColumn from './DBColumn';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
const sampleRows = [
    [
        "0b9fc703-5cde-4cb9-b8f5-fc7d7e71a796", // user_id
        "e31f493c-fa04-40a2-90f6-a53bc4269b0f", // table_id
        "Sielo Data", // data_store
        "llm_data", // db_name
        "JOB_DATA", // table_name
        "Pending", // status
        "2023-10-23 21:26:50", // time
    ],
    [
        "0b9fc703-5cde-4cb9-b8f5-fc7d7e71a796",
        "99b4823a-b082-495a-823f-7db41209a2ca",
        "Sielo Data",
        "llm_data",
        "USER_QUERY",
        "Pending",
        "2023-10-23 21:26:52"
    ]
];
export default function DBTable() {
    const navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    const dispatch = useDispatch();
    const myRef = useRef();
    const columns = [
        {
            id: 'db_name',
            numeric: false,
            label: 'Database name',
        },
        {
            id: 'table_name',
            numeric: false,
            label: 'Table name',
        },
        {
            id: 'status',
            numeric: false,
            label: 'Status',
            type: 'chip',
            colors:{'Registered':'success','Deregistered':'error'}
        },
        {
            id: 'time',
            numeric: false,
            label: 'Time',
        },
        {
            id: 'action',
            numeric: false,
            label: 'Action',
        },
    ];
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        fetchTableData();
    }, [])

    function fetchTableData() {
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        let data = {
            user_id: jsonStorage.user_id || '',
            db_name: params.db_name.toLowerCase(),
            "auth_token": jsonStorage.auth_token 
        };
        setLoading(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/get-table-list`,
            data
        })
            .then((res) => {
                if (!res.data.status) {
                    dispatch(uiAction.showSnackbar({ type: 'error', message: res.data.message }));
                    return;
                }
                setLoading(false);
                setRows((res.data.data || []).map((row) => preparedRow(row)))

                // dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'User registered Successfuly!!!' }));
            })
            .catch((err) => {
                setLoading(false);
                dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
            });
    }

    function preparedRow(item) {
        return {
            user_id: item[0],
            table_id: item[1],
            data_store: item[2],
            db_name: item[3],
            table_name: item[4],
            status: item[5],
            time: item[6],
            id: item[1],
        }
    }
    // const computedRows = rows.map((row) => preparedRow(row));
    function onView(row) {
        // navigate(`${row.table_id}/column`, { ...row })
        setSelectedRow(row);
        setOpenDialog(!openDialog)
    }

    function closeDialog(status) {
        setOpenDialog(status)
    }

    function onBack() {
        navigate(`../`)
    }
    function getColumnDetails() {
        return <DBColumn ref={myRef} table_id={selectedRow?.table_id}></DBColumn>
    }
    function handleClose() {
        let { rows, table_desc } = myRef.current.getRows();
        const { table_id, user_id } = selectedRow;
        rows = rows.map(col => ({
            col_name: col.column,
            data_type: col.datatype,
            col_desc: col.details
        }));
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const data = {
            table_desc,
            col_list: rows,
            table_id,
            user_id,
            "auth_token": jsonStorage.auth_token 
        };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/register-sql-data-table`,
            data
        })
            .then((res) => {
                if (!res.data.status) {
                    dispatch(uiAction.showSnackbar({ type: 'error', message: res.data.message }));
                    return;
                }
                setOpenDialog(false);
                dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'User registered Successfuly!!!' }));
                fetchTableData();
            })
            .catch((err) => {
                dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
            });

        // setOpenDialog(false);
    }

    function handleDelete() {
        const { table_id, user_id } = selectedRow;
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        const data = {
            table_id,
            user_id,
            "auth_token": jsonStorage.auth_token 
        };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/deregister-sql-data-table`,
            data
        })
            .then((res) => {
                if (!res.data.status) {
                    dispatch(uiAction.showSnackbar({ type: 'error', message: res.data.message }));
                    return;
                }
                setOpenDialog(false);
                dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'Success!!!' }));
                fetchTableData();
            })
            .catch((err) => {
                dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
            });

        // setOpenDialog(false);
    }

    function getActions() {
        return <>
            <Button variant='contained' color="error" autoFocus onClick={handleDelete} endIcon={<DeleteIcon />}>
                Delete
            </Button>
            <Button variant='contained' sx={{fontSize:'sm'}} autoFocus onClick={handleClose} endIcon={<SendIcon />}>
                Register
            </Button></>
    }
    return (
        <Box>

            <AppTable
                loading={loading}
                showBack={true}
                onBack={onBack}
                columns={columns}
                rows={rows}
                showCheckBox={false}
                toolbarTitle={'Table'}
                toolbarActionTitle={'Add Table'}
                onViewClick={(row) => onView(row)}
            ></AppTable>
            <AppDialog
                openDialog={openDialog}
                onOpenDialog={closeDialog}
                dialogTitle={'Table Details'}
                attachedComponent={getColumnDetails()}
                actions={getActions()}
            ></AppDialog>
        </Box>
    )
}
