import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ResponsiveSearchContainer from './ResponsiveSearchContainer';
import Header from './common/Header';
import Credentials from './Credentials';
import Databases from './Databases';
import { useLocation, useNavigate, Navigate, Route, Routes } from "react-router-dom";
import CustomSnackbar from "./snackbar/CustomSnackbar";
import { useSelector } from "react-redux";
import { useEffect, useState, createContext } from "react";
import SignIn from './SignIn';
import SignUp from './SignUp';
import NotFound from './NotFound';
import Layout from './Layout';
import AppContext from './context/AppContext';
import AppTable from './common/AppTable';
import DataStore from './Pages/DataStore';
import DBTable from './Pages/DBTable';
import DBColumn from './Pages/DBColumn';
import Profile from './Pages/Profile';
import DataAnalytics from './Pages/data-analytics/DataAnalytics';
import DataAnalyticsLayout from './Pages/data-analytics/DataAnalyticsLayout';
import Setting from './Pages/setting/Setting';
import ForgetPassword from './ForgetPassword';
import Review from './Pages/Review/Review';
import UploadContainer from './Pages/Uploads/UploadContainer';
import UploadDetails from './Pages/Uploads/UploadDetails';
import BranchDetails from './Pages/branch-info/BranchDetails';
import MeddraDetails from './Pages/Meddra/MeddraDetails';
import MeddraCompareNode from './Pages/Meddra/MeddraCompareNode';
function App() {
  let location = useLocation();
  const [pathname, setPathname] = useState('');
  const navigate = useNavigate();
  const { isLogedIn, user } = useSelector((state) => state.login);
  useEffect(() => {
    setTimeout(() => {
      let obj = { pathname: "/" };
      if (isLogedIn) {
        switch (user.userCategory) {
          case "super_admin": {
            obj.pathname = "/organizations";
            obj.userCategory = "Super Admin";
            break;
          }
          case "admin": {
            obj.pathname = "/projects";
            obj.userCategory = "Admin";
            break;
          }

        }
        if (obj.pathname) {
          if (navigate) {
            navigate({
              pathname: obj.pathname,
              state: obj,
            });
          }
        }
      }
    }, 300);
  }, [isLogedIn]);

  useEffect(() => {
    console.log(location);
    setPathname(location.pathname);
  }, [location]);
  const [contextValue, setContextValue] = useState(false);
  const updateContextValue = (newValue) => {
    setContextValue(newValue);
  };
  return (

    <>
      <AppContext.Provider value={{ contextValue, updateContextValue }}>
        {!['/sign-in', '/sign-up', '/forget-password'].includes(pathname) && <Header></Header>}
        <Routes>
          <Route path="/sign-in" exact element={<SignIn />} />
          <Route path="/sign-up" exact element={<SignUp />} />
          <Route path="/forget-password" exact element={<ForgetPassword />} />
          {/* <Route path="/search" exact element={<ResponsiveSearchContainer />} />
          <Route path="/credentials" exact element={<Credentials />} />
          <Route path="/databases" exact element={<Databases />} /> */}
          <Route path="/layout" exact element={<Layout />} >
            <Route path="" exact element={<Navigate to="search" />} />
            <Route path="search" exact element={<ResponsiveSearchContainer isMeddra={false}/>} />
            <Route path="profile" exact element={<Profile />} />
            <Route path="setting" exact element={<Setting />} />
            <Route path="credentials" exact element={<Credentials />} />
            <Route path="data-analytics" exact element={<DataAnalyticsLayout />} />
            <Route path="review/:id" element={<Review />} />
            <Route path="uploads" exact element={<UploadContainer />} />
            <Route path="branch-info" exact element={<BranchDetails />} />
            <Route path="uploads/:dataId/details" element={<UploadDetails/>} />
            <Route path="meddra" element={<MeddraDetails/>} />
            <Route path="meddra/:docId/compare-nodes" element={<MeddraCompareNode />} />
            {/* <Route path="databases" exact element={<Databases />} /> */}
            <Route path="databases" exact element={<Databases />} >
              <Route path="" exact element={<Navigate to="datastore" />} />
              <Route path="datastore" exact element={<DataStore />} />
              <Route path=":db_name/table" exact element={<DBTable />} />
              <Route path=":db_name/table/:table_id/column" exact element={<DBColumn />} />
            </Route>
          </Route>
          <Route path="/" exact element={<Navigate to="/layout" />} />
          <Route path="*" exact element={<NotFound />} />
        </Routes>
      </AppContext.Provider>

      <CustomSnackbar />
    </>
  );

}

export default App;
