import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { blue, blueGrey, orange } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import axios from "../../utility/api/api";
import { useDispatch } from 'react-redux';
import uiAction from '../../store/actions/uiAction';
export default function ProfileComponent() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(true);
    const sampleFormData = { user_name: '', email_id: '', contact_num: '', org_url: '', org_name: '' }
    const [formData, setFormData] = useState(sampleFormData);
    const [value, setValue] = useState(0);

    const getProfileData = async () => {
        try {
            setLoading(true);
            const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
            const body = { "auth_token": jsonStorage.auth_token };
            const res = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/get-user-details`,
                data: body
            })
            console.log(res.data.data);
            setLoading(false);
            setTimeout(() => {
                setFormData({ ...res.data.data });
            }, 200)
        } catch (error) {
            setLoading(false);
            // console.log(error);
        }


    }

    useEffect(() => {
        getProfileData();
    }, [])
    function onBack() {
        navigate(`/`)
    }
    const abbreviateWord = (word = "") => {
        let abbrWord = "";
        word
            .split(" ")
            .forEach((w) => (abbrWord = abbrWord + (w[0] || "").toUpperCase()));
        return abbrWord;
    };

    const handleChange = (prop) => (event) => {
        setFormData({ ...formData, [prop]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const { user_name, email_id, contact_num, org_url, org_name } = formData;
        // let data = {
        //   email_id: formData.email,
        //   password: formData.password
        // };
        axios({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + "/api/update-user-details",
            data: { user_name, email_id, contact_num, org_url, org_name }
        }).then(async res => {
            if (!res.data.status) {
                dispatch(uiAction.showSnackbar({ type: 'error', message: res.data.message }));
                return;
            }
            dispatch(uiAction.showSnackbar({ type: 'success', message: res.data.message || 'Profile updated successfully' }));
        }).catch(err => {
            console.log(err);
            dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
        });
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', display: 'flex', minHeight: 'calc(100vh - 180px)', flexDirection: isMobile ? 'column' : 'row' }}>
                <Box sx={{ minHeight: isMobile ? '50%' : '100%', flex: 0.5, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: blue[400], color: '#000', px: '32px' }}>
                    <Avatar sx={{ bgcolor: orange[500], width: 100, height: 100, mb: 2, color: '#000', fontSize: '32px' }}>{abbreviateWord(formData.user_name)}</Avatar>
                    <Typography variant={'h6'} sx={{ fontWeight: '500', }}>{formData.user_name}</Typography>
                    <Typography variant={'p'} sx={{ fontWeight: '200' }}>{formData.email_id}</Typography>
                </Box>
                {loading && <> <Box sx={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box></>}
                {!loading && <Box component="form" noValidate onSubmit={handleSubmit} sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', px: 3, }}>
                <Box sx={{ color: '#797EF6', fontWeight:600, fontSize: '24px', pb: 2 }}>Edit Profile</Box>
                    <Box sx={{ display: 'flex' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="user_name"
                            label="User Name"
                            name="user_name"
                            type="text"
                            variant='standard'
                            sx={{ mr: 2 }}
                            autoFocus
                            value={formData.user_name}
                            onChange={handleChange('user_name')}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email_id"
                            label="Email Address"
                            name="email_id"
                            type="text"
                            variant='standard'
                            autoFocus
                            value={formData.email_id}
                            onChange={handleChange('email_id')}
                        />
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="org_name"
                            label="Organization Name"
                            name="org_name"
                            type="text"
                            variant='standard'
                            sx={{ mr: 2 }}
                            autoFocus
                            value={formData.org_name}
                            onChange={handleChange('org_name')}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="contact_num"
                            label="Mobile No"
                            name="contact_num"
                            type="text"
                            variant='standard'
                            autoFocus
                            value={formData.contact_num}
                            onChange={handleChange('contact_num')}
                        />
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="org_url"
                            label="Organization URL"
                            name="org_url"
                            type="text"
                            variant='standard'
                            autoFocus
                            value={formData.org_url}
                            defaultValue={""}
                            onChange={handleChange('org_url')}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', px: 0 }}>
                        <Button
                            margin="normal"
                            variant='contained'
                            type="submit"
                            sx={{ mt: 6, mb: isMobile ? 3 : 0,backgroundColor: '#797EF6' }}
                        >Submit</Button>
                    </Box>

                </Box>}
            </Box>
        </Box >
    )
}
