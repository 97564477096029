import React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppTable from '../../common/AppTable'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import uiAction from '../../store/actions/uiAction';
import axios from "../../utility/api/api";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import ResponsiveSearchContainer from '../../ResponsiveSearchContainer';
const sampleRows = [
    {
        "db_name": "COLLAB_DB",
        "store_name": "Snowflake"
    },
    {
        "db_name": "FRAUD_VAL_DB1",
        "store_name": "Snowflake"
    }
];
export default function DataAnalyticsListTable() {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const [datsetId, setDatsetId] = useState('');
    const columns = [
        {
            id: 'dataset_name',
            numeric: false,
            label: 'Dataset Name',
        },
        {
            id: 'dataset_details',
            numeric: false,
            label: 'Details',
        },
        {
            id: 'action',
            numeric: false,
            label: 'Action',
        },
    ];
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(true);
    useEffect(() => {
        const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
        let data = {
            data_format: 'csv',
            user_id: jsonStorage.user_id || '',
            auth_token: jsonStorage.auth_token
        };
        setLoading(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/get-doc-list`,
            data
        })
            .then((res) => {
                if (!res.data.status) {
                    dispatch(uiAction.showSnackbar({ type: 'error', message: res.data.message }));
                    return;
                }
                setLoading(false);
                let size = res.data.doc_list.doc_details.length;
                const data_ids = res.data.doc_list.doc_id;
                const data_details = res.data.doc_list.doc_details;
                const data_name = res.data.doc_list.doc_name;
                const data_status = res.data.doc_list.doc_status;
                let prepRows = [];

                for (let i = 0; i < size; i++) {
                    prepRows.push({ id: data_ids[i], dataset_name: data_name[i], dataset_details: data_details[i] });
                }
                setRows(prepRows);
                // setRows((res.data.data_list.data_details || []).map((row) => preparedRow(row)))

                // dispatch(uiAction.showSnackbar({ type: 'success', message: res?.data?.message || 'User registered Successfuly!!!' }));
            })
            .catch((err) => {
                setLoading(false);
                dispatch(uiAction.showSnackbar({ type: 'error', message: 'Something went wrong.Please try after some time' }));
            });
    }, [])

    function preparedRow(item) {
        return { id: item[0], dataset_name: item[1], dataset_details: item[2] }
    }

    function onView(row) {
        setDatsetId(row.id);
        setShowTable(false);
    }
    function onBack() {
        setShowTable(true);
    }


    return (
        <Box>
            {showTable && <AppTable
                loading={loading}
                columns={columns}
                rows={rows}
                showCheckBox={false}
                toolbarTitle={'Data Analytics'}
                toolbarActionTitle={'Add Dataset'}
                onViewClick={(row) => onView(row)}
            ></AppTable>}
            {!showTable && <>
                <Box sx={{ width: '100%', maxWidth: '100%', backgroundColor: "#fff", borderRadius: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', p: 4 }} component={Paper} elevation={1}>

                    <ResponsiveSearchContainer
                        hideDbType={true}
                        hideDefaultSampleQuery={true}
                        showBackBtn={true}
                        extraBody={{ data_id: datsetId }
                        }
                        onBack={onBack}
                        queryUrl='api/query-table-data'
                    ></ResponsiveSearchContainer>
                </Box>
            </>}
        </Box>
    )
}
