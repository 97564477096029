import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { blue, blueGrey, lightBlue } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Token from './Token';
import Api from './Api';
import ColorConfig from './ColorConfig';
import SampleSearch from './SampleSearch';
import CustomTabPanel from '../../common/CustomTabPanel';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Setting() {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box onClick={() => navigate(-1)} sx={{
                        display: 'flex',
                        pr: 2,
                        cursor: 'pointer',
                        '&:hover': { textDecorationLine: 'none', color: blue[500], },
                        color: '#797EF6'
                    }}>
                        <ArrowBackIcon sx={{ mr: 0.25, }} />
                        <Box sx={{ fontWeight: '500' }}>Go Back</Box>
                    </Box>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Token" {...a11yProps(0)} />
                        <Tab label="API Usage" {...a11yProps(1)} />
                        <Tab label="Color Config" {...a11yProps(2)} />
                        <Tab label="Sample Search" {...a11yProps(3)} />
                    </Tabs>
                </Box>


            </Box>
            <CustomTabPanel value={value} index={0}>
                <Token></Token>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Api></Api>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <ColorConfig></ColorConfig>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <SampleSearch></SampleSearch>
            </CustomTabPanel>
        </Box>
    )
}
