import { colorMap } from '../utility/helper';
let colors = ["orange", "magenta", "yellow", "blue", "green", "red", "cyan"]
function generateRandomTreeData(n) {
    // Function to generate UUID (Universally Unique Identifier)
    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    // Generate random nodes with unique IDs
    const nodes = [];
    let reviews = [{ type: 'a', updatedBy: 'avinash@gmail.com', updatedAt: '15-03-2024', comment: 'Add comment goes here' },
    { type: 'u', updatedFields: { category: "triangle", color: colors[0], name: 'updated name' }, updatedBy: 'avinash@gmail.com', updatedAt: '15-03-2024', comment: 'Update comment goes here' },
    { type: 'd', updatedBy: 'avinash@gmail.com', updatedAt: '15-03-2024', comment: 'Delete comment goes here' },
        null];
    let linkReviews = [{ type: 'a', updatedBy: 'avinash@gmail.com', updatedAt: '15-03-2024', comment: 'Add comment goes here' },
    { type: 'u', updatedFields: { label: `updated link`, color: colors[0] }, comment: 'Update comment goes here' },
    { type: 'd', updatedBy: 'avinash@gmail.com', updatedAt: '15-03-2024', comment: 'Delete comment goes here' },
        null];
    for (let i = 1; i <= n; i++) {
        nodes.push({
            id: uuidv4(), name: `node ${i}`,
            color: colorMap[i % 4 == 0 ? colors[5] : i % 3 === 0 ? colors[4] : colors[0]],
            category: "square",
            review: reviews[i % 4 === 0 ? 0 : i % 6 === 0 ? 1 : i % 9 === 0 ? 2 : 3]
        });
    }

    const links = [];
    for (let i = 2; i <= n; i++) {
        const parentIndex = Math.floor(Math.random() * (i - 1));
        links.push({
            id: uuidv4(),
            type: i % 2 == 0 ? 'dash' : 'solid',
            review: linkReviews[i % 5 === 0 ? 0 : i % 6 === 0 ? 1 : i % 9 === 0 ? 2 : 3],
            source: nodes[parentIndex].id,
            target: nodes[i - 1].id,
            label: `link ${parentIndex}`,
            color: colorMap[i % 4 == 0 ? colors[3] : i % 3 === 0 ? colors[4] : colors[0]]
        });
    }

    return { nodes, links };
}

export function sampleBranchData() {
    // Function to generate UUID (Universally Unique Identifier)
    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    // Generate random nodes with unique IDs
    const nodeIds = [uuidv4(), uuidv4(), uuidv4(), uuidv4(), uuidv4(), uuidv4(), uuidv4()];
    const nodes = [{
        id: nodeIds[0], name: `node 1`,
        color: colors[4],
        category: "square",
        level: 1,
    },
    {
        id: nodeIds[1], name: `node 2`,
        color: colors[4],
        category: "square",
        level: 1,
    },
    {
        id: nodeIds[2], name: `node 3`,
        color: colors[4],
        category: "square",
        level: 1,
    },
    {
        id: nodeIds[3], name: `node 4`,
        color: colors[4],
        category: "square",
        level: 1,
        isMainBranchNode: true
    },
    {
        id: nodeIds[4], name: `node 5`,
        color: colors[5],
        level: 2,
        category: "square",
    },
    {
        id: nodeIds[5], name: `node 6`,
        color: colors[3],
        level: 2,
        category: "square",
    },
    {
        id: nodeIds[6], name: `node 7`,
        color: colors[5],
        level: 2,
        category: "square",
    }];

    const links = [{
        id: uuidv4(),
        type: 'solid',
        source: nodes[0].id,
        target: nodes[1].id,
        // label: `link 1`,
        color: colors[4],
    },
    {
        id: uuidv4(),
        type: 'solid',
        source: nodes[1].id,
        target: nodes[2].id,
        // label: `link 2`,
        color: colors[4]
    },
    {
        id: uuidv4(),
        type: 'solid',
        source: nodes[2].id,
        target: nodes[3].id,
        // label: `link 3ddd`,
        color: colors[4]
    },
    // {
    //     id: uuidv4(),
    //     type: 'solid',
    //     source: nodes[3].id,
    //     target: nodes[4].id,
    //     label: `link 4`,
    //     color: colors[5]
    // },
    {
        id: uuidv4(),
        type: 'solid',
        source: nodes[5].id,
        target: nodes[3].id,
        // label: `link 4`,
        color: colors[3]
    },
    {
        id: uuidv4(),
        type: 'solid',
        source: nodes[1].id,
        target: nodes[5].id,
        // label: `link 5`,
        color: colors[3]
    },
    {
        id: uuidv4(),
        type: 'solid',
        source: nodes[1].id,
        target: nodes[4].id,
        // label: `link 6`,
        color: colors[5]
    },
    {
        id: uuidv4(),
        type: 'solid',
        source: nodes[1].id,
        target: nodes[6].id,
        // label: `link 7`,
        color: colors[5]
    }
    ];
    return { nodes, links };
}

export function sampleVersionDetail() {
    // Function to generate UUID (Universally Unique Identifier)
    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    // Generate random nodes with unique IDs
    const nodeIds = [uuidv4(), uuidv4(), uuidv4(), uuidv4(), uuidv4(), uuidv4(), uuidv4()];
    const nodes = [{
        id: nodeIds[0], name: `branch 1`,
        color: colors[4],
        category: "circle",
        updated_date: '12-05-2024',
        level: 1,
    },
    {
        id: nodeIds[1], name: `branch 1`,
        color: colors[4],
        updated_date: '18-05-2024',
        category: "square",
        level: 1,
    },
    {
        id: nodeIds[2], name: `branch 1`,
        color: colors[4],
        category: "square",
        updated_date: '19-05-2024',
        level: 1,
    },
    {
        id: nodeIds[3], name: `branch 1`,
        color: colors[4],
        category: "square",
        updated_date: '20-05-2024',
        level: 1,
        isMainBranchNode: true
    },
    {
        id: nodeIds[4], name: `branch 2`,
        color: colors[5],
        level: 2,
        updated_date: '18-05-2024',
        category: "square",
    },
    {
        id: nodeIds[5], name: `branch 3`,
        color: colors[3],
        level: 2,
        updated_date: '20-05-2024',
        category: "square",
    },
    {
        id: nodeIds[6], name: `branch 4`,
        color: colors[1],
        level: 2,
        updated_date: '20-05-2024',
        category: "square",
    }];

    const links = [{
        id: uuidv4(),
        type: 'solid',
        source: nodes[0].id,
        target: nodes[1].id,
        // label: `link 1`,
        color: colors[4],
    },
    {
        id: uuidv4(),
        type: 'solid',
        source: nodes[1].id,
        target: nodes[2].id,
        // label: `link 2`,
        color: colors[4]
    },
    {
        id: uuidv4(),
        type: 'solid',
        source: nodes[2].id,
        target: nodes[3].id,
        // label: `link 3ddd`,
        color: colors[4]
    },
    // {
    //     id: uuidv4(),
    //     type: 'solid',
    //     source: nodes[3].id,
    //     target: nodes[4].id,
    //     label: `link 4`,
    //     color: colors[5]
    // },
    {
        id: uuidv4(),
        type: 'solid',
        source: nodes[5].id,
        target: nodes[3].id,
        // label: `link 4`,
        color: colors[3]
    },
    {
        id: uuidv4(),
        type: 'solid',
        source: nodes[1].id,
        target: nodes[5].id,
        // label: `link 5`,
        color: colors[3]
    },
    {
        id: uuidv4(),
        type: 'solid',
        source: nodes[1].id,
        target: nodes[4].id,
        // label: `link 6`,
        color: colors[5]
    },
    {
        id: uuidv4(),
        type: 'solid',
        source: nodes[1].id,
        target: nodes[6].id,
        // label: `link 7`,
        color: colors[1]
    }
    ];
    return { nodes, links };
}

export default generateRandomTreeData;