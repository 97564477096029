import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        // color: 'rgba(0, 0, 0, 0.87)',
        //   maxWidth: 220,
        // minWidth: '450px',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #ccc',
    },
}));

export default HtmlTooltip;