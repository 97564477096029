import { v4 as uuidv4 } from 'uuid';
import axios from "../utility/api/api";
import { curveMonotoneX } from "d3-shape";
const generateID = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
}

const colorMap =
{
    "orange": "#ffa331",
    "magenta": "#c149d6",
    "yellow": "#ffcf00",
    "blue": "#6d6dff",
    "green": "#66db66",
    "red": "#ed7777",
    "cyan": "#56c9c9"
}
const strokeMap = { "solid": 'Solid', "dash": 'Dash' };
const nodeLabelMap = { "circle": 'Circle', "square": 'Square', "triangle": 'Triangle' };

const generateUUID = () => {
    return uuidv4();
}


function removeDuplicate(arr) {
    let newArr = [];
    arr.filter(l => l.source !== l.target).forEach(item => {
        const hasSome = newArr.some(i => i.source === item.source && i.target === item.target);
        if (!hasSome) {
            newArr.push(item);
        }
    })
    return newArr;
}

function formateVersion(nodeObj, versionId) {
    let preparedNodes = (nodeObj.node_list || []).map(n => ({
        id: n.id,
        link: (n.updated ? n.updated.link : n.main.link) || [],
        doc_id: (n.updated ? n.updated.doc_id : n.main.doc_id) || "",
        name: n.updated ? n.updated.text : n.main.text,
        color: n.updated ? n.updated.meta?.color : n.main.meta?.color,
        category: n.updated ? n.updated.meta?.label : n.main.meta?.label,
        child_count: n.updated ? n.updated.child_count : n.main.child_count,
        action: n.action,
        main: {
            name: n.main?.text,
            color: n.main?.meta?.color,
            category: n.main?.meta?.label,
            child_count: n.main?.child_count,
        },
        version_id: versionId
    }));
    let preparedLinks = (nodeObj.edge_list || []).map(link => ({
        id: link.id,
        source: link.updated ? link.updated?.pc[0] : link.main?.pc[0],
        target: link.updated ? link.updated?.pc[1] : link.main?.pc[1],
        type: link.updated ? link.updated?.meta.stroke : link.main?.meta?.stroke,
        label: link.updated ? link.updated?.text : link.main?.text,
        color: link.updated ? link.updated?.meta?.color : link.main?.meta?.color,
        action: link.action,
        version_id: versionId,
        main: {
            source: link.main?.pc[0],
            target: link.main?.pc[1],
            type: link.main?.meta.stroke,
            label: link.main?.text,
            color: link.main?.meta?.color,
        }
    }));
    preparedLinks = removeDuplicate(preparedLinks);
    return {
        nodes: preparedNodes,
        links: preparedLinks
    }

}

const triggerEvent = (key, value = null, type = null) => {
    const event = new CustomEvent('localStorageChange', { detail: { key, value, type } });
    window.dispatchEvent(event);
};

function prepareReteTreeV3(nodeObj) {
    let preparedNodes = (nodeObj.nodes_list || []).map(n => ({
        id: n.id,
        name: n.text,
        doc_id: n.doc_id || "",
        link: n.link || [],
        color: n.meta.color || "#f0f0f0",
        category: n.meta.label,
        child_count: n.child_count,
    }));
    let preparedLinks = (nodeObj.edge_list || []).map(link => ({
        id: link.id,
        source: link.pc[0],
        target: link.pc[1],
        type: link.meta.stroke,
        label: link.text,
        color: link.meta.color || "steelblue",
    }));
    preparedLinks = removeDuplicate(preparedLinks);
    return {
        nodes: preparedNodes,
        links: preparedLinks
    }

}
function showHideGrid(show) {
    let ele = document.getElementById('editor-grid');
    if (show) {
        if (!ele.classList.contains("fill-area")) {
            ele.classList.add("fill-area");
        }
    }
    else {
        if (ele.classList.contains("fill-area")) {
            ele.classList.remove("fill-area");
        }
    }
}

function addCustomBackground(
    area
) {
    const background = document.createElement("div");

    background.id = 'editor-grid';
    background.classList.add("background");
    background.classList.add("fill-area");

    area.area.content.add(background);
}


const multiLineEllipsis = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100% - 50px)',
    textAlign: 'initial'
}

function stringAvatar(name) {
    const arr = (name || '').split(' ');
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${arr[0][0]}${arr[1] ? arr[1][0] : ''}`,
    };
}
function getFormattedDate(inputDate) {
    const date = new Date(inputDate);
    const modifyDate = (value) => {
        return value < 10 ? '0' + value : value;
    }
    const datevalues = [
        date.getFullYear(),
        modifyDate(date.getMonth() + 1),
        modifyDate(date.getDate()),
        modifyDate(date.getHours()),
        modifyDate(date.getMinutes()),
        modifyDate(date.getSeconds()),
    ];
    return `${datevalues[2]}-${datevalues[1]}-${datevalues[0]} ${datevalues[3]}:${datevalues[4]}`;
}

function getUser(type) {
    const groupsObj = {
        g1: 'Cedant',
        g2: 'Broker',
        g2b: 'Senior Broker',
        g3: 'Insurer',
        admin: 'Admin'
    };
    return groupsObj[type] || type;
}

function stringToColor(string = '') {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}


function millisecondsToTime(duration) {
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((duration % (1000 * 60)) / 1000);

    let timeString = '';
    if (hours > 0) {
        timeString += hours + ' h ';
    }
    if (minutes > 0) {
        timeString += minutes + ' min ';
    }
    if (seconds > 0 || timeString === '') {
        timeString += seconds + ' sec';
    }

    return timeString;
}

function showLoader() {
    let doc = document.getElementById('loaderContainer');
    if (doc) doc.style.display = 'block';
}

function hideLoader() {
    let doc = document.getElementById('loaderContainer');
    if (doc) doc.style.display = 'none';
}

function getAllChildren(rootNodeId, currentTarget, links, targetNodes, editor) {
    let newLinks = [...links];
    for (const item of newLinks) {
        if (item.source === currentTarget && item.target !== rootNodeId) {
            // const hasLink = hasOtherLink(item.target, newLinks, targetNodes, rootNodeId);
            // if (hasLink) {
            //     continue;
            // };
            if (!targetNodes.includes(item.target)) {
                targetNodes.push(item.target);
                if (editor.collapsedNode && editor.collapsedNode[item.target]) {
                    targetNodes.push(...(editor.collapsedNode[item.target]?.nodes || []).map(i => i.id));
                }
                getAllChildren(rootNodeId, item.target, links, targetNodes, editor);
            }
            // const index = newLinks.findIndex(l => l.id === item.id);
        }
    }
}

function hasOtherLink(nodeId, links, targetNodes, rootNodeId) {
    const connectedLinks = links.filter(l => l.target === nodeId);
    if (connectedLinks.length > 1) {
        return connectedLinks.some(l => ![...targetNodes, rootNodeId].includes(l.source));
    }
    return false;
}


function removeDuplicatesFromArray(arr, field = null) {
    const uniqueIds = [];
    return arr.filter(element => {
        const isDuplicate = uniqueIds.includes(field ? element[field] : element);
        if (!isDuplicate) {
            uniqueIds.push(element[field]);
            return true;
        }
        return false;
    });
}

// Function to find the number of nodes connected directly or indirectly
function numberOfConnectedNodes(nodeId, editor) {
    const targetNodes = [];
    const existingLinks = [...editor.getConnections()];

    if (editor.collapsedNode) {
        let collNodes = Object.values(editor.collapsedNode);
        const links = collNodes.reduce((arr, curr) => {
            arr.push(...(curr.links || []));
            return arr;
        }, []) || [];
        existingLinks.push(...links);
    }

    const uniqueLinks = removeDuplicatesFromArray(existingLinks, 'id');
    getAllChildren(nodeId, nodeId, uniqueLinks, targetNodes, editor);
    const uniqueNodes = removeDuplicatesFromArray(targetNodes);
    return uniqueNodes.length;
}

function formatTimestamp(timestamp) {
    const date = new Date(+timestamp);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = months[date.getMonth()];
    const yyyy = date.getFullYear();
    const hh = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    const ss = String(date.getSeconds()).padStart(2, '0');
    return `${dd} ${mm} ${yyyy} : ${hh}:${min}:${ss}`;
}


const callFetchChildNodeApi = async (node_id) => {
    const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
    return await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/get-document-node-childs`,
        data: { node_id, "auth_token": jsonStorage.auth_token, level: 2 }
    });

}

const callFetchParentNodeApi = async (node_id) => {
    const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
    return await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/get-document-node-parents`,
        data: { node_id, "auth_token": jsonStorage.auth_token, level: 2 }
    });

}


function hasOtherParentLink(nodeId, links, targetNodes, rootNodeId) {
    const connectedLinks = links.filter(l => l.source === nodeId);
    if (connectedLinks.length > 1) {
        return connectedLinks.some(l => ![...targetNodes, rootNodeId].includes(l.target));
    }
    return false;
}


function getAllParentV2(rootNodeId, currentTarget, links, targetNodes, targetLinks) {
    let newLinks = [...links];
    for (const item of newLinks) {
        if (item.target === currentTarget && item.source !== rootNodeId) {
            const hasLink = hasOtherParentLink(item.source, newLinks, targetNodes, rootNodeId);
            if (hasLink) {
                targetLinks.push(item);
                continue;
            };
            if (!targetNodes.includes(item.source)) {
                targetLinks.push(item);
                targetNodes.push(item.source);
                getAllParentV2(rootNodeId, item.source, links, targetNodes, targetLinks);
            } if (!targetLinks.some(l => l.id === item.id)) {
                targetLinks.push(item);
            }
        }
    }
}

function getNodeWithPos(nodeId, socket, editor, Connection, arrange, area) {
    const node = editor.getNode(nodeId);
    const parentPos = area.nodeViews.get(node.id).position;
    node.x = parentPos.x;
    node.y = parentPos.y;
    return node;
}

const appendParentSubTree = async (nodes, links, parentNode, socket, editor, Connection, arrange, area, createNode) => {
    for (let i = 0; i < nodes.length; i++) {
        if (!editor.getNode(nodes[i].id)) {
            const a = createNode(nodes[i], socket, editor, Connection, arrange, area);
            await editor.addNode(a);
            const p = getNodeWithPos(parentNode.id, socket, editor, Connection, arrange, area);
            await area.translate(a.id, { x: p.x - 350, y: p.y - (i * 100) });
            editor.updateCollapseParentAction();
        }
    }

    let linkLen = links.length;
    for (let i = 0; i < linkLen; i++) {
        const l = links[i];
        if (!editor.getConnection(linkLen.id)) {
            const source = editor.getNode(l.source);
            const target = editor.getNode(l.target);
            if (source && target) {
                let conn1 = new Connection(source, "port", target, "port");
                conn1.id = l.id;
                conn1.label = l.label;
                conn1.type = l.type;
                conn1.color = l.color;
                await editor.addConnection(conn1);
                conn1.curve = curveMonotoneX;
            }
        }

    }
    return;
}


export {
    generateUUID,
    generateID,
    multiLineEllipsis,
    stringAvatar,
    stringToColor,
    getUser,
    getFormattedDate,
    millisecondsToTime,
    showLoader,
    hideLoader,
    numberOfConnectedNodes,
    colorMap,
    strokeMap,
    nodeLabelMap,
    formatTimestamp,
    formateVersion,
    prepareReteTreeV3,
    showHideGrid,
    addCustomBackground,
    triggerEvent,
    callFetchChildNodeApi,
    callFetchParentNodeApi,

    getAllParentV2,
    appendParentSubTree
};