import { createRoot } from "react-dom/client";
import { NodeEditor, GetSchemes, ClassicPreset } from "rete";
import { AreaPlugin, AreaExtensions } from "rete-area-plugin";
import { MinimapExtra, MinimapPlugin } from "rete-minimap-plugin";
import { CustomNode } from "./CustomNode";
import { CustomSocket } from "./CustomSocket";
import { CustomConnection } from "./CustomConnection";
import { numberOfConnectedNodes, generateUUID, showHideGrid, addCustomBackground, callFetchChildNodeApi, callFetchParentNodeApi } from '../../../utility/helper';
import "./background.css";
import { ConnectionPathPlugin } from "rete-connection-path-plugin";
import { curveStep, curveMonotoneX } from "d3-shape";
import { removeNodeWithConnections } from "./utils";

import { millisecondsToTime, hideLoader, showLoader } from "../../../../src/utility/helper";
import {
  MagneticConnection
} from "./magnetic-connection";

import {
  ConnectionPlugin,
  Presets as ConnectionPresets,
  getSourceTarget,
  BidirectFlow,
  ClassicFlow
} from "rete-connection-plugin";
import { ReactPlugin, Presets } from "rete-react-plugin";
import {
  AutoArrangePlugin,
  Presets as ArrangePresets,
  ArrangeAppliers
} from "rete-auto-arrange-plugin";

class Node extends ClassicPreset.Node {
  width = 180;
  height = 80;
  editor = null;
  childCount = null;
  opCount = 0;
  colCount = 0;
  isCollapsed = false;
  showBadge = false;
  data = {};
  setChildCount = (count) => this.childCount = count;
  setEditor = (editor) => this.editor = editor;

}

const triggerEvent = (key, value) => {
  const event = new CustomEvent('localStorageChange', { detail: { key, value } });
  window.dispatchEvent(event);
};

const prepareSubTree = (json) => {
  let preparedNodes = (json.nodes_list || []).map(n => ({
    id: n.id,
    name: n.text,
    link: n.link,
    color: n.meta.color || "#f0f0f0",
    category: n.meta.label,
    child_count: n.child_count
  }));
  let preparedLinks = (json.edge_list || []).map(link => ({
    id: link.id,
    source: link.pc[0],
    target: link.pc[1],
    type: link.meta.stroke,
    label: link.text,
    color: link.meta.color || "steelblue"
  }));
  // updateLinks(preparedLinks);
  preparedLinks = removeDuplicate(preparedLinks, 'link');
  preparedNodes = removeDuplicate(preparedNodes, 'node');
  return {
    nodes: preparedNodes,
    links: preparedLinks
  }
}

function removeDuplicate(arr, type) {
  let newArr = [];
  if (type === 'link') {
    arr.filter(l => l.source !== l.target).forEach(item => {
      const hasSome = newArr.some(i => i.source === item.source && i.target === item.target);
      if (!hasSome) {
        newArr.push(item);
      }
    })
  } else {
    arr.forEach(item => {
      const hasSome = newArr.some(node => node.id === item.id);
      if (!hasSome) {
        newArr.push(item);
      }
    })
  }


  return newArr;
}

const appendSubTree = async (nodes, links, parentNode, socket, editor, Connection, arrange, area) => {
  for (let i = 0; i < nodes.length; i++) {
    if (!editor.getNode(nodes[i].id)) {
      const a = createNode(nodes[i], socket, editor, Connection, arrange, area);
      await editor.addNode(a);
      const p = getNodeWithPos(parentNode.id, socket, editor, Connection, arrange, area);
      await area.translate(a.id, { x: p.x + 350, y: p.y + (i * 100) });
      editor.updateCollapseAction();
    }
  }

  let linkLen = links.length;
  for (let i = 0; i < linkLen; i++) {
    const l = links[i];
    if (!editor.getConnection(linkLen.id)) {
      const source = editor.getNode(l.source);
      const target = editor.getNode(l.target);
      if (source && target) {
        let conn1 = new Connection(source, "port", target, "port");
        conn1.id = l.id;
        conn1.label = l.label;
        conn1.type = l.type;
        conn1.color = l.color;
        await editor.addConnection(conn1);
        conn1.curve = curveMonotoneX;
      }
    }

  }
  // editor.setDefaultJson();
  return;
}

const appendParentSubTree = async (nodes, links, parentNode, socket, editor, Connection, arrange, area) => {
  for (let i = 0; i < nodes.length; i++) {
    if (!editor.getNode(nodes[i].id)) {
      const a = createNode(nodes[i], socket, editor, Connection, arrange, area);
      await editor.addNode(a);
      const p = getNodeWithPos(parentNode.id, socket, editor, Connection, arrange, area);
      await area.translate(a.id, { x: p.x - 350, y: p.y - (i * 100) });
      editor.updateCollapseParentAction();
    }
  }

  let linkLen = links.length;
  for (let i = 0; i < linkLen; i++) {
    const l = links[i];
    if (!editor.getConnection(linkLen.id)) {
      const source = editor.getNode(l.source);
      const target = editor.getNode(l.target);
      if (source && target) {
        let conn1 = new Connection(source, "port", target, "port");
        conn1.id = l.id;
        conn1.label = l.label;
        conn1.type = l.type;
        conn1.color = l.color;
        await editor.addConnection(conn1);
        conn1.curve = curveMonotoneX;
      }
    }

  }
  // editor.setDefaultJson();
  return;
}

function createNode(n, socket, editor, Connection, arrange, area) {
  const node = new Node(n.name);
  if (!n.id) n.id = generateUUID();
  node.id = n.id;
  node.data = n;
  node.childCount = n.child_count || n?.data?.child_count || 0;
  node.setEditor(editor);
  node.addInput("port", new ClassicPreset.Input(socket, '', true));
  node.addOutput("port", new ClassicPreset.Output(socket));
  // node.addControl('expand-backward', {
  //   type: 'expand-backward', async onClick(form) {
  //     console.log('expand-backward');
  //   }
  // });
  // node.addControl('expand-forward', {
  //   type: 'expand-forward', async onClick(form) {
  //     console.log('expand-backward');
  //   }
  // });
  // node.addControl('collapse-backward', {
  //   type: 'collapse-backward', async onClick() {
  //     console.log('collapse-backward');
  //   }
  // });
  // node.addControl('collapse-forward', {
  //   type: 'collapse-forward', async onClick() {
  //     console.log('collapse-forward');
  //   },
  // });
  node.addControl('collapse-expand-parent-button', {
    type: 'collapse-parent', async onClick() {

      const type = node.controls['collapse-expand-parent-button'].type;
      if (type === 'collapse-parent') {
        node.showBadge = true;
        if (!editor.collapsedParentNode) editor.collapsedParentNode = {};
        editor.collapsedParentNode[node.id] = { nodes: [], links: [] };
        let cNodes = editor.collapsedParentNode[node.id].nodes;
        let cLinks = editor.collapsedParentNode[node.id].links;
        const targetNodes = [];
        getAllParentV2(node.id, node.id, editor.getConnections(), targetNodes, cLinks);
        targetNodes.forEach(n => {
          cNodes.push(getNodeWithPos(n, socket, editor, Connection, arrange, area));
        })

        for (let i = 0; i < cLinks.length; i++) {
          await editor.removeConnection(cLinks[i].id);
        }

        for (let i = 0; i < cNodes.length; i++) {
          const n = cNodes[i];
          await editor.removeNode(n.id);
        }

        // node.setChildCount(numberOfConnectedNodes(node.id, editor));
        node.isParentCollapsed = false;
        node.controls['collapse-expand-parent-button'].type = 'expand-parent';
      } else {
        // node.isCollapsed = false;
        // node.controls['collapse-expand-button'].type = 'expand';
        const eNodes = editor.collapsedParentNode && editor.collapsedParentNode[node.id] ? editor.collapsedParentNode[node.id].nodes : [] || [];
        const eLinks = editor.collapsedParentNode && editor.collapsedParentNode[node.id] ? editor.collapsedParentNode[node.id].links : [] || [];

        if (!eNodes.length && !eLinks.length) {
          const response = await callFetchParentNodeApi(node.id);
          if (response.data.nodes.nodes_list.length) {
            const { nodes, links } = prepareSubTree(response.data.nodes);
            await appendParentSubTree(nodes, links, node, socket, editor, Connection, arrange, area);
          } else {
            triggerEvent('show-message', 'No parent exist');
          }
        } else {
          if (!eNodes.length && !eLinks.length) {
            triggerEvent('show-message', 'No parent exist');
          }
          for (let i = 0; i < eNodes.length; i++) {
            const n = eNodes[i];
            await editor.addNode(n);
            await area.translate(n.id, { x: n.x, y: n.y });
          }
          for (let i = 0; i < eLinks.length; i++) {
            await editor.addConnection(eLinks[i]);
          }
        }
        node.isParentCollapsed = true;
        node.controls['collapse-expand-parent-button'].type = 'collapse-parent';
      }
      // node.controls['collapse-expand-button'].type = type === 'collapse' ? 'expand' : 'collapse';
      editor.updateCollapseParentAction();
    },
  });
  node.addControl('collapse-expand-button', {
    type: n.defaultCollapsed ? 'expand' : 'collapse', async onClick() {

      const type = node.controls['collapse-expand-button'].type;
      // editor.removeNode(node.id);
      if (type === 'collapse') {
        node.showBadge = true;
        // node.isCollapsed = true;
        // node.controls['collapse-expand-button'].type = 'collapse';
        if (!editor.collapsedNode) editor.collapsedNode = {};
        editor.collapsedNode[node.id] = { nodes: [], links: [] };
        let cNodes = editor.collapsedNode[node.id].nodes;
        let cLinks = editor.collapsedNode[node.id].links;
        const targetNodes = [];
        getAllChildrenV2(node.id, node.id, editor.getConnections(), targetNodes, cLinks);
        targetNodes.forEach(n => {
          cNodes.push(getNodeWithPos(n, socket, editor, Connection, arrange, area));
        })

        for (let i = 0; i < cLinks.length; i++) {
          await editor.removeConnection(cLinks[i].id);
        }

        for (let i = 0; i < cNodes.length; i++) {
          const n = cNodes[i];
          await editor.removeNode(n.id);
        }

        // node.setChildCount(numberOfConnectedNodes(node.id, editor));
        node.isCollapsed = false;
        node.controls['collapse-expand-button'].type = 'expand';
      } else {
        // node.isCollapsed = false;
        // node.controls['collapse-expand-button'].type = 'expand';
        const eNodes = editor.collapsedNode && editor.collapsedNode[node.id] ? editor.collapsedNode[node.id].nodes : [] || [];
        const eLinks = editor.collapsedNode && editor.collapsedNode[node.id] ? editor.collapsedNode[node.id].links : [] || [];

        if (!eNodes.length && !eLinks.length && node?.data?.child_count) {
          const response = await callFetchChildNodeApi(node.id);
          if (response.data.nodes.nodes_list.length) {
            const { nodes, links } = prepareSubTree(response.data.nodes);
            await appendSubTree(nodes, links, node, socket, editor, Connection, arrange, area);
            // node.showBadge = false;
          } else {
            triggerEvent('show-message', 'No children exist');
          }
        } else {
          if (!eNodes.length && !eLinks.length) {
            triggerEvent('show-message', 'No children exist');
          }
          for (let i = 0; i < eNodes.length; i++) {
            const n = eNodes[i];
            await editor.addNode(n);
            await area.translate(n.id, { x: n.x, y: n.y });
          }
          for (let i = 0; i < eLinks.length; i++) {
            await editor.addConnection(eLinks[i]);
          }
        }
        node.isCollapsed = true;
        // node.showBadge = false;
        node.controls['collapse-expand-button'].type = 'collapse';
      }
      // node.controls['collapse-expand-button'].type = type === 'collapse' ? 'expand' : 'collapse';
      editor.updateCollapseAction();
    },
  });
  return node;
}

function getAllChildrenV2(rootNodeId, currentTarget, links, targetNodes, targetLinks) {
  let newLinks = [...links];
  for (const item of newLinks) {
    if (item.source === currentTarget && item.target !== rootNodeId) {
      const hasLink = hasOtherLink(item.target, newLinks, targetNodes, rootNodeId);
      if (hasLink) {
        targetLinks.push(item);
        continue;
      };
      if (!targetNodes.includes(item.target)) {
        targetLinks.push(item);
        targetNodes.push(item.target);
        getAllChildrenV2(rootNodeId, item.target, links, targetNodes, targetLinks);
      } if (!targetLinks.some(l => l.id === item.id)) {
        targetLinks.push(item);
      }
      // const index = newLinks.findIndex(l => l.id === item.id);
    }
  }
}

function getAllParentV2(rootNodeId, currentTarget, links, targetNodes, targetLinks) {
  let newLinks = [...links];
  for (const item of newLinks) {
    if (item.target === currentTarget && item.source !== rootNodeId) {
      const hasLink = hasOtherParentLink(item.source, newLinks, targetNodes, rootNodeId);
      if (hasLink) {
        targetLinks.push(item);
        continue;
      };
      if (!targetNodes.includes(item.source)) {
        targetLinks.push(item);
        targetNodes.push(item.source);
        getAllParentV2(rootNodeId, item.source, links, targetNodes, targetLinks);
      } if (!targetLinks.some(l => l.id === item.id)) {
        targetLinks.push(item);
      }
    }
  }
}

function hasOtherLink(nodeId, links, targetNodes, rootNodeId) {
  const connectedLinks = links.filter(l => l.target === nodeId);
  if (connectedLinks.length > 1) {
    return connectedLinks.some(l => ![...targetNodes, rootNodeId].includes(l.source));
  }
  return false;
}

function hasOtherParentLink(nodeId, links, targetNodes, rootNodeId) {
  const connectedLinks = links.filter(l => l.source === nodeId);
  if (connectedLinks.length > 1) {
    return connectedLinks.some(l => ![...targetNodes, rootNodeId].includes(l.target));
  }
  return false;
}

function getNodeWithPos(nodeId, socket, editor, Connection, arrange, area) {
  const node = editor.getNode(nodeId);
  const parentPos = area.nodeViews.get(node.id).position;
  node.x = parentPos.x;
  node.y = parentPos.y;
  return node;
}


export async function createEditor(container) {
  const socket = new ClassicPreset.Socket("socket");

  class Connection extends ClassicPreset.Connection {
    click = (p) => onClickLink(p);
    actionClick = (p) => onLinkAction(p);
    label;
    isMagnetic = false;
  }


  const editor = new NodeEditor();
  const area = new AreaPlugin(container);
  const connection = new ConnectionPlugin();
  const render = new ReactPlugin({ createRoot });
  const pathPlugin = new ConnectionPathPlugin({
    curve: (c) => c.curve || curveStep,
    // transformer: () => Transformers.classic({ vertical: false }),
    arrow: (c) => {
      return {
        color: c.color,
        // marker: 'M0,0 L0,6 L9,3 z'
      }
    }
  });
  render.use(pathPlugin);
  const arrange = new AutoArrangePlugin();
  const selector = AreaExtensions.selector();
  const accumulating = AreaExtensions.accumulateOnCtrl();
  const minimap = new MinimapPlugin({
    boundViewport: true,
  });
  AreaExtensions.selectableNodes(area, selector, { accumulating });

  render.addPreset(
    Presets.classic.setup(
      {
        customize: {
          node(context) {
            return CustomNode;
            // return Presets.classic.Node;
          },
          socket(context) {
            return CustomSocket;
          },
          connection(context) {
            if (context.payload.isMagnetic) return MagneticConnection;
            return CustomConnection;
          },
        }
      }
    )
  );

  // connection.addPreset(ConnectionPresets.classic.setup());
  connection.addPreset(() => new BidirectFlow({
    async makeConnection(from, to, context) {
      const [source, target] = getSourceTarget(from, to) || [null, null];
      const { editor } = context;

      if (source && target) {
        const conn1 = new Connection(
          editor.getNode(source.nodeId),
          "port",
          editor.getNode(target.nodeId),
          "port"
        );
        conn1.id = generateUUID();
        conn1.type = "solid";
        conn1.color = "steelblue";
        await editor.addConnection(conn1);
        conn1.curve = curveMonotoneX;
        return true; // ensure that the connection has been successfully added
      }
    }
  }))
  // connection.addPipe(async context => {
  //   if (context.type === 'connectionpick') { // when the user clicks on the socket
  //     const { socket } = context.data
  //   }
  //   if (context.type === 'connectiondrop') { // when the user clicks on the socket or any area
  //     const { socket, initial, created } = context.data;
  //     if (created) {
  //       console.log("connection created", "do some thing", context.data)

  //     }
  //   }
  //   return context
  // })

  const applier = new ArrangeAppliers.TransitionApplier({
    duration: 500,
    timingFunction: (t) => t,
    async onTick() {
      await AreaExtensions.zoomAt(area, editor.getNodes());
    }
  });

  arrange.addPreset(ArrangePresets.classic.setup());
  render.addPreset(Presets.minimap.setup({ size: 150 }));
  addCustomBackground(area);
  editor.use(area);
  area.use(connection);
  area.use(render);
  area.use(arrange);
  area.use(minimap);

  AreaExtensions.simpleNodesOrder(area);

  async function arrangeDiagram() {
    await arrange.layout({
      options: {
        'elk.spacing.nodeNode': 70,
        'elk.layered.spacing.nodeNodeBetweenLayers': 504
      }
    });
  }


  function onClickLink(props) {
    const id = props.data.id;
    const label = "connection";
    selector.add(
      {
        id,
        label,
        translate() { },
        unselect() {
          props.data.selected = false;
          const button = document.querySelector("#link-button" + id);
          if (button && button.style) button.style.display = 'none'
          area.update("connection", id);
        }
      },
      accumulating.active()
    );
    props.data.selected = true;

    area.update("connection", id);
  }

  async function deleteLink(id) {
    // await editor.removeConnection(id);
    const conn = editor.getConnection(id);
    if (!conn) return;
    const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
    let body = {
      "node_data": {
        "edge_list": [
          {
            "edge_id": id,
            "source_id": conn.source,
            "target_id": conn.target,
          },
        ],
        "node_list": []
      }, "data_type": "knowledge-graph", "auth_token": jsonStorage.auth_token
    }
    // axios({
    //   method: "post",
    //   url: `${process.env.REACT_APP_BASE_URL}/api/delete-document-chunk`,
    //   data: body
    // })
    //   .then(async (res) => {
    //     await editor.removeConnection(id);
    //   }).catch(err => {
    //     console.log(err)
    //   })

  }
  async function updateLink(id) {
    // await editor.removeConnection(id);
    const conn = editor.getConnection(id);
    if (!conn) return;
    const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
    let body = {
      "node_data": {
        "edge_list": [
          {
            "edge_id": id,
            "source_id": conn.source,
            "target_id": conn.target,
            "text": conn.label,
            "meta": {
              "color": conn.color,
              "stroke": conn.type,
            }

          },
        ],
        "node_list": []
      }, "data_type": "knowledge-graph", "auth_token": jsonStorage.auth_token
    }
    // axios({
    //   method: "post",
    //   url: `${process.env.REACT_APP_BASE_URL}/api/update-document-chunk`,
    //   data: body
    // })
    //   .then(async (res) => {

    //   }).catch(err => {
    //     console.log(err)
    //   })

  }


  async function addNodeToApi(id, conn = null) {
    // await editor.removeConnection(id);
    const node = editor.getNode(id);
    if (!node) return;
    const jsonStorage = JSON.parse(localStorage.getItem("sielo_search_app") || '{}');
    let edge_list = [];
    if (conn) {
      edge_list.push({
        "edge_id": conn.id,
        "source_id": conn.source,
        "target_id": conn.target,
        "text": conn.label,
        "meta": {
          "color": conn.color,
          "stroke": conn.type,
        },
      });
    };
    let body = {
      "node_data": {
        "edge_list": edge_list,
        "node_list": [{
          "node_id": node.id,
          "text": node.data.name,
          "meta": {
            "color": node.data.color,
            "label": node.data.category || "circle",
          }
        },
        ]
      }, "data_type": "knowledge-graph", "auth_token": jsonStorage.auth_token
    }
    // axios({
    //   method: "post",
    //   url: `${process.env.REACT_APP_BASE_URL}/api/update-document-chunk`,
    //   data: body
    // })
    //   .then(async (res) => {
    //     triggerEvent('nodeCount', editor.getNodes().length);
    //   }).catch(async err => {
    //     console.log(err);
    //     await editor.removeNode(id);
    //   })

  }

  editor.addNodeToApi = addNodeToApi;
  editor.updateCollapseAction = () => {
    const sourceLinks = editor.getConnections().map(l => l.source);
    const leafNodes = editor.getNodes().filter(n => !sourceLinks.includes(n.id));
    leafNodes.forEach(n => {
      n.controls['collapse-expand-button'].type = "expand";
      n.isCollapsed = false;
    })
  }

  editor.setDefaultJson = () => {
    editor.defaultJson = { nodes: editor.getNodes(), links: editor.getConnections() };
  }

  editor.updateCollapseParentAction = () => {
    const targetLinks = editor.getConnections().map(l => l.target);
    const leafNodes = editor.getNodes().filter(n => !targetLinks.includes(n.id));
    leafNodes.forEach(n => {
      n.controls['collapse-expand-parent-button'].type = "expand-parent";
      n.isCollapsed = false;
    })
  }

  // editor.arrangeTextPosition = () => {
  //   const sourceLinks = editor.getConnections();
  //   sourceLinks.forEach(l => {
  //     let path = document.getElementById(l.id);
  //     const pathLength = path.getTotalLength(); // Total length of the path
  //     const midpoint = path.getPointAtLength(pathLength / 2);
  //     // actionBox.style.left = (midpoint.x - actionBox.offsetWidth / 2) + "px";
  //     // actionBox.style.display = "flex";
  //     // actionBox.style.top = (midpoint.y - actionBox.offsetHeight / 2) + "px";
  //   })


  // }


  async function onLinkAction({ action, id, form }) {
    const conn = editor.getConnection(id);
    if (!conn) return;
    if (action === 'edit') {
      conn.label = form.link_name;
      conn.color = form.link_color;
      conn.type = form.link_stroke.value;
      updateLink(conn.id);
    } else if (action === 'delete') {
      await deleteLink(id);
    }
  }

  AreaExtensions.zoomAt(area, editor.getNodes());
  return {
    layout: async (animate) => {
      await arrange.layout({ applier: animate ? applier : undefined });
      AreaExtensions.zoomAt(area, editor.getNodes());
    },
    removeSelected: async () => {
      for (const item of [...editor.getConnections()]) {
        if (item.selected) {
          await editor.removeConnection(item.id);
        }
      }
      for (const item of [...editor.getNodes()]) {
        if (item.selected) {
          await removeNodeWithConnections(editor, item.id);
        }
      }
    },
    addFromJson: async (json) => {
      showLoader();
      const startTime = Date.now();
      await editor.clear();
      let nodes = (json?.nodes || []);
      let length = nodes.length;
      for (let i = 0; i < length; i++) {
        const a = createNode(nodes[i], socket, editor, Connection, arrange, area);
        await editor.addNode(a);
      }

      let links = (json?.links || []);
      let linkLen = links.length;
      for (let i = 0; i < linkLen; i++) {
        const l = links[i];
        const source = editor.getNode(l.source);
        const target = editor.getNode(l.target);
        if (source && target) {
          let conn1 = new Connection(source, "port", target, "port");
          conn1.id = l.id;
          conn1.label = l.label;
          conn1.type = l.type;
          conn1.data = l;
          conn1.color = l.color;
          await editor.addConnection(conn1);
          conn1.curve = curveMonotoneX;
        }

      }
      await arrange.layout({ applier: applier });
      await arrangeDiagram();
      const endTime = Date.now();
      console.log(endTime - startTime, millisecondsToTime(endTime - startTime));
      setTimeout(function () {
        // Once data loading is complete, hide the loader
        hideLoader();
        editor.updateCollapseAction();
        editor.updateCollapseParentAction();
        // Replace this with your actual data rendering logic
        // document.getElementById('content').innerHTML = "<p>Data Loaded Successfully!</p>";
      }, 300);
    },
    searchInEditor: async (searchTxt) => {
      showLoader();
      if (!editor.defaultJson) {
        editor.setDefaultJson();
      }
      const json = editor?.defaultJson || {};
      await editor.clear();
      let nodes = (json?.nodes || []).filter(n => n.label.toLowerCase().includes((searchTxt || "").toLowerCase())).map(n => n.data);
      let length = nodes.length;
      for (let i = 0; i < length; i++) {
        const a = createNode(nodes[i], socket, editor, Connection, arrange, area);
        await editor.addNode(a);
      }

      let links = (json?.links || []);
      let linkLen = links.length;
      for (let i = 0; i < linkLen; i++) {
        const l = links[i];
        const source = editor.getNode(l.source);
        const target = editor.getNode(l.target);
        if (source && target) {
          let conn1 = new Connection(source, "port", target, "port");
          conn1.id = l.id;
          conn1.label = l.label;
          conn1.type = l.type;
          conn1.data = l;
          conn1.color = l.color;
          await editor.addConnection(conn1);
          conn1.curve = curveMonotoneX;
        }

      }
      await arrange.layout({ applier: applier });
      await arrangeDiagram();
      setTimeout(function () {
        hideLoader();
        editor.updateCollapseAction();
        editor.updateCollapseParentAction();
      }, 300);
    },
    setSelectedReview: (review) => {
      editor.selectedReview = review;
    },
    refreshTree: async () => {
      await arrange.layout({ applier: applier });
      await arrangeDiagram();
    },
    zoomAt(k) {
      const rect = area.area.container.getBoundingClientRect();
      const ox = (rect.left - window.innerWidth / 2) * k;
      const oy = (rect.top - window.innerHeight / 2) * k;
      if (k > 0 && area.area.transform.k < 2) area.area.zoom(area.area.transform.k + k, ox, oy);
      if (k < 0 && area.area.transform.k > - 2) area.area.zoom(area.area.transform.k + k, ox, oy);
    },
    addIndependentNode: async (nodeObj) => {

      const a = createNode({
        name: nodeObj.node_name,
        color: nodeObj.node_color,
        category: (nodeObj.node_label || [{ value: "circle" }]).map(l => l.value)
      }, socket, editor, Connection, arrange, area);
      await editor.addNode(a);
      editor.addNodeToApi(a.id);
      editor.updateCollapseAction();
      editor.updateCollapseParentAction();
      // const parentPos = area.nodeViews.get(node.id).position;
      // await area.translate(a.id, { x: parentPos.x + 380, y: parentPos.y - 100 });
    },
    destroy: () => area.destroy(),
    showHideGrid: showHideGrid
  };
}
